import React from "react";
import Tooltip from "../tooltip/tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import "./input.scss";
import classNames from "classnames";

export default function InputLabel(props) {
  return (
    <span
      className={classNames("input-label", props.className)}
      data-testid="input-label"
    >
      <strong>{props.label}</strong>
      {props.isRequired ? <span className="asterisk"> *</span> : null}
      {props.helpText ? (
        <Tooltip
          tooltipMessage={props.helpText}
          placement={props.helpTextPlacement}
          referenceStyle={{ display: "inline-block" }}
        >
          <FaQuestionCircle
            className="help-text-icon"
            data-testid="help-text-icon"
          />
        </Tooltip>
      ) : null}
      {props.additionalIconComponent}
    </span>
  );
}

InputLabel.defaultProps = {
  label: "",
  helpText: "",
  helpTextPlacement: "bottom",
  className: "",
  additionalIconComponent: null
};
