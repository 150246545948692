import React from "react";
import "./FullWidthBannerImage.scss";

export default function FullWidthBannerImage(props) {
  return (
    <div
      className="full-width-banner"
      style={{ background: `url(${props.backgroundImage})` }}
    >
      {props.bannerText ? (
        <h1 className="banner-text">{props.bannerText}</h1>
      ) : null}
      {props.children}
    </div>
  );
}

FullWidthBannerImage.defaultProps = {
  backgroundImage: "",
  bannerText: ""
};
