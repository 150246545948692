import axios from "axios";

export function sendAccountDeletionEmail({ uid }) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/send-account-deletion-email", {
        uid
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
