import React from "react";
import { FaSpinner } from "react-icons/fa";
import "./LoadSpinner.scss";

export default function LoadSpinner() {
  return (
    <div className="loading-spinner">
      <FaSpinner aria-label="loading spinner" />
    </div>
  );
}
