export const prices =
  process.env.NODE_ENV === "development"
    ? Object.freeze([
        "price_1IE6QtEq5pL0glqtOcGEXpO2",
        "price_1IE6QwEq5pL0glqtTRzTf48N",
        "price_1IE6R0Eq5pL0glqtlqRSa9Qj",
        "price_1IE6R3Eq5pL0glqt9UpOEvmL",
        "price_1IE6R6Eq5pL0glqtJCfimGsl",
        "price_1IE6R9Eq5pL0glqtyZ0yBP5O",
        "price_1IE6RCEq5pL0glqtDRJPEEk4",
        "price_1IE6RGEq5pL0glqtDTW5pKHi",
        "price_1IE6RKEq5pL0glqtXNc8hioT"
      ])
    : Object.freeze([
        "price_1IE62PEq5pL0glqtQU6AwNju",
        "price_1IE62bEq5pL0glqtBywvJdnQ",
        "price_1I9KoVEq5pL0glqtyWRBFLmr",
        "price_1I9KoVEq5pL0glqtAGfaGJFk",
        "price_1I9KoVEq5pL0glqtmbBcbRnL",
        "price_1I9KoVEq5pL0glqtmbBcbRnL",
        "price_1I9KoVEq5pL0glqtcqYUQKpY",
        "price_1I9KoVEq5pL0glqtI8RoGwei",
        "price_1I9KoVEq5pL0glqtbJskkTGA",
        "price_1I9KoVEq5pL0glqttGIOFVTz"
      ]);

export const BASIC = "BASIC";
export const PREMIUM = "PREMIUM";
