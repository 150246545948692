import React, { useState } from "react";
import useInterval from "../../custom-hooks/useInterval";
import { useSelector } from "react-redux";
import "./WelcomeMessage.scss";

export default function WelcomeMessage(props) {
  const [date, setDate] = useState(new Date());
  const { displayName } = useSelector(state => state.userDetails);

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  const getMessage = () => {
    const hours = date.getHours();
    const displayNameSplit = displayName.split(" ");
    const message =
      hours < 12
        ? "Good morning"
        : 12 <= hours && hours < 17
        ? "Good afternoon"
        : "Good evening";
    return (
      <div className="welcome-message">
        <h1 className="header-layout-font">
          {message},{" "}
          {displayNameSplit[0] === "" ? "Anonymous" : displayNameSplit[0]}
        </h1>
      </div>
    );
  };

  return getMessage();
}
