import { useEffect, useState } from "react";
import axios from "axios";

/*
 * Fetches data from a feed and returns it to the Reach component
 */
export const useRssFetch = url => {
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .post("/.netlify/functions/fetch-feed", {
        url
      })
      .then(res => setData(res.data.feedData))
      .catch(err => console.log(err));
  }, [url]);

  return data;
};
