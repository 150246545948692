// GUARDIAN RSS Feeds
export const GUARDIAN_WORLD = "guardian_World";
export const GUARDIAN_WORLD_FRIENDLY = "World";
export const GUARDIAN_US = "guardian_us";
export const GUARDIAN_US_FRIENDLY = "US";
export const GUARDIAN_UK = "guardian_uk";
export const GUARDIAN_UK_FRIENDLY = "UK";
export const GUARDIAN_UK_LIFESTYLE = "guardian_uk_lifestyle";
export const GUARDIAN_UK_LIFESTYLE_FRIENDLY = "UK Lifestyle";
export const GUARDIAN_SCIENCE = "guardian_science";
export const GUARDIAN_SCIENCE_FRIENDLY = "Science";
export const GUARDIAN_US_LIFESTYLE = "guardian_us_lifestyle";
export const GUARDIAN_US_LIFESTYLE_FRIENDLY = "US Lifestyle";
export const GUARDIAN_FASHION = "guardian_fashion";
export const GUARDIAN_FASHION_FRIENDLY = "Fashion";
export const GUARDIAN_US_SPORTS = "guardian_us_sports";
export const GUARDIAN_US_SPORTS_FRIENDLY = "US Sports";
export const GUARDIAN_UK_SPORTS = "guardian_uk_sports";
export const GUARDIAN_UK_SPORTS_FRIENDLY = "UK Sports";

// FOX RSS Feeds
export const FOX_NATIONAL = "fox_news_national";
export const FOX_NATIONAL_FRIENDLY = "National";
export const FOX_LATEST = "fox_news_latest";
export const FOX_LATEST_FRIENDLY = "Latest";
export const FOX_WORLD = "fox_news_world";
export const FOX_WORLD_FRIENDLY = "World";
export const FOX_POLITICS = "fox_news_politics";
export const FOX_POLITICS_FRIENDLY = "Politics";
export const FOX_BUSINESS = "fox_news_business";
export const FOX_BUSINESS_FRIENDLY = "Business";
export const FOX_SCI_TECH = "fox_news_scitech";
export const FOX_SCI_TECH_FRIENDLY = "Science & Technology";
export const FOX_HEALTH = "fox_news_health";
export const FOX_HEALTH_FRIENDLY = "Health";
export const FOX_ENTERTAINMENT = "fox_news_entertainment";
export const FOX_ENTERTAINMENT_FRIENDLY = "Entertainment";
export const FOX_VIEWS = "fox_news_views";
export const FOX_VIEWS_FRIENDLY = "Views";

// ESPN RSS Feeds
export const ESPN_TOP = "espn_top";
export const ESPN_TOP_FRIENDLY = "Top Headlines";
export const ESPN_NFL = "espn_nfl";
export const ESPN_NFL_FRIENDLY = "NFL";
export const ESPN_NBA = "espn_nba";
export const ESPN_NBA_FRIENDLY = "NBA";
export const ESPN_MLB = "espn_mlb";
export const ESPN_MLB_FRIENDLY = "MLB";
export const ESPN_NHL = "espn_nhl";
export const ESPN_NHL_FRIENDLY = "NHL";
export const ESPN_COLLEGE_BASKETBALL = "espn_college_basketball";
export const ESPN_COLLEGE_BASKETBALL_FRIENDLY = "College Basketball";
export const ESPN_COLLEGE_FOOTBALL = "espn_football_basketball";
export const ESPN_COLLEGE_FOOTBALL_FRIENDLY = "College Football";

// CNN RSS Feeds
export const CNN_WORLD = "cnn_world";
export const CNN_WORLD_FRIENDLY = "World";
export const CNN_TOP_STORIES = "cnn_topstories";
export const CNN_TOP_STORIES_FRIENDLY = "Top Stories";
export const CNN_US = "cnn_us";
export const CNN_US_FRIENDLY = "USA";
export const CNN_POLITICS = "cnn_allpolitics";
export const CNN_POLITICS_FRIENDLY = "Politics";
export const CNN_TECHNOLOGY = "cnn_tech";
export const CNN_TECHNOLOGY_FRIENDLY = "Technology";
export const CNN_HEALTH = "cnn_health";
export const CNN_HEALTH_FRIENDLY = "Health";
export const CNN_ENTERTAINMENT = "cnn_showbiz";
export const CNN_ENTERTAINMENT_FRIENDLY = "Entertainment";
export const CNN_TRAVEL = "cnn_travel";
export const CNN_TRAVEL_FRIENDLY = "Travel";

// NEW YORK TIMES RSS FEEDS
export const NYT_WORLD = "World";
export const NYT_WORLD_FRIENDLY = "World";
export const NYT_US = "US";
export const NYT_US_FRIENDLY = "USA";
export const NYT_BUSINESS = "Business";
export const NYT_BUSINESS_FRIENDLY = "Business";
export const NYT_TECHNOLOGY = "Technology";
export const NYT_TECHNOLOGY_FRIENDLY = "Technology";
export const NYT_SPORTS = "Sports";
export const NYT_SPORTS_FRIENDLY = "Sports";
export const NYT_ENVIRONMENT = "Climate";
export const NYT_ENVIRONMENT_FRIENDLY = "Environment";
export const NYT_SPACE = "Space";
export const NYT_SPACE_FRIENDLY = "Space & Cosmos";
export const NYT_JOBS = "Jobs";
export const NYT_JOBS_FRIENDLY = "Jobs";
export const NYT_ARTS = "Arts";
export const NYT_ARTS_FRIENDLY = "Arts";

// WASHINGTON POST FEEDS
export const WAPO_NATIONAL = "wapo_national";
export const WAPO_NATIONAL_FRIENDLY = "National";
export const WAPO_LEADERSHIP = "wapo_rss_on-leadership";
export const WAPO_LEADERSHIP_FRIENDLY = "Leadership";
export const WAPO_POLITICS = "wapo_politics";
export const WAPO_POLITICS_FRIENDLY = "Politics";
export const WAPO_FACT_CHECKER = "wapo_rss_fact-checker";
export const WAPO_FACT_CHECKER_FRIENDLY = "Fact Checker";
export const WAPO_WORLD = "wapo_world";
export const WAPO_WORLD_FRIENDLY = "World";
export const WAPO_MONKEY_CAGE = "wapo_rss_monkey-cage";
export const WAPO_MONKEY_CAGE_FRIENDLY = "Monkey Cage";
export const WAPO_OPINIONS = "wapo_opinions";
export const WAPO_OPINIONS_FRIENDLY = "Opinions";
export const WAPO_LIFESTYLE = "wapo_lifestyle";
export const WAPO_LIFESTYLE_FRIENDLY = "Lifestyle";

export const GAMASUTRA_FEED = "gamasutra_feed";
export const GAMASUTRA_FEED_FRIENDLY = "Gamasutra";

export const BOOKMARK = "bookmark";
export const BOOKMARK_FRIENDLY = "Bookmark";
export const CLOCK = "clock";
export const CLOCK_FRIENDLY = "Clock";
export const TASK_LIST = "task_list";
export const TASK_LIST_FRIENDLY = "Tasks";
export const GOOGLE_SEARCH = "google_search";
export const GOOGLE_SEARCH_FRIENDLY = "Google Search";
export const BOOKMARK_LIST = "bookmark_list";
export const BOOKMARK_LIST_FRIENDLY = "Bookmark List";
export const ICON_BOOKMARK = "icon_bookmark";
export const ICON_BOOKMARK_FRIENDLY = "Icon Bookmark";
export const WELCOME_MESSAGE = "welcome_message";
export const WELCOME_MESSAGE_FRIENDLY = "Welcome";
export const RICH_TEXT = "rich_text";
export const RICH_TEXT_FRIENDLY = "Custom Text";

// FINANCE
export const TICKER_BANNER = "ticker_banner";
export const TICKER_BANNER_FRIENDLY = "Stock and Crypto Ticker Banner";
export const MARKET_OVERVIEW = "market_overview";
export const MARKET_OVERVIEW_FRIENDLY = "Market Overview Chart";

export const baseConfigs = {
  isDraggable: true,
  isResizable: true,
  maxH: 100,
  maxW: 100
};
export const WELCOME_MESSAGE_MIN_H = 3;
export const WELCOME_MESSAGE_MIN_W = 10;
export const CLOCK_MIN_H = 3;
export const CLOCK_MIN_W = 10;

export const widgetLayoutConfigs = {};

[
  WAPO_NATIONAL,
  WAPO_OPINIONS,
  WAPO_POLITICS,
  WAPO_WORLD,
  WAPO_LEADERSHIP,
  WAPO_LIFESTYLE,
  WAPO_MONKEY_CAGE,
  WAPO_FACT_CHECKER,
  NYT_WORLD,
  NYT_US,
  NYT_BUSINESS,
  NYT_TECHNOLOGY,
  NYT_SPORTS,
  NYT_ENVIRONMENT,
  NYT_SPACE,
  NYT_JOBS,
  NYT_ARTS,
  CNN_US,
  CNN_TRAVEL,
  CNN_TOP_STORIES,
  CNN_TECHNOLOGY,
  CNN_POLITICS,
  CNN_ENTERTAINMENT,
  CNN_HEALTH,
  CNN_WORLD,
  ESPN_TOP,
  ESPN_NFL,
  ESPN_NBA,
  ESPN_MLB,
  ESPN_NHL,
  ESPN_COLLEGE_BASKETBALL,
  ESPN_COLLEGE_FOOTBALL,
  GUARDIAN_WORLD,
  GUARDIAN_US,
  GUARDIAN_UK,
  GUARDIAN_UK_LIFESTYLE,
  GUARDIAN_SCIENCE,
  GUARDIAN_US_LIFESTYLE,
  GUARDIAN_FASHION,
  GUARDIAN_US_SPORTS,
  GUARDIAN_UK_SPORTS,
  FOX_BUSINESS,
  FOX_ENTERTAINMENT,
  FOX_HEALTH,
  FOX_LATEST,
  FOX_NATIONAL,
  FOX_POLITICS,
  FOX_SCI_TECH,
  FOX_VIEWS,
  FOX_WORLD,
  GAMASUTRA_FEED
].forEach(config => {
  widgetLayoutConfigs[config] = {
    w: 20,
    h: 15,
    minW: 10,
    minH: 3,
    ...baseConfigs
  };
});

widgetLayoutConfigs[TASK_LIST] = {
  w: 20,
  h: 15,
  minW: 10,
  minH: 3,
  ...baseConfigs
};
widgetLayoutConfigs[BOOKMARK] = {
  w: 12,
  h: 10,
  minW: 10,
  minH: 3,
  ...baseConfigs
};
widgetLayoutConfigs[CLOCK] = {
  w: 40,
  h: 5,
  minW: CLOCK_MIN_W,
  minH: CLOCK_MIN_H,
  ...baseConfigs
};
widgetLayoutConfigs[GOOGLE_SEARCH] = {
  w: 20,
  h: 3,
  minW: 10,
  minH: 2,
  ...baseConfigs
};
widgetLayoutConfigs[BOOKMARK_LIST] = {
  w: 20,
  h: 15,
  minW: 10,
  minH: 3,
  ...baseConfigs
};
widgetLayoutConfigs[WELCOME_MESSAGE] = {
  w: 50,
  h: 3,
  minW: WELCOME_MESSAGE_MIN_W,
  minH: WELCOME_MESSAGE_MIN_H,
  ...baseConfigs
};
widgetLayoutConfigs[TICKER_BANNER] = {
  w: 20,
  h: 5,
  minW: 10,
  minH: 3,
  ...baseConfigs
};
widgetLayoutConfigs[MARKET_OVERVIEW] = {
  w: 20,
  h: 30,
  minW: 20,
  minH: 10,
  ...baseConfigs
};
widgetLayoutConfigs[ICON_BOOKMARK] = {
  w: 7,
  h: 4,
  minW: 7,
  minH: 4,
  ...baseConfigs
};
widgetLayoutConfigs[RICH_TEXT] = {
  w: 7,
  h: 7,
  minW: 7,
  minH: 7,
  ...baseConfigs
};

export const editableWidgetTypes = [
  BOOKMARK_LIST,
  BOOKMARK,
  ICON_BOOKMARK,
  TICKER_BANNER,
  MARKET_OVERVIEW,
  CLOCK,
  RICH_TEXT
];

const uuidv1 = require("uuid/v1");

export const generateNewWidget = (widgetType, layout, numColumns) => {
  const { x, y } = generateOpenXYCoordinate(
    widgetLayoutConfigs[widgetType],
    layout,
    numColumns
  );
  return {
    i: `${uuidv1()}`,
    x,
    y,
    static: false,
    ...widgetLayoutConfigs[widgetType]
  };
};

const generateOpenXYCoordinate = (widgetType, layout, numColumns) => {
  const layoutCopy = layout.slice();
  let maxYPos = 0;
  layoutCopy
    .sort(function (a, b) {
      return a.x - b.x || a.y - b.y;
    })
    .forEach(layout => {
      maxYPos = layout.y + layout.h > maxYPos ? layout.y + layout.h : maxYPos;
    });

  const grid = create2DArray(numColumns, maxYPos);
  layoutCopy.forEach(layout => {
    for (let i = layout.y; i < layout.y + layout.h; i++) {
      for (let j = layout.x; j < layout.x + layout.w; j++) {
        grid[i][j] = 1;
      }
    }
  });

  return getXYCoords(grid, widgetType);
};

export const getXYCoords = (grid, widgetType) => {
  let x;
  let y;
  const gridHeight = grid.length || 0;
  const gridWidth = grid[0] ? grid[0].length : 0;
  loop1: for (let i = 0; i < gridHeight; i++) {
    for (let j = 0; j < gridWidth; j++) {
      loop3: for (
        let k = i;
        k < Math.min(widgetType.h + i, gridHeight - 1);
        k++
      ) {
        for (let l = j; l < widgetType.w + j; l++) {
          if (l > gridWidth - 1 || grid[k][l] === 1) {
            break loop3;
          }
        }
        if (
          k === Math.min(widgetType.h + i, gridHeight - 1) - 1 &&
          x === undefined &&
          y === undefined
        ) {
          x = j;
          y = i;
          break loop1;
        }
      }
    }
  }
  if (x === undefined) {
    x = 0;
    y = gridHeight;
  }
  return { x, y };
};

const create2DArray = (numColumns, numRows) => {
  const arr = [];

  for (let i = 0; i < numRows; i++) {
    arr[i] = Array(numColumns).fill(0);
  }

  return arr;
};
