import React from "react";
import Features from "../pages/about/Features";
import "./PricingOptions.scss";
import Button, { buttonStyles } from "../button/button";
import { useMobile } from "../../custom-hooks/useMobile";
import classNames from "classnames";

export default function PricingOptions(props) {
  const { isMobile } = useMobile({});

  return (
    <div className={classNames("pricing", isMobile ? "mobile-view" : "")}>
      <div className="pricing-option">
        <h2>Forever Free</h2>
        <div className="pricing-option-details">
          <h1 className="cost">
            <span>$0</span> / month
          </h1>
          <p>
            <em>
              Dashboi will never cost you a penny. Yep, you read that right.
            </em>
          </p>
          <Button
            buttonStyle={buttonStyles.OPAQUE_BUTTON}
            onClick={props.onBasicClick}
          >
            Select
          </Button>
        </div>
        {props.showPlanFeatures ? (
          <div className="pricing-option-details">
            <Features isPremiumList={false} />
          </div>
        ) : null}
      </div>
      <div className="pricing-option">
        <h2>Dashboi Premium</h2>
        <div className="pricing-option-details">
          <h1 className="cost">
            <span>$4.50</span> / month
          </h1>
          <p>
            <em>Power up your basic plan to make Dashboi truly your own.</em>
          </p>
          <Button
            buttonStyle={buttonStyles.OPAQUE_BUTTON}
            onClick={props.onPremiumClick}
          >
            Select
          </Button>
        </div>
        {props.showPlanFeatures ? (
          <div className="pricing-option-details">
            <Features isPremiumList={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

PricingOptions.defaultProps = {
  showPlanFeatures: true,
  onBasicClick: () => {},
  onPremiumClick: () => {}
};
