import {
  ADD_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  FILTER_TASKS,
  REQUEST_TASKS,
  REQUEST_TASKS_SUCCESS,
  REQUEST_TASKS_FAILURE,
  CLEAR_TASKS,
  UPDATE_COMPLETION_STATUS_SUCCESS,
  UPDATE_COMPLETION_STATUS,
  UPDATE_COMPLETION_STATUS_FAILURE,
  UPDATE_TASK_NAME_SUCCESS,
  UPDATE_TASK_NAME,
  UPDATE_TASK_NAME_FAILURE,
  ARCHIVE_TASK,
  UNARCHIVE_TASK,
  ARCHIVE_TASK_SUCCESS,
  UNARCHIVE_TASK_SUCCESS,
  ARCHIVE_TASK_FAILURE,
  UNARCHIVE_TASK_FAILURE
} from "../actions/types";

export const initialTaskState = {
  taskList: [],
  isCompletedFilter: false //actually shows archived tasks
};

/*
 * Responds to task actions and updates the appropriate state
 */
export function handleTaskActions(state, action) {
  if (typeof state === "undefined") {
    return initialTaskState;
  }
  switch (action.type) {
    case ADD_TASK_SUCCESS:
      return Object.assign({}, state, {
        taskList: [action.task, ...state.taskList]
      });
    case UPDATE_COMPLETION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        taskList: state.taskList.map(task => {
          if (task.id === action.taskId) {
            return Object.assign({}, task, {
              isCompleted: action.isCompleted
            });
          }
          return task;
        })
      });
    case UPDATE_TASK_NAME_SUCCESS:
      return Object.assign({}, state, {
        taskList: state.taskList.map(task => {
          if (task.id === action.taskId) {
            return Object.assign({}, task, {
              taskName: action.taskName
            });
          }
          return task;
        })
      });
    case FILTER_TASKS:
      return Object.assign({}, state, {
        isCompletedFilter: action.isCompletedFilter
      });
    case REQUEST_TASKS_SUCCESS:
      return Object.assign({}, state, {
        taskList: action.taskList
      });
    case CLEAR_TASKS:
      return Object.assign({}, state, {
        taskList: []
      });
    case ARCHIVE_TASK_SUCCESS:
      return Object.assign({}, state, {
        taskList: state.taskList.map(task => {
          if (task.id === action.taskId) {
            return Object.assign({}, task, {
              isArchived: true
            });
          }
          return task;
        })
      });
    case UNARCHIVE_TASK_SUCCESS:
      return Object.assign({}, state, {
        taskList: state.taskList.map(task => {
          if (task.id === action.taskId) {
            return Object.assign({}, task, {
              isArchived: false
            });
          }
          return task;
        })
      });
    case ARCHIVE_TASK:
    case ARCHIVE_TASK_FAILURE:
    case UNARCHIVE_TASK:
    case UNARCHIVE_TASK_FAILURE:
    case ADD_TASK:
    case ADD_TASK_FAILURE:
    case UPDATE_COMPLETION_STATUS:
    case UPDATE_COMPLETION_STATUS_FAILURE:
    case UPDATE_TASK_NAME:
    case UPDATE_TASK_NAME_FAILURE:
    case REQUEST_TASKS:
    case REQUEST_TASKS_FAILURE:
    default:
      return state;
  }
}
