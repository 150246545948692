import React from "react";
import classNames from "classnames";
import "./Checkbox.scss";

const uuidv1 = require("uuid/v1");

export default function Checkbox(props) {
  return (
    <div className={classNames("checkbox-row", props.className)}>
      <input
        type="checkbox"
        id={props.uniqueId}
        ref={props.reference}
        name={props.uniqueId}
        checked={props.isChecked}
        onChange={props.onChange}
      />
      <label htmlFor={props.uniqueId}>{props.label}</label>
    </div>
  );
}

Checkbox.defaultProps = {
  className: "",
  isChecked: false,
  onChange: () => {},
  uniqueId: uuidv1(),
  label: "",
  reference: null
};
