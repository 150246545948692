import React from "react";
import {
  FaRss,
  FaTasks,
  FaBookmark,
  FaRegClock,
  FaSearch,
  FaMoneyBillAlt,
  FaSun,
  FaFont
} from "react-icons/fa";
import {
  ESPN_COLLEGE_BASKETBALL,
  ESPN_COLLEGE_BASKETBALL_FRIENDLY,
  ESPN_COLLEGE_FOOTBALL,
  ESPN_COLLEGE_FOOTBALL_FRIENDLY,
  ESPN_MLB,
  ESPN_MLB_FRIENDLY,
  ESPN_NBA,
  ESPN_NBA_FRIENDLY,
  ESPN_NFL,
  ESPN_NFL_FRIENDLY,
  ESPN_NHL,
  ESPN_NHL_FRIENDLY,
  ESPN_TOP,
  ESPN_TOP_FRIENDLY,
  GUARDIAN_WORLD,
  GUARDIAN_WORLD_FRIENDLY,
  GUARDIAN_US,
  GUARDIAN_US_FRIENDLY,
  GUARDIAN_UK,
  GUARDIAN_UK_FRIENDLY,
  GUARDIAN_UK_LIFESTYLE,
  GUARDIAN_UK_LIFESTYLE_FRIENDLY,
  GUARDIAN_SCIENCE,
  GUARDIAN_SCIENCE_FRIENDLY,
  GUARDIAN_US_LIFESTYLE,
  GUARDIAN_US_LIFESTYLE_FRIENDLY,
  GUARDIAN_FASHION,
  GUARDIAN_FASHION_FRIENDLY,
  GUARDIAN_US_SPORTS,
  GUARDIAN_US_SPORTS_FRIENDLY,
  GUARDIAN_UK_SPORTS,
  GUARDIAN_UK_SPORTS_FRIENDLY,
  BOOKMARK,
  BOOKMARK_FRIENDLY,
  CLOCK,
  CLOCK_FRIENDLY,
  TASK_LIST,
  TASK_LIST_FRIENDLY,
  GOOGLE_SEARCH,
  GOOGLE_SEARCH_FRIENDLY,
  BOOKMARK_LIST,
  BOOKMARK_LIST_FRIENDLY,
  GAMASUTRA_FEED,
  GAMASUTRA_FEED_FRIENDLY,
  FOX_LATEST,
  FOX_LATEST_FRIENDLY,
  FOX_NATIONAL,
  FOX_NATIONAL_FRIENDLY,
  FOX_WORLD,
  FOX_WORLD_FRIENDLY,
  FOX_POLITICS,
  FOX_POLITICS_FRIENDLY,
  FOX_SCI_TECH,
  FOX_SCI_TECH_FRIENDLY,
  FOX_HEALTH,
  FOX_HEALTH_FRIENDLY,
  FOX_ENTERTAINMENT,
  FOX_ENTERTAINMENT_FRIENDLY,
  CNN_WORLD,
  CNN_WORLD_FRIENDLY,
  CNN_HEALTH_FRIENDLY,
  CNN_HEALTH,
  CNN_ENTERTAINMENT_FRIENDLY,
  CNN_ENTERTAINMENT,
  CNN_POLITICS,
  CNN_POLITICS_FRIENDLY,
  CNN_TECHNOLOGY,
  CNN_TECHNOLOGY_FRIENDLY,
  CNN_US,
  CNN_US_FRIENDLY,
  CNN_TRAVEL,
  CNN_TRAVEL_FRIENDLY,
  CNN_TOP_STORIES,
  CNN_TOP_STORIES_FRIENDLY,
  NYT_BUSINESS,
  NYT_BUSINESS_FRIENDLY,
  NYT_ARTS,
  NYT_ARTS_FRIENDLY,
  NYT_ENVIRONMENT,
  NYT_ENVIRONMENT_FRIENDLY,
  NYT_JOBS,
  NYT_JOBS_FRIENDLY,
  NYT_SPACE,
  NYT_SPACE_FRIENDLY,
  NYT_SPORTS,
  NYT_SPORTS_FRIENDLY,
  NYT_TECHNOLOGY,
  NYT_TECHNOLOGY_FRIENDLY,
  NYT_US,
  NYT_US_FRIENDLY,
  NYT_WORLD,
  NYT_WORLD_FRIENDLY,
  WAPO_FACT_CHECKER,
  WAPO_FACT_CHECKER_FRIENDLY,
  WAPO_LEADERSHIP,
  WAPO_LEADERSHIP_FRIENDLY,
  WAPO_LIFESTYLE,
  WAPO_LIFESTYLE_FRIENDLY,
  WAPO_MONKEY_CAGE,
  WAPO_MONKEY_CAGE_FRIENDLY,
  WAPO_NATIONAL,
  WAPO_NATIONAL_FRIENDLY,
  WAPO_OPINIONS,
  WAPO_OPINIONS_FRIENDLY,
  WAPO_POLITICS,
  WAPO_POLITICS_FRIENDLY,
  WAPO_WORLD,
  WAPO_WORLD_FRIENDLY,
  TICKER_BANNER,
  TICKER_BANNER_FRIENDLY,
  MARKET_OVERVIEW,
  MARKET_OVERVIEW_FRIENDLY,
  WELCOME_MESSAGE,
  WELCOME_MESSAGE_FRIENDLY,
  editableWidgetTypes,
  ICON_BOOKMARK,
  ICON_BOOKMARK_FRIENDLY,
  RICH_TEXT,
  RICH_TEXT_FRIENDLY
} from "../widget/widgetUtils";
import { useDispatch } from "react-redux";
import { showModal } from "../../actions/modalActions";
import { addWidgetToLayout } from "../../actions/layoutActions";
import { EDIT_COMPONENT } from "../modal/modalTypes";
import SidebarWidgetOption from "./SidebarWidgetOption";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";
import "./ComponentPicker.scss";

export default function ComponentPicker(props) {
  const dispatch = useDispatch();

  const onClick = (event, item) => {
    event.preventDefault();
    recordEvent(
      `Select Component ${item.friendlyName}`,
      eventCategories.LAYOUT_CONFIGURATION,
      `Select Component ${item.friendlyName}`
    );
    dispatch(addWidgetToLayout(item.widgetType));
    if (editableWidgetTypes.includes(item.widgetType)) {
      dispatch(showModal(EDIT_COMPONENT));
    }
  };

  const items = [
    {
      icon: <FaRss className="sidebar-icon" aria-label="Add News Feed" />,
      friendlyName: "News",
      groupWidgets: [
        {
          friendlyName: "CNN",
          groupWidgets: [
            {
              widgetType: CNN_ENTERTAINMENT,
              friendlyName: CNN_ENTERTAINMENT_FRIENDLY
            },
            {
              widgetType: CNN_HEALTH,
              friendlyName: CNN_HEALTH_FRIENDLY
            },
            {
              widgetType: CNN_POLITICS,
              friendlyName: CNN_POLITICS_FRIENDLY
            },
            {
              widgetType: CNN_TECHNOLOGY,
              friendlyName: CNN_TECHNOLOGY_FRIENDLY
            },
            {
              widgetType: CNN_TOP_STORIES,
              friendlyName: CNN_TOP_STORIES_FRIENDLY
            },
            {
              widgetType: CNN_TRAVEL,
              friendlyName: CNN_TRAVEL_FRIENDLY
            },
            {
              widgetType: CNN_US,
              friendlyName: CNN_US_FRIENDLY
            },
            {
              widgetType: CNN_WORLD,
              friendlyName: CNN_WORLD_FRIENDLY
            }
          ]
        },
        {
          friendlyName: "ESPN",
          groupWidgets: [
            {
              widgetType: ESPN_COLLEGE_BASKETBALL,
              friendlyName: ESPN_COLLEGE_BASKETBALL_FRIENDLY
            },
            {
              widgetType: ESPN_COLLEGE_FOOTBALL,
              friendlyName: ESPN_COLLEGE_FOOTBALL_FRIENDLY
            },
            {
              widgetType: ESPN_MLB,
              friendlyName: ESPN_MLB_FRIENDLY
            },
            {
              widgetType: ESPN_NBA,
              friendlyName: ESPN_NBA_FRIENDLY
            },
            {
              widgetType: ESPN_NFL,
              friendlyName: ESPN_NFL_FRIENDLY
            },
            {
              widgetType: ESPN_NHL,
              friendlyName: ESPN_NHL_FRIENDLY
            },
            {
              widgetType: ESPN_TOP,
              friendlyName: ESPN_TOP_FRIENDLY
            }
          ]
        },
        {
          friendlyName: "Fox",
          groupWidgets: [
            {
              widgetType: FOX_ENTERTAINMENT,
              friendlyName: FOX_ENTERTAINMENT_FRIENDLY
            },
            {
              widgetType: FOX_HEALTH,
              friendlyName: FOX_HEALTH_FRIENDLY
            },
            {
              widgetType: FOX_LATEST,
              friendlyName: FOX_LATEST_FRIENDLY
            },
            {
              widgetType: FOX_NATIONAL,
              friendlyName: FOX_NATIONAL_FRIENDLY
            },
            {
              widgetType: FOX_POLITICS,
              friendlyName: FOX_POLITICS_FRIENDLY
            },
            {
              widgetType: FOX_SCI_TECH,
              friendlyName: FOX_SCI_TECH_FRIENDLY
            },
            {
              widgetType: FOX_WORLD,
              friendlyName: FOX_WORLD_FRIENDLY
            }
          ]
        },
        {
          widgetType: GAMASUTRA_FEED,
          friendlyName: GAMASUTRA_FEED_FRIENDLY
        },
        {
          friendlyName: "The Guardian",
          groupWidgets: [
            {
              widgetType: GUARDIAN_FASHION,
              friendlyName: GUARDIAN_FASHION_FRIENDLY
            },
            {
              widgetType: GUARDIAN_SCIENCE,
              friendlyName: GUARDIAN_SCIENCE_FRIENDLY
            },
            {
              widgetType: GUARDIAN_UK,
              friendlyName: GUARDIAN_UK_FRIENDLY
            },
            {
              widgetType: GUARDIAN_UK_LIFESTYLE,
              friendlyName: GUARDIAN_UK_LIFESTYLE_FRIENDLY
            },
            {
              widgetType: GUARDIAN_UK_SPORTS,
              friendlyName: GUARDIAN_UK_SPORTS_FRIENDLY
            },
            {
              widgetType: GUARDIAN_US,
              friendlyName: GUARDIAN_US_FRIENDLY
            },
            {
              widgetType: GUARDIAN_US_LIFESTYLE,
              friendlyName: GUARDIAN_US_LIFESTYLE_FRIENDLY
            },
            {
              widgetType: GUARDIAN_US_SPORTS,
              friendlyName: GUARDIAN_US_SPORTS_FRIENDLY
            },
            {
              widgetType: GUARDIAN_WORLD,
              friendlyName: GUARDIAN_WORLD_FRIENDLY
            }
          ]
        },
        {
          friendlyName: "New York Times",
          groupWidgets: [
            {
              widgetType: NYT_ARTS,
              friendlyName: NYT_ARTS_FRIENDLY
            },
            {
              widgetType: NYT_BUSINESS,
              friendlyName: NYT_BUSINESS_FRIENDLY
            },
            {
              widgetType: NYT_ENVIRONMENT,
              friendlyName: NYT_ENVIRONMENT_FRIENDLY
            },
            {
              widgetType: NYT_JOBS,
              friendlyName: NYT_JOBS_FRIENDLY
            },
            {
              widgetType: NYT_SPACE,
              friendlyName: NYT_SPACE_FRIENDLY
            },
            {
              widgetType: NYT_SPORTS,
              friendlyName: NYT_SPORTS_FRIENDLY
            },
            {
              widgetType: NYT_TECHNOLOGY,
              friendlyName: NYT_TECHNOLOGY_FRIENDLY
            },
            {
              widgetType: NYT_US,
              friendlyName: NYT_US_FRIENDLY
            },
            {
              widgetType: NYT_WORLD,
              friendlyName: NYT_WORLD_FRIENDLY
            }
          ]
        },
        {
          friendlyName: "Washington Post",
          groupWidgets: [
            {
              widgetType: WAPO_FACT_CHECKER,
              friendlyName: WAPO_FACT_CHECKER_FRIENDLY
            },
            {
              widgetType: WAPO_LEADERSHIP,
              friendlyName: WAPO_LEADERSHIP_FRIENDLY
            },
            {
              widgetType: WAPO_LIFESTYLE,
              friendlyName: WAPO_LIFESTYLE_FRIENDLY
            },
            {
              widgetType: WAPO_MONKEY_CAGE,
              friendlyName: WAPO_MONKEY_CAGE_FRIENDLY
            },
            {
              widgetType: WAPO_NATIONAL,
              friendlyName: WAPO_NATIONAL_FRIENDLY
            },
            {
              widgetType: WAPO_OPINIONS,
              friendlyName: WAPO_OPINIONS_FRIENDLY
            },
            {
              widgetType: WAPO_POLITICS,
              friendlyName: WAPO_POLITICS_FRIENDLY
            },
            {
              widgetType: WAPO_WORLD,
              friendlyName: WAPO_WORLD_FRIENDLY
            }
          ]
        }
      ]
    },
    {
      icon: <FaTasks aria-label="Add Task List Component" />,
      widgetType: TASK_LIST,
      friendlyName: TASK_LIST_FRIENDLY
    },
    {
      icon: <FaBookmark aria-label="Add Bookmark Components" />,
      friendlyName: "Bookmarks",
      groupWidgets: [
        {
          widgetType: BOOKMARK,
          friendlyName: BOOKMARK_FRIENDLY
        },
        {
          widgetType: BOOKMARK_LIST,
          friendlyName: BOOKMARK_LIST_FRIENDLY
        },
        {
          widgetType: ICON_BOOKMARK,
          friendlyName: ICON_BOOKMARK_FRIENDLY
        }
      ]
    },
    {
      icon: <FaRegClock aria-label="Add Clock Component" />,
      widgetType: CLOCK,
      friendlyName: CLOCK_FRIENDLY
    },
    {
      icon: <FaSearch aria-label="Add Google Search Component" />,
      widgetType: GOOGLE_SEARCH,
      friendlyName: GOOGLE_SEARCH_FRIENDLY
    },
    {
      icon: <FaSun aria-label="Add Welcome Message" />,
      widgetType: WELCOME_MESSAGE,
      friendlyName: WELCOME_MESSAGE_FRIENDLY
    },
    {
      icon: <FaMoneyBillAlt aria-label="Add Finance Components" />,
      friendlyName: "Finance",
      groupWidgets: [
        {
          widgetType: MARKET_OVERVIEW,
          friendlyName: MARKET_OVERVIEW_FRIENDLY
        },
        {
          widgetType: TICKER_BANNER,
          friendlyName: TICKER_BANNER_FRIENDLY
        }
      ]
    },
    {
      icon: <FaFont aria-label="Add Custom Text" />,
      widgetType: RICH_TEXT,
      friendlyName: RICH_TEXT_FRIENDLY
    }
  ];

  return (
    <div className="sidebar-unordered-list">
      {items.map((item, i) => {
        return <SidebarWidgetOption item={item} key={i} onClick={onClick} />;
      })}
    </div>
  );
}
