import React, { useState, useEffect } from "react";
import PopperWrapper from "../popperWrapper/popperWrapper";
import "./tooltip.scss";

export default function Tooltip(props) {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    const timer = setTimeout(function () {
      setIsVisible(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, [isVisible]);

  const hideTooltip = () => {
    setIsVisible(false);
  };

  const children = (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={props.referenceStyle}
    >
      {props.children}
    </div>
  );

  return isVisible ? (
    <PopperWrapper
      reference={children}
      poppedComponent={<div className="tooltip">{props.tooltipMessage}</div>}
      placement={props.placement}
      referenceStyle={props.referenceStyle}
      poppedComponentStyles={{ maxWidth: "250px" }}
    />
  ) : (
    children
  );
}
