import React, { useRef, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestLoginWithGoogle,
  requestRegistrationWithEmailAndPassword
} from "../../actions/AuthenticationActions";
import { useEmailValidator } from "../../custom-hooks/useEmailValidator";
import { useFormInput } from "../../custom-hooks/useFormInput";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import Banner from "../banner/banner";
import { bannerSentiment } from "../banner/bannerUtils";
import Button, { buttonStyles } from "../button/button";
import Input from "../input/input";
import "./SignUpForm.scss";
import SocialLogin from "./SocialLogin";

export default function SignUpForm() {
  const email = useFormInput();
  const emailValidation = useEmailValidator(email.value);
  const emailRequired = useRequiredValidation(true, email.value);
  const emailRef = useRef(null);

  const displayName = useFormInput();
  const displayNameRequired = useRequiredValidation(true, displayName.value);
  const displayNameRef = useRef(null);

  const password = useFormInput();
  const passwordRequired = useRequiredValidation(true, password.value);
  const [passwordLengthValidation, setPasswordLengthValidation] = useState([]);
  const passwordRef = useRef(null);

  const validatePassword = () => {
    if (password.value.length < 6) {
      setPasswordLengthValidation([
        "Password must be six characters or longer"
      ]);
    } else {
      setPasswordLengthValidation([]);
    }
  };

  const dispatch = useDispatch();
  const { registerFailure } = useSelector(state => state.login);

  const onSubmit = event => {
    event.preventDefault();
    emailRequired.onInvalid(event);
    passwordRequired.onInvalid(event);
    displayNameRequired.onInvalid(event);
    const isPasswordValid = passwordLengthValidation.length === 0;
    if (
      emailValidation.isValidEmail &&
      emailRequired.isFieldFilled &&
      passwordRequired.isFieldFilled &&
      displayNameRequired.isFieldFilled &&
      isPasswordValid
    ) {
      dispatch(
        requestRegistrationWithEmailAndPassword(
          email.value,
          displayName.value,
          password.value
        )
      );
    } else if (!emailValidation.isValidEmail || !emailRequired.isFieldFilled) {
      emailRef.current.focus();
    } else if (!displayNameRequired.isFieldFilled) {
      displayNameRef.current.focus();
    } else if (!passwordRequired.isFieldFilled || !isPasswordValid) {
      passwordRef.current.focus();
    }
  };

  const onGoogleSubmit = () => {
    dispatch(requestLoginWithGoogle());
  };

  return (
    <Fragment>
      {registerFailure ? (
        <Banner
          message="Unexepcted sign up error occurred. Contact the Dashboi team at dashboi.app@gmail.com."
          sentiment={bannerSentiment.NEGATIVE}
        />
      ) : null}
      <div className="sign-up-inputs">
        <SocialLogin
          onGoogleSubmit={onGoogleSubmit}
          buttonAction={"Continue"}
        />
        <Input
          ariaLabel="Email"
          placeholder="Email"
          id="email"
          type="email"
          name="email"
          {...email}
          onBlur={() => emailValidation.validateEmail(email.value)}
          validations={emailRequired.validations.concat(
            emailValidation.validationMessage
          )}
          required={true}
          reference={emailRef}
        />
        <Input
          ariaLabel="Display Name"
          placeholder="Display Name"
          id="displayName"
          type="text"
          name="displayName"
          {...displayName}
          validations={displayNameRequired.validations}
          required={true}
          reference={displayNameRef}
        />
        <Input
          ariaLabel="Password"
          placeholder="Password"
          id="password"
          type="password"
          name="password"
          {...password}
          onBlur={() => validatePassword()}
          validations={passwordRequired.validations.concat(
            passwordLengthValidation
          )}
          required={true}
          reference={passwordRef}
        />
        <Button
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
          type="submit"
          onClick={onSubmit}
          shouldHandleGlobalEnterKeypress={true}
        >
          Sign up
        </Button>
      </div>
    </Fragment>
  );
}
