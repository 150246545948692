import React from "react";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichText.scss";

export default function RichText({ widget_id }) {
  const richTexts = useSelector(state => state.layouts.richTexts);
  const richText = richTexts
    ? richTexts.find(rt => rt.widget_id === widget_id)
    : null;
  return (
    <div className="rich-text-container">
      {richText ? (
        <ReactQuill value={richText.delta} theme={null} readOnly />
      ) : (
        <p className="body-layout-font">
          Custom Text is currently in edit mode
        </p>
      )}
    </div>
  );
}
