import { useRef, useCallback } from "react";

/*
 * Calls a callback as soon as a ref is established on the DOM
 */
export const useRefCallback = callback => {
  const myRef = useRef(null);
  const setMyRef = useCallback(
    node => {
      if (node) {
        myRef.current = node;
        callback(myRef);
      }
    },
    [myRef, callback]
  );

  return {
    referenceFn: setMyRef,
    reference: myRef
  };
};
