import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./AboutPage.scss";
import classNames from "classnames";
import { FEEDBACK } from "../../modal/modalTypes";
import FullWidthBannerImage from "../../fullWidgetBannerImage/FullWidthBannerImage";
import bannerImage from "../../../resources/water-banner.jpg";
import editLayoutVideo from "../../../resources/Dashboi-edit-layout-gif.mp4";
import Card from "./Card";
import UnsplashCredit from "../../unsplashCredit/UnsplashCredit";
import FullWidthSection from "../FullWidthSection";
import { getIsPremiumEnabled } from "../../../utils/featureToggles";
import PricingOptions from "../../pricingOptions/PricingOptions";
import history from "../../../utils/history";
import { useDispatch } from "react-redux";
import { showModal } from "../../../actions/modalActions";

export default function AboutPage() {
  const dispatch = useDispatch();

  const onFeedbackClick = event => {
    event.preventDefault();
    dispatch(showModal(FEEDBACK));
  };

  const onSignUpNowClick = event => {
    event.preventDefault();
    history.push("/signup");
  };

  const [videoWidth, setVideoWidth] = useState(
    window.innerWidth >= 768 ? "50%" : "100%"
  );

  const handleResize = () =>
    window.innerWidth >= 768 ? setVideoWidth("50%") : setVideoWidth("100%");

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="about-page">
      <Helmet>
        <title>About Dashboi</title>
        <meta
          name="description"
          content="Read all about the history and goals of the Dashboi application. Learn about the capabilities and future of the personal dashboard application."
        />
        <meta
          name="og:title"
          property="og:title"
          content="About the Dashboi Application"
        />
        <link rel="canonical" href="https://dashboi.com/about" />
      </Helmet>
      <FullWidthBannerImage
        backgroundImage={bannerImage}
        bannerText="Welcome to your personal workspace"
      >
        <UnsplashCredit
          photographerName="Giga Khurtsilava"
          photographLink="https://unsplash.com/@gigakhurtsilava"
        />
        <button className="sign-up-now" onClick={onSignUpNowClick}>
          Sign Up Now
        </button>
      </FullWidthBannerImage>
      <FullWidthSection>
        <div className="cards">
          <Card icon="MONEY" title="Finance">
            <p>
              Track crypto and stock symbols using preconfigured charts and
              tickers. Powered by{" "}
              <a
                href="https://www.tradingview.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trading View
              </a>
            </p>
          </Card>
          <Card icon="PRODUCTIVITY" title="Productivity">
            <p>
              Organize the work you need to get done using task and bookmark
              components.
            </p>
          </Card>
          <Card icon="NEWS" title="News">
            <p>
              Access popular newsfeeds to stay informed about what's important
              to you.
            </p>
          </Card>
        </div>
        <p className="components-link">
          <a href="/components">
            Read about all the components available on Dashboi
          </a>
        </p>
      </FullWidthSection>
      <FullWidthSection hasDarkBackground={true}>
        <h1 className="section-header" id="layout-design">
          Design Your Layout
        </h1>
        <div className={classNames("about-subsection", "video")}>
          <video
            className="dashboi-video"
            width={videoWidth}
            height="auto"
            autoPlay={true}
            loop={true}
            data-testid="quick-look-video"
          >
            <source src={editLayoutVideo} type="video/mp4" />
          </video>
          <div className="video-section-text">
            <p>The way you work is unique, and your workspace should be too.</p>
            <ul>
              <li>Easily add components to the layout.</li>
              <li>Adjust the size and positioning of each component.</li>
              <li>Change the background image.</li>
              <li>Create a workspace that works for you.</li>
            </ul>
          </div>
        </div>
      </FullWidthSection>
      {getIsPremiumEnabled() ? (
        <FullWidthSection>
          <h1 className="section-header" id="pricing">
            Pricing
          </h1>
          <PricingOptions showPlanFeatures={true} />
        </FullWidthSection>
      ) : null}
      <FullWidthSection
        hasDarkBackground={getIsPremiumEnabled() ? true : false}
      >
        <h1>The Future of Dashboi</h1>
        <p>
          Dashboi was started with a big goal in mind: to be the central hub for
          a suite of applications aimed at improving focus, reducing cognitive
          load, and tracking your progress towards goals. Ultimately, we want
          Dashboi to be your personal assistant on the web, helping you do the
          things you do most often, sometimes even by doing them for you. The
          Dashboi you see now is a step toward that goal.
        </p>
        <p>
          Obviously, we've got a long way to go. We've got a whole backlog of
          projects we want to incorporate into Dashboi, but first, we want your
          feedback. What are the features that you're looking for in personal
          dashboard? Are there any huge gaps that we've missed? We're pretty
          sure there are. Don't hesitate to{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={onFeedbackClick}>
            let us know how we're doing
          </a>
          .
        </p>
        <p>
          Finally, Dashboi is a free to use application, but we humbly ask that
          if you use it and enjoy it, you support it by{" "}
          <a
            href="https://paypal.me/dashboi"
            alt="Link to Paypal Account"
            target="_blank"
            rel="noopener noreferrer"
          >
            donating
          </a>
          . This will enable us to keep the lights on, build new features, and
          eventually, realize Dashboi's full potential.
        </p>
        <p>
          Thank you so much for giving Dashboi a try. We really hope you enjoy
          it.
        </p>
      </FullWidthSection>
    </div>
  );
}
