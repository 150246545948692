import React from "react";
import CenterPane from "../../centerPane/CenterPane";
import cat from "../../../resources/404cat.jpg";
import UnsplashCredit from "../../unsplashCredit/UnsplashCredit";
import "./PageNotFound.scss";

export default function PageNotFound() {
  return (
    <CenterPane>
      <h1>404 - Page Not Found</h1>
      <p>
        Uh oh, it looks like you were trying to reach a page that doesn't exist.
      </p>
      <p>
        We're sorry you couldn't find what you were looking for. Here's a
        picture of a cat instead.
      </p>
      <div className="cat-container">
        <img className="cat-image" src={cat} alt="Smiling Kitty Cat" />
        <UnsplashCredit
          photographerName="Jae Park"
          photographLink="https://unsplash.com/@jaehunpark"
          isFullWidthImage={false}
        />
      </div>
    </CenterPane>
  );
}
