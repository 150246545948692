import axios from "axios";

export function createSubscription(
  customer_id,
  email,
  price_id,
  paymentMethodId
) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/stripe-create-subscription", {
        customer_id,
        email,
        price_id,
        paymentMethodId
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
