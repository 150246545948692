import React from "react";
import ReactSlider from "react-slider";
import "./HorizontalSlider.scss";
import classNames from "classnames";

export default function HorizontalSlider(props) {
  const thumbId = props.thumbId;

  const onSliderChange = newVal => {
    props.onChange(newVal);
  };

  const getRenderThumbFn = () => {
    if (props.renderThumb) {
      return props.renderThumb;
    } else {
      return (props, state) => (
        <div {...props} data-testid="slider-thumb" id={thumbId} />
      );
    }
  };

  return (
    <ReactSlider
      className={classNames("horizontal-slider", props.className)}
      thumbClassName={classNames("thumb", props.thumbClassName)}
      trackClassName="track"
      min={props.min}
      max={props.max}
      value={props.value}
      withTracks={true}
      onChange={onSliderChange}
      renderThumb={getRenderThumbFn()}
    />
  );
}

HorizontalSlider.defaultProps = {
  onChange: () => {},
  value: 0,
  min: 0,
  max: 10,
  thumbId: null,
  renderThumb: null,
  thumbClassName: null,
  className: null
};
