import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../actions/modalActions";
import ButtonFooterLayout from "../buttonFooterLayout/ButtonFooterLayout";
import { cancelSubscription } from "../../axios/CancelSubscriptionFacade";
import Banner from "../banner/banner";
import { bannerSentiment } from "../banner/bannerUtils";
import LoadingGlass from "../loadSpinner/LoadingGlass";
import { cancelUserSubscription } from "../../actions/userActions";

export default function CancelPremiumModal() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { email, subscriptionId, uid } = useSelector(
    state => state.userDetails
  );

  const onKeepSubscriptionClick = () => {
    dispatch(hideModal());
  };

  const onCancelSubscriptionClick = () => {
    setIsProcessing(true);
    cancelSubscription(email, subscriptionId)
      .then(() => {
        dispatch(cancelUserSubscription(uid));
        setIsProcessing(false);
        dispatch(hideModal());
      })
      .catch(() => {
        setIsProcessing(false);
        setError(
          "There was an issue cancelling your subscription. Please contact the dashboi team at dashboi.app@gmail.com"
        );
      });
  };

  return (
    <div>
      {isProcessing ? (
        <LoadingGlass style={{ transform: "translate(-30px, -95px)" }} />
      ) : null}
      {error !== null ? (
        <Banner sentiment={bannerSentiment.NEGATIVE} message={error} />
      ) : null}
      <p>
        Cancelling your subscription means you'll lose access to the sweet
        features you've come to know and love, such as Custom Styles.
      </p>
      <p>
        Clicking cancel today will immediately revoke your access to these
        features. Are you sure you wish to continue?
      </p>
      <ButtonFooterLayout
        primaryButtonText="Cancel my subscription"
        secondaryButtonText="Keep my subscription"
        onSecondaryButtonClick={onKeepSubscriptionClick}
        onPrimaryButtonClick={onCancelSubscriptionClick}
      />
    </div>
  );
}
