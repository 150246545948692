import React, { Fragment, useState } from "react";
import { useRefCallback } from "../../custom-hooks/useRefCallback";
import "./WidgetHeaderContent.scss";

export default function WidgetHeaderContent(props) {
  const [headerHeight, setHeaderHeight] = useState(24);
  const setHeaderRef = useRefCallback(myRef => {
    setHeaderHeight(myRef.current.clientHeight);
  });

  return (
    <Fragment>
      <div ref={setHeaderRef.referenceFn} className="header-elements">
        {props.headerElements}
      </div>
      <div
        className="content-elements"
        style={{
          height: `calc(100% - ${headerHeight}px - 10px)` /* to avoid duplicate scrollbars, subtract the amount of space of the title, and add 10px on bottom */
        }}
      >
        {props.contentElements}
      </div>
    </Fragment>
  );
}
