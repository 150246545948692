import React from "react";
import "./CenterPane.scss";
import classNames from "classnames";
import { useMobile } from "../../custom-hooks/useMobile";

export default function CenterPane(props) {
  const { isMobile } = useMobile({});

  return (
    <div className={classNames("center-pane", isMobile ? "mobile-view" : "")}>
      {props.children}
    </div>
  );
}
