import React from "react";

export default function A(props) {
  return (
    <a
      className={props.className}
      href={props.href}
      target={props.target}
      rel={props.rel}
    >
      {props.children}
    </a>
  );
}

A.defaultProps = {
  href: "",
  target: "_blank",
  rel: "noopener noreferrer",
  className: ""
};
