import { db } from "../utils/firebase-config";

export function writeFeedback({ uid, sentimentScore, title, body }) {
  return new Promise(function (resolve, reject) {
    db.collection("feedback")
      .add({
        uid,
        sentimentScore,
        title,
        body
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
}
