import React, { useState, useLayoutEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import MultiSelectDropdown from "../../multiSelectDropdown/MultiSelectDropdown";
import Button from "../../button/button";
import stocks from "../../../utils/stocks.json";
import InputLabel from "../../input/InputLabel";
import "./EditMarketOverview.scss";
import { saveWidget, addMarketOverview } from "../../../actions/layoutActions";
import { hideModal } from "../../../actions/modalActions";
import Checkbox from "../../checkbox/CheckBox";
import { generateSymbolsFromSelections } from "../FinanceUtils";
import {
  eventCategories,
  recordEvent
} from "../../../utils/googleAnalyticsEvents";

/*
 * TODO: Pull data from server instead of doing it locally in browser (bad performance)
 */
export default function EditMarketOverview(props) {
  const marketOverviews = useSelector(state => state.layouts.marketOverviews);
  const dispatch = useDispatch();
  const firstCheckboxRef = useRef();

  const marketOverview = marketOverviews
    ? marketOverviews.find(overview => overview.widget_id === props.widget_id)
    : null;

  // We are saving `symbol - company name` in tickerBanner.symbols
  const symbols = [];
  if (marketOverview?.symbols) {
    marketOverview.symbols.forEach(symbol => {
      const keys = symbol.split(":");
      symbols.push(keys[1]);
    });
  }
  const [selections, setSelections] = useState(marketOverview ? symbols : []);
  const [showIndices, setShowIndices] = useState(
    marketOverview ? marketOverview.showIndices : true
  );
  const [showCrypto, setShowCrypto] = useState(
    marketOverview ? marketOverview.showCrypto : true
  );

  const choices = [];
  Object.keys(stocks).forEach(key => {
    const keys = key.split(":");
    choices.push(keys[1].concat(" - ").concat(stocks[key]));
  });

  const onSubmit = () => {
    const symbols = generateSymbolsFromSelections(selections);

    recordEvent(
      "Market Overview Config Saved",
      eventCategories.COMPONENT_CONFIGURATION,
      "Market Overview Config Saved"
    );
    dispatch(saveWidget(props.widget_id));
    dispatch(
      addMarketOverview({
        widget_id: props.widget_id,
        symbols,
        showIndices,
        showCrypto
      })
    );
    dispatch(hideModal());
  };

  useLayoutEffect(() => {
    if (firstCheckboxRef?.current) {
      firstCheckboxRef.current.focus();
    }
  }, [firstCheckboxRef]);

  return (
    <div className="edit-market-overview-wrapper">
      <div className="market-overview-options">
        <InputLabel label="Options" />
        <Checkbox
          isChecked={showIndices}
          onChange={() => setShowIndices(!showIndices)}
          uniqueId="showIndices"
          label="Show tab containing market indices"
          reference={firstCheckboxRef}
        />
        <Checkbox
          isChecked={showCrypto}
          onChange={() => setShowCrypto(!showCrypto)}
          uniqueId="showCrypto"
          label="Show tab containing top crypto symbols"
        />
      </div>
      <InputLabel
        label="My Stock and Crypto picks (max 5)"
        helpText="Select the set of stock & crypto symbols you want to track in the overview"
      />
      <MultiSelectDropdown
        choices={choices}
        maxSelections={5}
        placeholder="Search symbols..."
        selections={selections}
        setSelections={setSelections}
        usePermanentAccent
      />
      <Button
        onClick={onSubmit}
        className="edit-ticker-button"
        usePermanentAccent
      >
        Save Market Overview
      </Button>
    </div>
  );
}

EditMarketOverview.defaultProps = {
  widget_id: ""
};
