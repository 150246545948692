import React, { Fragment, useState, useRef, useLayoutEffect } from "react";
import "./DeleteAccount.scss";
import ButtonFooterLayout from "../buttonFooterLayout/ButtonFooterLayout";
import { sendAccountDeletionEmail } from "../../axios/AccountDeletionFacade";
import Banner from "../banner/banner";
import { bannerSentiment } from "../banner/bannerUtils";
import { useSelector } from "react-redux";
import { ACCOUNT_DELETION_STATUSES } from "../../reducers/authenticationReducer";

export default function DeleteAccount(props) {
  const [didErrorOccur, setDidErrorOccur] = useState(false);

  const { accountDeletionStatus } = useSelector(state => state.login);

  const onConfirmButtonClick = event => {
    event.preventDefault();
    sendAccountDeletionEmail({ uid: props.uid })
      .then(res => {
        props.onDeleteAccountClick(props.uid);
      })
      .catch(err => setDidErrorOccur(true));
  };

  const cancelButtonRef = useRef();

  useLayoutEffect(() => {
    if (cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    }
  }, [cancelButtonRef]);

  return (
    <Fragment>
      {didErrorOccur ||
      accountDeletionStatus === ACCOUNT_DELETION_STATUSES.FAILED ? (
        <Banner
          message="Error occurred deleting account. Error code: ERR03. Contact the Dashboi team at dashboi.app@gmail.com."
          sentiment={bannerSentiment.NEGATIVE}
        />
      ) : null}
      <p>We're sad to see you go!</p>
      <p>
        Deleting your account is permanent. Confirming deletion will remove your
        account as well as all data that you have entered, including your
        layouts, bookmarks and tasks.
      </p>
      <ButtonFooterLayout
        onSecondaryButtonClick={props.onCancelClick}
        onPrimaryButtonClick={onConfirmButtonClick}
        secondaryButtonText="Cancel"
        primaryButtonText="Delete"
        secondaryButtonRef={cancelButtonRef}
      />
    </Fragment>
  );
}

DeleteAccount.defaultProps = {
  onCancelClick: () => {},
  onDeleteAccountClick: () => {}
};
