import { db } from "../utils/firebase-config";

export function writeError({ uid, error, cause }) {
  return new Promise(function (resolve, reject) {
    try {
      db.collection("errors")
        .add({
          uid,
          error,
          cause,
          date: new Date()
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    } catch (err) {
      // do nothing
    }
  });
}
