import React, { Fragment, useState } from "react";
import "./TaskList.scss";
import Input from "../input/input";
import Task from "./Task";
import ClickableText from "../clickableText/ClickableText";
import { firestore } from "firebase/app";
import WidgetHeaderContent from "../widgetHeaderContent/WidgetHeaderContent";
import H, { headerSizes } from "../elements/H";
import { useDispatch, useSelector } from "react-redux";
import { addTask, filterTasks } from "../../actions/taskActions";

export default function TaskList(props) {
  const [currentTaskName, setCurrentTaskName] = useState("");
  const dispatch = useDispatch();
  const { uid } = useSelector(state => state.userDetails);
  const { isCompletedFilter, taskList } = useSelector(state => state.task);

  const addNewTask = event => {
    event.preventDefault();
    if (currentTaskName) {
      const task = {
        id: null,
        taskName: currentTaskName,
        createdDate: firestore.Timestamp.now(),
        uid,
        isCompleted: false,
        isArchived: false
      };

      dispatch(addTask(task));
      setCurrentTaskName("");
    }
  };

  const handleChange = event => {
    setCurrentTaskName(event.target.value);
  };

  const generateTaskList = (sortedTaskList, isCompletedList) => {
    return (
      <div className="task-list">
        {sortedTaskList.map(task => {
          return (
            <Task key={task.id} task={task} isCompletedList={isCompletedList} />
          );
        })}
      </div>
    );
  };

  const getHeader = isCompletedFilter => {
    if (isCompletedFilter) {
      return null;
    } else {
      return (
        <form onSubmit={addNewTask}>
          <div className="add-task-container">
            <Input
              placeholder="Task Name"
              id={`taskName_${props.widget_id}`}
              type="text"
              name="currentTaskName"
              value={currentTaskName}
              onChange={handleChange}
              className="body-layout-font"
            />
          </div>
        </form>
      );
    }
  };

  const getPage = (sortedTaskList, isCompletedFilter) => {
    if (isCompletedFilter) {
      if (sortedTaskList.length > 0) {
        return (
          <div className="task-list-container">
            {generateTaskList(sortedTaskList, true)}
          </div>
        );
      } else {
        return (
          <div className="task-list-container">
            <p className="body-layout-font">
              <em>You haven't closed any tasks yet!</em>
            </p>
          </div>
        );
      }
    } else {
      return (
        <div className="task-list-container">
          {generateTaskList(sortedTaskList, false)}
        </div>
      );
    }
  };

  const onCompletionFilterClick = event => {
    event.preventDefault();
    dispatch(filterTasks(!isCompletedFilter));
  };

  const sortFn = (a, b) => {
    if (a.createdDate && b.createdDate) {
      return (
        new firestore.Timestamp(
          b.createdDate.seconds,
          b.createdDate.nanoseconds
        ).toDate() -
        new firestore.Timestamp(
          a.createdDate.seconds,
          a.createdDate.nanoseconds
        ).toDate()
      );
    } else if (a.createdDate) {
      return -1;
    } else {
      return 1;
    }
  };

  const sortedTaskList = isCompletedFilter
    ? taskList.filter(task => task.isArchived).sort(sortFn)
    : taskList.filter(task => !task.isArchived).sort(sortFn);

  return (
    <div className="task-wrapper">
      <WidgetHeaderContent
        headerElements={
          <Fragment>
            <div className="task-header">
              <H
                size={headerSizes.EXTRA_SMALL}
                className="header-layout-font"
                usePermanentAccent={false}
              >
                Tasks
              </H>
              <ClickableText
                onClick={onCompletionFilterClick}
                className="body-layout-font"
              >
                {isCompletedFilter ? "Active Tasks" : "Archived Tasks"}
              </ClickableText>
            </div>
            {getHeader(isCompletedFilter)}
          </Fragment>
        }
        contentElements={getPage(sortedTaskList, isCompletedFilter)}
      />
    </div>
  );
}
