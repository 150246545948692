import React from "react";
import "./SubtleSeparator.scss";
import classNames from "classnames";

export default function SubtleSeparator(props) {
  return (
    <div
      className="subtle-separator"
      style={{ margin: `${props.verticalMargin}px 0px` }}
    >
      <span className={classNames("divider", "first")} />
      <span>{props.text}</span>
      <span className={classNames("divider", "last")} />
    </div>
  );
}

SubtleSeparator.defaultProps = {
  verticalMargin: 0,
  text: "OR"
};
