import React from "react";
import FeedStory from "../feedStory/feedStory";
import { useRssFetch } from "../../custom-hooks/useRssFetch";
import LoadSpinner from "../loadSpinner/LoadSpinner";

const GamasutraFeed = props => {
  const feed = useRssFetch("http://feeds.feedburner.com/GamasutraNews");

  const getImageFromDescription = desc => {
    const index = desc.search('src="');
    if (index === -1) {
      return "";
    } else {
      const sliced = desc.slice(index + 5, desc.length);
      const lastIndex = sliced.indexOf('"');
      return sliced.slice(0, lastIndex);
    }
  };

  if (!feed || !feed.items) {
    return <LoadSpinner />;
  }
  const stories = [];
  feed.items.forEach(function (entry) {
    stories.push({
      name: entry.title,
      url: entry.link,
      img: getImageFromDescription(entry.content)
    });
  });

  return (
    <div className="rssFeed">
      {stories.map((story, i) => {
        return <FeedStory key={i} story={story} shouldShowImage={i === 0} />;
      })}
    </div>
  );
};

export default React.memo(GamasutraFeed);
