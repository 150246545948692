import { useLayoutEffect } from "react";

/*
 * Registers a click event that checks to see if a click happened within a DOM
 * element or ANY of its children.
 * Useful if you want to keep a menu open, but close it if the user shifts their focus elsewhere.
 */
export const useClickOutside = (myRef, setIsVisible) => {
  useLayoutEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  });

  const handleClickOutside = event => {
    if (myRef && myRef.current !== null) {
      if (
        event.target !== myRef.current &&
        !myRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    }
  };
};
