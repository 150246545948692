import React from "react";
import { useDispatch } from "react-redux";
import { setExperimentalMode } from "../../actions/globalActions";
import { hideModal } from "../../actions/modalActions";
import ButtonFooterLayout from "../buttonFooterLayout/ButtonFooterLayout";
import "./Modal.scss";
import PremiumModal from "./PremiumModal";

export default function PremiumOnlyModal(props) {
  const dispatch = useDispatch();
  return (
    <div className="premium-only-modal">
      <PremiumModal
        renderButtons={confirmCallback => (
          <ButtonFooterLayout
            onSecondaryButtonClick={() => {
              dispatch(setExperimentalMode(true));
              dispatch(hideModal());
            }}
            onPrimaryButtonClick={async () => {
              await confirmCallback();
              dispatch(hideModal());
            }}
            secondaryButtonText="Let Me Experiment"
            primaryButtonText="Go Premium"
          />
        )}
      >
        <p>
          The {props.subType} feature is only available for premium customers.
          Dashboi is funded by customers just like you who choose how much they
          are willing to pay. Most people choose $5.
        </p>
        <p>
          If you just want to experiment with premium features, you may do so,
          but you will not be allowed to save.
        </p>
      </PremiumModal>
    </div>
  );
}

PremiumOnlyModal.defaultProps = {
  subType: "Styles"
};
