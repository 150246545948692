export const ENTER = "Enter";
export const ENTER_TEST_VAL = { key: "Enter", code: "Enter", charCode: 13 };
export const DOWN = "Down";
export const DOWN_TEST_VAL = { key: "Down", code: "Down", charCode: 40 };
export const ARROW_DOWN = "ArrowDown";
export const UP = "Up";
export const UP_TEST_VAL = { key: "Up", code: "Up", charCode: 38 };
export const ARROW_UP = "ArrowUp";
export const TAB = "Tab";
export const TAB_TEST_VAL = { key: "Tab", code: "Tab", charCode: 9 };
export const SHIFT_TAB_TEST_VAL = {
  key: "Tab",
  code: "Tab",
  charCode: 9,
  shiftKey: true
};
export const SPACE = "(space)";

export const handleEnterKeypress = (event, callback) => {
  const key = event.key;
  if (key === ENTER) {
    callback(event);
  }
};

export const handleDownKeypress = (event, callback) => {
  const key = event.key;
  if (key === DOWN || key === ARROW_DOWN) {
    callback(event);
  }
};

export const handleUpKeypress = (event, callback) => {
  const key = event.key;
  if (key === UP || key === ARROW_UP) {
    callback(event);
  }
};

export const handleTabKeypress = (event, callback) => {
  const key = event.key;
  if (key === TAB) {
    callback(event);
  }
};

export const handleSpaceKeypress = (event, callback) => {
  const key = event.key;
  if (key === SPACE) {
    callback(event);
  }
};

export const handleShiftTabKeypress = (event, callback) => {
  const key = event.key;
  if (event.shiftKey && key === TAB) {
    callback(event);
  }
};
