import React, { useState, useRef, useLayoutEffect } from "react";
import { FaCaretDown, FaTimes, FaCheck } from "react-icons/fa";
import "./MultiSelectDropdown.scss";
import { useFormInput } from "../../custom-hooks/useFormInput";
import { useClickOutsidePortal } from "../../custom-hooks/useClickOutsidePortal";
import classNames from "classnames";
import InputLabel from "../input/InputLabel";
import { useInputWidth } from "../../custom-hooks/useInputWidth";
import { useFocusedDropdownIndex } from "../../custom-hooks/useFocusedDropdownIndex";

export default function MultiSelectDropdown(props) {
  const searchTerm = useFormInput("");
  const localChoices = props.choices;
  const filteredChoices = localChoices.filter(choice =>
    choice.toLowerCase().includes(searchTerm.value.toLowerCase())
  );

  const {
    inputWidth,
    inputRef: multiSelectComponentRef,
    setInputRef: setMultiSelectRef
  } = useInputWidth();
  const tempRef = useRef();
  const searchInputRef = props.reference || tempRef;
  const dropdownChoicesRef = useRef();

  // handles dropdown visibility
  const [isVisible, setIsVisible] = useState(false);
  const toggleDropdown = async () => {
    setIsVisible(!isVisible);
  };
  useClickOutsidePortal(
    multiSelectComponentRef,
    dropdownChoicesRef,
    setIsVisible
  );

  // either adds or removes the an element from the selections
  const onDropdownChoiceClick = (event, choice, index) => {
    if (props.selections.includes(choice)) {
      removeSelection(event, props.selections.indexOf(choice));
    } else {
      if (!(props.selections.length >= props.maxSelections)) {
        const selectionsCopy = props.selections.slice();
        selectionsCopy.push(choice);
        props.setSelections(selectionsCopy);
      }
      if (props.selections.length + 1 >= props.maxSelections) {
        toggleDropdown();
      }
    }
  };

  // deselect a choice from the multi-select
  const removeSelection = (event, index) => {
    event.stopPropagation();
    const selectionsCopy = props.selections.slice();
    selectionsCopy.splice(index, 1);
    props.setSelections(selectionsCopy);
  };

  // open or close the dropdown when the component is clicked
  const onMultiSelectClick = async event => {
    event.stopPropagation();
    await toggleDropdown(!isVisible);
    if (
      searchInputRef.current !== null &&
      searchInputRef.current !== undefined
    ) {
      if (!isVisible) {
        searchInputRef.current.focus();
      } else {
        searchInputRef.current.blur();
      }
    }
  };

  // always show the dropdown when the input is clicked
  const showDropdown = event => {
    // event.stopPropagation();
    if (!isVisible) {
      setIsVisible(true);
    }
  };

  const onEnterKeyPress = (event, focusedIndex) => {
    const mySelections = props.selections.slice();
    const myChoice = filteredChoices[focusedIndex];
    if (mySelections.includes(myChoice)) {
      removeSelection(event, mySelections.indexOf(myChoice));
    } else if (filteredChoices.length !== 0) {
      mySelections.push(filteredChoices[focusedIndex]);
      props.setSelections(mySelections);
    }
    searchTerm.onChange({ target: { value: "" } });
  };
  const onTabKeyPress = event => {
    toggleDropdown();
  };
  const { focusedIndex, setFocusedIndex, onKeyPress } = useFocusedDropdownIndex(
    searchInputRef,
    props.choices,
    onEnterKeyPress,
    onTabKeyPress
  );

  const handleInputChange = event => {
    setFocusedIndex(0);
    searchTerm.onChange(event);
  };

  useLayoutEffect(() => {
    const elements = document.getElementsByClassName("focused");
    if (elements.length > 0) {
      elements.item(0).scrollIntoView();
    }
  }, [focusedIndex]);

  const multiSelectComponent = (
    <div
      className={classNames(
        "multi-dropdown-input",
        props.usePermanentAccent && "use-permanent-accent"
      )}
      onClick={onMultiSelectClick}
      ref={setMultiSelectRef}
      onKeyDown={onKeyPress}
    >
      <div className="selections-and-input">
        {props.selections.length > 0 ? (
          <div className="selection-list">
            {props.selections.map((selection, index) => {
              return (
                <span key={index} className="selection" title={selection}>
                  <p>{selection}</p>
                  {
                    <FaTimes
                      aria-label="Remove Selection"
                      onClick={event => removeSelection(event, index)}
                    />
                  }
                </span>
              );
            })}
          </div>
        ) : null}
        {props.maxSelections === 1 && props.selections.length === 1 ? null : (
          <input
            type="text"
            value={searchTerm.value}
            onChange={handleInputChange}
            placeholder={props.placeholder}
            ref={searchInputRef}
            onClick={showDropdown}
            onKeyDown={showDropdown}
            aria-label="Search..."
          />
        )}
      </div>
      <FaCaretDown
        className="multi-dropdown-caret"
        aria-label="Open selectable options"
      />
    </div>
  );

  const choices = () => {
    const hasReachedMaxSelections =
      props.selections.length >= props.maxSelections;
    if (filteredChoices.length === 0) {
      return (
        <div
          className="multi-dropdown-choices"
          ref={dropdownChoicesRef}
          style={{ width: `${inputWidth}px` }}
          data-testid="dropdown-choices"
        >
          <div className="multi-dropdown-choice">No options available</div>
        </div>
      );
    } else {
      return (
        <div
          className="multi-dropdown-choices"
          ref={dropdownChoicesRef}
          style={{ width: `${inputWidth}px` }}
          onKeyDown={onKeyPress}
          data-testid="dropdown-choices"
        >
          {filteredChoices.map((choice, index) => {
            const isSelected = props.selections.includes(choice);
            return (
              <div
                className={classNames(
                  "multi-dropdown-choice",
                  hasReachedMaxSelections && !isSelected
                    ? "not-selectable"
                    : "selectable",
                  isSelected ? "selected" : null,
                  index === focusedIndex ? "focused" : null,
                  props.usePermanentAccent && "use-permanent-accent"
                )}
                onClick={event => onDropdownChoiceClick(event, choice, index)}
                key={index}
                title={choice}
              >
                {choice}
                {isSelected ? <FaCheck aria-label="Deselect Option" /> : null}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={classNames("multi-select-dropdown")}>
      {props.label ? (
        <InputLabel label={props.label} helpText={props.helpText} />
      ) : null}
      {multiSelectComponent}
      {isVisible ? choices() : null}
    </div>
  );
}

MultiSelectDropdown.defaultProps = {
  label: null,
  helpText: null,
  choices: [],
  maxSelections: 5,
  placeholder: "Search...",
  selections: [],
  setSelections: () => {},
  reference: null,
  usePermanentAccent: true
};
