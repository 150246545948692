import React from "react";
import "./MobileMenu.scss";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

export default function MobileMenu(props) {
  let location = useLocation();

  return (
    <div
      className={classNames(
        "menu-links-mobile",
        props.isMobileMenuVisible ? "open" : "closed"
      )}
      data-testid="mobile-menu"
    >
      {props.isLoggedIn ? (
        <a
          href="/app"
          alt="My Dashboard"
          className={classNames(
            "menu-link",
            location.pathname === "app" ? "active" : null
          )}
          onClick={props.hideMobileMenu}
        >
          My Dashboard
        </a>
      ) : (
        <a
          href="/"
          alt="Home"
          className={classNames(
            "menu-link",
            location.pathname === "/" ? "active" : null
          )}
          onClick={props.hideMobileMenu}
        >
          Home
        </a>
      )}
      <a
        href="/contact"
        alt="Contact"
        className={classNames(
          "menu-link",
          location.pathname === "/contact" ? "active" : null
        )}
        onClick={props.hideMobileMenu}
      >
        Contact
      </a>
    </div>
  );
}
