import React from "react";
import "./feedStory.scss";
import H, { headerSizes } from "../elements/H";
import A from "../elements/A";
import classNames from "classnames";

export default function FeedStory(props) {
  if (props.story.url === "" || props.story.name === "") {
    return null;
  } else if (props.shouldShowImage) {
    return (
      <div className="featured-story">
        <div className="featured-image-container">
          <A href={props.story.url} target="_blank" rel="noopener noreferrer">
            <img
              className="featured-image"
              src={props.story.img}
              alt={props.story.name + " featured image"}
            />
          </A>
        </div>
        <A
          className="featured-story-overlay"
          href={props.story.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <H
            size={headerSizes.SMALL}
            className={classNames("featured-story-name", "header-layout-font")}
          >
            {props.story.name}
          </H>
          {props.story.author ? (
            <span
              className={classNames(
                "featured-story-details",
                "body-layout-font"
              )}
            >
              <em>{props.story.author}</em>
            </span>
          ) : null}
        </A>
      </div>
    );
  } else {
    return (
      <div className="feed-story">
        <h3 className="story-body">
          <A
            href={props.story.url}
            target="_blank"
            rel="noopener noreferrer"
            className="header-layout-font"
          >
            {props.story.name}
          </A>
        </h3>
        <em className={classNames("story-author", "body-layout-font")}>
          {props.story.author}
        </em>
      </div>
    );
  }
}
