import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import "./MarketOverview.scss";
import { createTradingViewSymbolMap } from "../FinanceUtils";
import Color from "color";

export default function MarketOverview(props) {
  const marketOverviews = useSelector(state => state.layouts.marketOverviews);
  const marketOverview = marketOverviews
    ? marketOverviews.find(overview => overview.widget_id === props.widget_id)
    : null;
  const { accentColor } = useSelector(state => state.styles);
  const rgbAccent = `rgb(${accentColor.r}, ${accentColor.g}, ${accentColor.b})`;
  const opaqueColor = Color(rgbAccent).alpha(0.65);
  const moreOpaqueColor = Color(rgbAccent).alpha(0.12);
  const id = "tradingview-widget-container__widget".concat(props.widget_id);

  const tickerWidgetRef = useRef(null);
  const setRef = useCallback(
    node => {
      if (node) {
        const element = document.getElementById(id);
        if (element.childNodes.length > 0) {
          element.removeChild(element.childNodes[0]);
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
        script.async = true;

        const symbols = marketOverview
          ? createTradingViewSymbolMap(marketOverview.symbols, "s", "d")
          : [];
        const showIndices = marketOverview ? marketOverview.showIndices : true;
        const showCrypto = marketOverview ? marketOverview.showCrypto : true;
        const tabs = [];

        if (symbols.length > 0) {
          tabs.push({
            title: "My Picks",
            symbols: symbols
          });
        }

        if (showIndices) {
          tabs.push({
            title: "Indices",
            symbols: [
              {
                s: "FOREXCOM:SPXUSD",
                d: "S&P 500"
              },
              {
                s: "FOREXCOM:NSXUSD",
                d: "Nasdaq 100"
              },
              {
                s: "FOREXCOM:DJI",
                d: "Dow 30"
              },
              {
                s: "INDEX:NKY",
                d: "Nikkei 225"
              },
              {
                s: "INDEX:DEU30",
                d: "DAX Index"
              }
            ],
            originalTitle: "Indices"
          });
        }

        if (showCrypto) {
          tabs.push({
            title: "Crypto",
            symbols: [
              {
                s: "COINBASE:BTCUSD",
                d: "Bitcoin"
              },
              {
                s: "COINBASE:ETHUSD",
                d: "Ethereum"
              },
              {
                s: "COINBASE:LTCUSD",
                d: "Litecoin"
              },
              {
                s: "COINBASE:XRPUSD",
                d: "XRP"
              },
              {
                s: "COINBASE:DASHUSD",
                d: "Dash"
              }
            ]
          });
        }

        script.innerHTML = `{
        "colorTheme": "light",
        "dateRange": "1d",
        "showChart": true,
        "locale": "en",
        "width": "100%",
        "height": "100%",
        "largeChartUrl": "",
        "isTransparent": false,
        "plotLineColorGrowing": "${rgbAccent || "rgba(116, 27, 71, 1)"}",
        "plotLineColorFalling": "${rgbAccent || "rgba(116, 27, 71, 1)"}",
        "gridLineColor": "${opaqueColor.rgb() || "rgba(240, 243, 250, 1)"}",
        "scaleFontColor": "${opaqueColor.rgb() || "rgba(120, 123, 134, 1)"}",
        "belowLineFillColorGrowing": "${
          moreOpaqueColor.rgb() || "rgba(234, 209, 220, 0.12)"
        }",
        "belowLineFillColorFalling": "${
          moreOpaqueColor.rgb() || "rgba(234, 209, 220, 0.12)"
        }",
        "symbolActiveColor": "${
          moreOpaqueColor.rgb() || "rgba(194, 123, 160, 0.16)"
        }",
        "tabs": ${JSON.stringify(tabs)}
      }`;

        node.appendChild(script);
      }

      tickerWidgetRef.current = node;
    },
    [marketOverview, id, rgbAccent, opaqueColor, moreOpaqueColor]
  );

  return (
    <div className="tradingview-widget-container__market-overview">
      <div
        className="tradingview-widget-container__market-overview__widget"
        id={id}
        ref={setRef}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="blue-text">My Picks</span>
        </a>{" "}
        by TradingView
      </div>
    </div>
  );
}

MarketOverview.defaultProps = {
  widget_id: ""
};
