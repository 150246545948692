import React, { useState, Fragment } from "react";
import classNames from "classnames";
import "./Clock.scss";
import useInterval from "../../custom-hooks/useInterval";
import { useSelector } from "react-redux";
import { WHITE, getRGBFromColorObject } from "../../utils/colors";

export const TIME_FORMATS = {
  STANDARD: "Standard",
  MILITARY: "Military"
};

export const CLOCK_SIZES = {
  LARGE: "Large",
  MEDIUM: "Medium",
  SMALL: "Small"
};

export const CLOCK_ALIGNMENT = {
  LEFT: "Left",
  CENTER: "Center",
  RIGHT: "Right"
};

export default function Clock(props) {
  const { clocks } = useSelector(state => state.layouts);
  const clockConfig = clocks
    ? clocks.find(clock => clock.widget_id === props.widget_id)
    : null;
  const timeFormat = clockConfig?.timeFormat || TIME_FORMATS.STANDARD;
  const showSeconds =
    clockConfig?.showSeconds === undefined ? true : clockConfig.showSeconds;
  const clockSize = clockConfig?.clockSize || CLOCK_SIZES.MEDIUM;
  const alignment = clockConfig?.alignment || CLOCK_ALIGNMENT.CENTER;
  const color = getRGBFromColorObject(clockConfig?.color || WHITE);

  const [date, setDate] = useState(new Date());

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  const formatHours = hours => {
    if (hours > 12 && timeFormat === TIME_FORMATS.STANDARD) {
      return hours - 12;
    }
    return hours;
  };

  const formatTimeSlot = time => {
    if (time < 10) {
      return `0${time}`;
    } else {
      return time;
    }
  };

  const styleObject = {
    color
  };
  return (
    <div
      className={classNames(
        "digital-face",
        clockSize.toLowerCase(),
        alignment.toLowerCase(),
        "header-layout-font"
      )}
    >
      <div style={styleObject}>
        {formatHours(date.getHours() === 0 ? 12 : date.getHours())}
      </div>
      <div style={styleObject}>:</div>
      <div style={styleObject}>{formatTimeSlot(date.getMinutes())}</div>
      {showSeconds ? (
        <Fragment>
          <div style={styleObject}>:</div>
          <div style={styleObject}>{formatTimeSlot(date.getSeconds())}</div>
        </Fragment>
      ) : null}
      {timeFormat === TIME_FORMATS.STANDARD ? (
        <Fragment>
          <div>&nbsp;</div>
          <div style={styleObject}>{date.getHours() > 12 ? "PM" : "AM"}</div>
        </Fragment>
      ) : null}
    </div>
  );
}
