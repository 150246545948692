import React from "react";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import IconText from "../../IconText/IconText";
import "./Feature.scss";

export default function Feature(props) {
  const isEnabledClassName =
    props.isBasicFeature || props.isPremiumList ? "enabled" : "disabled";

  return (
    <IconText
      isIconFirst={true}
      wrapperClassName={classNames(
        "feature",
        props.isBasicFeature ? "basic" : "premium"
      )}
    >
      <div>
        <FaCheck
          aria-label={`checkmark ${isEnabledClassName}`}
          className={isEnabledClassName}
        />
      </div>
      <div>
        <span className={isEnabledClassName}>{props.featureName}</span>
      </div>
    </IconText>
  );
}

Feature.defaultProps = {
  featureName: "",
  isBasicFeature: true,
  isPremiumList: false
};
