import React from "react";
import "./wallpaper.scss";
import background from "../../resources/background-photo.jpeg";
import { useSelector } from "react-redux";

export default function Wallpaper(props) {
  const { backgroundUrl } = useSelector(state => state.layouts);
  const { isLoggedIn } = useSelector(state => state.login);

  const style =
    backgroundUrl && isLoggedIn
      ? { backgroundImage: `url(${backgroundUrl})` }
      : { backgroundImage: `url(${background})` };

  return (
    <div className="background" style={style}>
      {props.children}
    </div>
  );
}
