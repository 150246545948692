import React, { Fragment } from "react";
import Feature from "./Feature";

export default function Features(props) {
  const basicFeatures = [
    "Change background image",
    "Tasks",
    "Bookmarks",
    "Google Search",
    "Welcome Message",
    "Clock",
    "Stock & Crypto Market Overviews",
    "Stock & Crypto Tickers"
  ];

  const premiumFeatures = [
    "Multiple Dashboards",
    "Customize Font",
    "Set Accent Color",
    "Change component styles",
    "Priority Support"
  ];

  return (
    <Fragment>
      {basicFeatures.map(feature => (
        <Feature
          isPremiumList={props.isPremiumList}
          isBasicFeature={true}
          featureName={feature}
          key={feature}
        />
      ))}
      {premiumFeatures.map(feature => (
        <Feature
          isPremiumList={props.isPremiumList}
          isBasicFeature={false}
          featureName={feature}
          key={feature}
        />
      ))}
    </Fragment>
  );
}

Features.defaultProps = {
  isPremiumList: false
};
