import React, { useState } from "react";
import IconText, { ICON_PLACEMENT } from "../IconText/IconText";
import { handleEnterKeypress } from "../../utils/keyEvents";
import {
  FaRegCheckCircle,
  FaRegCircle,
  FaArchive,
  FaReply
} from "react-icons/fa";
import "./Task.scss";
import AutoSizeTextArea from "../textArea/AutosizeTextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveTask,
  unarchiveTask,
  updateTaskCompletionStatus,
  updateTaskName
} from "../../actions/taskActions";
import classNames from "classnames";

export default function Task(props) {
  const dispatch = useDispatch();
  const { uid } = useSelector(state => state.userDetails);

  const [isCompletionIconHover, setIsCompletionIconHover] = useState(false);
  const [taskName, setTaskName] = useState(props.task.taskName);

  const onTaskNameChange = event => {
    setTaskName(event.target.value);
  };

  const onTaskNameBlur = event => {
    dispatch(updateTaskName(props.task.id, taskName));
  };

  const onKeyPress = event =>
    handleEnterKeypress(event, () =>
      dispatch(
        updateTaskCompletionStatus(props.task.id, !props.task.isCompleted)
      )
    );

  const onArchive = event => dispatch(archiveTask(props.task.id, uid));
  const onUnarchive = event => dispatch(unarchiveTask(props.task.id, uid));

  const TaskCompletionIcon =
    isCompletionIconHover || props.task.isCompleted
      ? FaRegCheckCircle
      : FaRegCircle;
  return (
    <IconText
      wrapperClassName="task-container"
      iconPlacement={ICON_PLACEMENT.AROUND}
    >
      {props.task.isArchived ? null : (
        <TaskCompletionIcon
          className="icon-wrapper"
          onClick={() =>
            dispatch(
              updateTaskCompletionStatus(props.task.id, !props.task.isCompleted)
            )
          }
          onFocus={() => setIsCompletionIconHover(true)}
          onBlur={() => setIsCompletionIconHover(false)}
          onMouseEnter={() => setIsCompletionIconHover(true)}
          onMouseLeave={() => setIsCompletionIconHover(false)}
          onKeyPress={onKeyPress}
          tabIndex={0}
          title={
            props.task.isCompleted ? "Reopen task" : "Click to complete task"
          }
        />
      )}
      <span className="task-name">
        <AutoSizeTextArea
          ariaLabel="task name"
          value={taskName}
          className={classNames("task-input", "body-layout-font")}
          onChange={onTaskNameChange}
          onBlur={onTaskNameBlur}
          additionalStyles={{
            textDecoration: props.task.isCompleted ? "line-through" : "none"
          }}
        />
      </span>
      {props.task.isArchived ? (
        <FaReply
          title="Unarchive task"
          className="icon-wrapper"
          onKeyPress={event => handleEnterKeypress(event, onUnarchive)}
          onClick={onUnarchive}
          tabIndex={0}
        />
      ) : (
        <FaArchive
          title="Archive task"
          className="icon-wrapper"
          onKeyPress={event => handleEnterKeypress(event, onArchive)}
          onClick={onArchive}
          tabIndex={0}
        />
      )}
    </IconText>
  );
}

Task.defaultProps = {
  task: {}
};
