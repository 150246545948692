import React from "react";
import H, { headerSizes } from "../elements/H";
import HorizontalSlider from "../slider/HorizontalSlider";
import {
  FaRegGrinBeam,
  FaRegGrinHearts,
  FaRegGrinStars,
  FaRegSmileWink,
  FaRegGrinSquint,
  FaRegSmile,
  FaRegSmileBeam,
  FaRegGrinWink,
  FaRegGrinAlt
} from "react-icons/fa";
import "./PremiumSlider.scss";

export default function PremiumSlider(props) {
  const faces = [
    <FaRegSmile />,
    <FaRegSmileWink />,
    <FaRegSmileBeam />,
    <FaRegGrinAlt />,
    <FaRegGrinWink />,
    <FaRegGrinBeam />,
    <FaRegGrinSquint />,
    <FaRegGrinStars />,
    <FaRegGrinHearts />
  ];

  const face = faces[props.premiumAmount - 1];
  return (
    <div>
      <H
        size={headerSizes.SMALL}
        textAlign="center"
        className="chosen-amount"
        usePermanentAccent={true}
      >
        ${props.premiumAmount}/month
      </H>
      <HorizontalSlider
        value={props.premiumAmount}
        onChange={props.setPremiumAmount}
        min={1}
        max={9}
        showMarks={true}
        renderThumb={(props, state) => {
          return (
            <div {...props} data-testid="slider-thumb">
              {face}
            </div>
          );
        }}
        thumbClassName="premium-thumb"
        className="premium-slider"
      />
    </div>
  );
}
