import React from "react";
import classNames from "classnames";
import "./FullWidthSection.scss";

export default function FullWidthSection(props) {
  return (
    <div
      className={classNames(
        "full-width-section",
        props.hasDarkBackground ? "dark-background" : null,
        props.hasLargePadding ? "large-padding" : null
      )}
      id={props.sectionId}
    >
      {props.children}
    </div>
  );
}

FullWidthSection.defaultProps = {
  hasDarkBackground: false,
  hasLargePadding: false,
  sectionId: null
};
