import React, { Fragment } from "react";
import "./IconText.scss";
import classNames from "classnames";

export const ICON_PLACEMENT = {
  FIRST: "FIRST",
  SECOND: "SECOND",
  AROUND: "AROUND"
};

export default function IconText(props) {
  return (
    <Fragment>
      <div
        className={classNames(
          "icon-text",
          props.wrapperClassName,
          `icon-${props.iconPlacement.toLowerCase()}`
        )}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    </Fragment>
  );
}

IconText.defaultProps = {
  onClick: () => {},
  iconPlacement: ICON_PLACEMENT.FIRST,
  wrapperClassName: ""
};
