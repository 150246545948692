import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { addRichText, saveWidget } from "../../actions/layoutActions";
import { hideModal } from "../../actions/modalActions";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";
import Button from "../button/button";
import "./RichText.scss";

export default function EditRichText({ widget_id }) {
  const dispatch = useDispatch();
  const richTexts = useSelector(state => state.layouts.richTexts);

  const richText = (richTexts
    ? richTexts.find(rt => rt.widget_id === widget_id)
    : null) ?? { delta: null };
  const [delta, setDelta] = React.useState(richText.delta);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" }
      ],
      ["link"],
      ["clean"]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  function handleChange(_content, _delta, _source, editor) {
    setDelta(editor.getContents());
  }

  const deltaRequired = useRequiredValidation(true, delta);

  const onSubmit = event => {
    event.preventDefault();
    deltaRequired.onInvalid(event);
    if (deltaRequired.isFieldFilled) {
      recordEvent(
        "Custom Text Config Saved",
        eventCategories.COMPONENT_CONFIGURATION,
        "Custom Text Config Saved"
      );
      dispatch(saveWidget(widget_id));
      dispatch(
        addRichText({
          widget_id: widget_id,
          delta
        })
      );
      dispatch(hideModal());
    }
  };

  return (
    <div className="rich-text-editor-container">
      <ReactQuill
        theme="snow"
        value={delta}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
      <Button
        type="submit"
        onClick={onSubmit}
        usePermanentAccent
        className="rich-text-button"
      >
        Save Custom Text
      </Button>
    </div>
  );
}
