import React from "react";
import FeedStory from "../feedStory/feedStory";
import { useRssFetch } from "../../custom-hooks/useRssFetch";
import LoadSpinner from "../loadSpinner/LoadSpinner";

const GuardianFeed = props => {
  const feed = useRssFetch(`https://www.theguardian.com/${props.urlStub}/rss`);

  if (!feed || !feed.items) {
    return <LoadSpinner />;
  }
  const stories = [];
  feed.items.forEach(function (entry) {
    const mediaContent = entry.media ? entry.media[1].$.url : null;
    const imgUrl = mediaContent || "";
    stories.push({
      name: entry.title,
      url: entry.link,
      img: imgUrl,
      author: entry.creator
    });
  });

  return (
    <div className="rssFeed">
      {stories.map((story, i) => {
        return <FeedStory key={i} story={story} shouldShowImage={i === 0} />;
      })}
    </div>
  );
};

export default React.memo(GuardianFeed);
