import React from "react";
import { useRefCallback } from "../../custom-hooks/useRefCallback";
import TextArea from "./TextArea";

export default function AutoSizeTextArea(props) {
  const autoSize = myRef => {
    myRef.current.style.height = "auto";
    myRef.current.style.height = myRef.current.scrollHeight + 4 + "px";
  };

  const delayedResize = myRef => {
    window.setTimeout(() => autoSize(myRef), 0);
  };

  const setTextAreaRef = useRefCallback(myRef => autoSize(myRef));

  return (
    <TextArea
      {...props}
      reference={setTextAreaRef.referenceFn}
      changeFn={() => autoSize(setTextAreaRef.reference)}
      delayedChangeFn={() => delayedResize(setTextAreaRef.reference)}
      rows={1}
      hasBottomPadding={false}
      additionalStyles={{ padding: "3px", ...props.additionalStyles }}
    />
  );
}

AutoSizeTextArea.defaultProps = {
  additionalStyles: {}
};
