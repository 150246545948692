import {
  REQUEST_STORE_USER,
  CLEAR_USER,
  SAVE_CUSTOMER_ID_SUCCESS,
  REQUEST_SAVE_CUSTOMER_ID,
  SAVE_CUSTOMER_ID_FAILURE,
  STORE_CHOSEN_PLAN,
  SAVE_SUBSCRIPTION_SUCCESS,
  REQUEST_SAVE_SUBSCRIPTION,
  SAVE_SUBSCRIPTION_FAILURE,
  REQUEST_GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  SAVE_CANCELLATION_SUCCESS,
  REQUEST_SAVE_CANCELLATION,
  SAVE_CANCELLATION_FAILURE
} from "../actions/types";
import { BASIC } from "../utils/stripeUtils";

export const initialUserState = {
  uid: "",
  email: "",
  displayName: "",
  photoUrl: "",
  customerId: "",
  planType: BASIC,
  subscriptionId: ""
};

/*
 * Responds to user actions and updates the appropriate state
 */
export function handleUserDetailsActions(state, action) {
  if (typeof state === "undefined") {
    return initialUserState;
  }
  switch (action.type) {
    case CLEAR_USER:
      return Object.assign({}, state, initialUserState);
    case REQUEST_STORE_USER:
      return Object.assign({}, state, {
        uid: action.uid,
        email: action.email,
        displayName: action.displayName,
        photoUrl: action.photoURL
      });
    case SAVE_CUSTOMER_ID_SUCCESS: {
      return Object.assign({}, state, { customerId: action.customerId });
    }
    case STORE_CHOSEN_PLAN: {
      return Object.assign({}, state, { planType: action.planType });
    }
    case SAVE_SUBSCRIPTION_SUCCESS: {
      return Object.assign({}, state, {
        subscriptionId: action.subscriptionId,
        planType: action.planType
      });
    }
    case GET_USER_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        subscriptionId: action.subscriptionId,
        planType: action.planType,
        customerId: action.customerId
      });
    }
    case SAVE_CANCELLATION_SUCCESS: {
      return Object.assign({}, state, {
        subscriptionId: null,
        planType: BASIC
      });
    }
    case REQUEST_SAVE_CANCELLATION:
    case SAVE_CANCELLATION_FAILURE:
    case GET_USER_DETAILS_FAILURE:
    case REQUEST_GET_USER_DETAILS:
    case REQUEST_SAVE_SUBSCRIPTION:
    case SAVE_SUBSCRIPTION_FAILURE:
    case REQUEST_SAVE_CUSTOMER_ID:
    case SAVE_CUSTOMER_ID_FAILURE:
    default:
      return state;
  }
}
