import { connect } from "react-redux";
import Modal from "./Modal";
import { hideModal } from "../../actions/modalActions";
import { cancelWidgetEdit } from "../../actions/layoutActions";
import {
  recordEvent,
  eventCategories
} from "../../utils/googleAnalyticsEvents";

const mapStateToProps = state => {
  const { isModalVisible, modalType } = state.modal;
  const { editWidgetIdentifiers, widgetList } = state.layouts;
  return {
    isModalVisible,
    modalType,
    editWidgetIdentifiers,
    widgetList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      recordEvent(
        "Modal Closed",
        eventCategories.GENERAL_APP_ACTION,
        "Modal Closed"
      );
      dispatch(hideModal());
      dispatch(cancelWidgetEdit());
    }
  };
};

const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default ModalContainer;
