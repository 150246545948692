import { IS_MODAL_VISIBLE } from "./types";

export function showModal(type, subType) {
  return {
    type: IS_MODAL_VISIBLE,
    isModalVisible: true,
    modalType: type,
    subType
  };
}

export function hideModal() {
  return {
    type: IS_MODAL_VISIBLE,
    isModalVisible: false
  };
}
