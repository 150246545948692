import React from "react";
import "./banner.scss";
import classNames from "classnames";
import { bannerSentiment } from "./bannerUtils";
import { FaTimes } from "react-icons/fa";

export default function Banner(props) {
  return props.isBannerVisible ? (
    <div className={classNames("banner", props.sentiment)}>
      {props.message}
      {props.shouldShowBannerClose ? (
        <FaTimes onClick={props.onBannerClose} className="banner-close" />
      ) : null}
    </div>
  ) : null;
}

Banner.defaultProps = {
  message: "",
  sentiment: bannerSentiment.NEUTRAL,
  isBannerVisible: true,
  shouldShowBannerClose: false,
  onBannerClose: () => {}
};
