import {
  REQUEST_STORE_USER,
  CLEAR_USER,
  REQUEST_SAVE_CUSTOMER_ID,
  SAVE_CUSTOMER_ID_SUCCESS,
  SAVE_CUSTOMER_ID_FAILURE,
  STORE_CHOSEN_PLAN,
  REQUEST_SAVE_FIRST_LOGIN,
  SAVE_FIRST_LOGIN_SUCCESS,
  SAVE_FIRST_LOGIN_FAILURE,
  REQUEST_SAVE_SUBSCRIPTION,
  SAVE_SUBSCRIPTION_SUCCESS,
  SAVE_SUBSCRIPTION_FAILURE,
  REQUEST_GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  REQUEST_SAVE_CANCELLATION,
  SAVE_CANCELLATION_SUCCESS,
  SAVE_CANCELLATION_FAILURE
} from "./types";
import {
  saveCustomerId,
  saveUserPlan,
  saveUserFirstLogin,
  saveSubscriptionId,
  getUser
} from "../db/UserFacade";
import { writeError } from "../db/ErrorFacade";
import history from "../utils/history";
import { BASIC, PREMIUM } from "../utils/stripeUtils";
import { saveEverything } from "./globalActions";

/**
 * retrieves info about a user from the database and then stores it locally
 * @param {*} uid the unique identifier of the current logged in user
 */
export function getUserDetails(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_GET_USER_DETAILS });

    getUser(uid)
      .then(result => {
        dispatch({ type: GET_USER_DETAILS_SUCCESS, ...result.data() });
      })
      .catch(err => {
        dispatch({ type: GET_USER_DETAILS_FAILURE });
        writeError({
          uid,
          error: "Getting the user's details failed",
          cause: err.toString()
        });
      });
  };
}

/**
 * stores information about a user locally
 * @param {uid, displayName, email, photoURL}
 */
export function storeUserDetails({ uid, displayName, email, photoURL }) {
  return dispatch => {
    dispatch({
      type: REQUEST_STORE_USER,
      uid,
      displayName,
      email,
      photoURL
    });
  };
}

/**
 * the first log in time is used to determine if we should run first login processing
 * @param {*} uid the unique identifier of the current logged in user
 */
export function storeUserFirstLoginTime(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_SAVE_FIRST_LOGIN });
    saveUserFirstLogin(uid)
      .then(results => {
        dispatch({ type: SAVE_FIRST_LOGIN_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: SAVE_FIRST_LOGIN_FAILURE });
        writeError({
          uid,
          error: "Saving user first login failed",
          cause: err.toString()
        });
      });
  };
}

/*
 * After a user signs up for the first time, their email is registered with stripe
 * This action stores the customer Id that is returned onto their user object.
 */
export function storeUserCustomerId({ uid, customerId }) {
  return dispatch => {
    dispatch({ type: REQUEST_SAVE_CUSTOMER_ID });
    saveCustomerId({ uid, customerId })
      .then(() => {
        dispatch({ type: SAVE_CUSTOMER_ID_SUCCESS, customerId });
      })
      .catch(err => {
        dispatch({ type: SAVE_CUSTOMER_ID_FAILURE });
        writeError({
          uid,
          error: "Writing the customer ID to the user collection failed",
          cause: err
        });
      });
  };
}

/**
 * Useful when a user logs out of the app
 */
export function clearUser() {
  return dispatch => {
    dispatch({ type: CLEAR_USER });
  };
}

/**
 * when a user first signs up, they are automatically granted the BASIC plan
 * @param {*} uid the uid of the current logged in user
 * @param {*} redirectToHome whether we should redirect the user to /app after the results are received
 */
export function storeBasicPlanSelection(uid, redirectToHome) {
  return dispatch => {
    dispatch({ type: STORE_CHOSEN_PLAN, planType: BASIC });
    saveUserPlan({ uid, planType: BASIC })
      .then(results => {
        if (redirectToHome) {
          history.push("/app");
        }
      })
      .catch(err => {
        writeError({
          uid,
          error: "Could not store user plan selection",
          cause: err.toString()
        });
      });
  };
}

/**
 * called after an integration with stripe to get a subscription. This function stores the subscription ID and
 * the plan type locally & in the DB
 * @param {*} uid the uid of the current logged in user
 * @param {*} subscriptionId the ID of the subscription that was created in stripe for the user
 */
export function storeSubscription(uid, subscriptionId) {
  return async dispatch => {
    dispatch({ type: REQUEST_SAVE_SUBSCRIPTION });
    try {
      await saveSubscriptionId({ uid, subscriptionId, planType: PREMIUM });
      dispatch({
        type: SAVE_SUBSCRIPTION_SUCCESS,
        subscriptionId,
        planType: PREMIUM
      });
      history.push("/app");
    } catch (error) {
      dispatch({ type: SAVE_SUBSCRIPTION_FAILURE });
      writeError({
        uid,
        error: "Could not store subscription ID",
        cause: error.toString()
      });
    }
  };
}

export function cancelUserSubscription(uid) {
  return async dispatch => {
    dispatch({ type: REQUEST_SAVE_CANCELLATION });

    try {
      await saveSubscriptionId({ uid, subscriptionId: null, planType: BASIC });
      dispatch({ type: SAVE_CANCELLATION_SUCCESS });
      dispatch(saveEverything());
    } catch (error) {
      dispatch({ type: SAVE_CANCELLATION_FAILURE });
      writeError({
        uid,
        error: "Attempted to save user cancellation in DB",
        cause: error.toString()
      });
    }
  };
}
