import React, { useRef, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../input/input";
import Button from "../button/button";
import { useFormInput } from "../../custom-hooks/useFormInput";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import { saveWidget, addIconBookmark } from "../../actions/layoutActions";
import { hideModal } from "../../actions/modalActions";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";
import IconPicker from "../iconPicker/IconPicker";
import InputLabel from "../input/InputLabel";

export default function EditIconBookmark(props) {
  const dispatch = useDispatch();
  const iconBookmarks = useSelector(state => state.layouts.iconBookmarks);

  const bookmark = iconBookmarks
    ? iconBookmarks.find(bookmark => bookmark.widget_id === props.widget_id)
    : null;
  const bookmarkUrl = useFormInput(bookmark?.url);
  const [bookmarkIcon, setBookmarkIcon] = useState(bookmark?.icon || "FaBeer");
  const bookmarkUrlRequired = useRequiredValidation(true, bookmarkUrl.value);
  const urlRef = useRef();

  useLayoutEffect(() => {
    urlRef.current.focus();
  }, [urlRef]);

  const onSubmit = event => {
    event.preventDefault();
    bookmarkUrlRequired.onInvalid(event);
    if (bookmarkUrlRequired.isFieldFilled) {
      recordEvent(
        "Bookmark Icon Config Saved",
        eventCategories.COMPONENT_CONFIGURATION,
        "Bookmark Icon Config Saved"
      );
      dispatch(saveWidget(props.widget_id));
      dispatch(
        addIconBookmark({
          widget_id: props.widget_id,
          url: bookmarkUrl.value,
          icon: bookmarkIcon
        })
      );
      dispatch(hideModal());
    }
  };

  return (
    <div>
      <Input
        label="URL"
        placeholder="URL"
        id="url"
        type="text"
        name="url"
        {...bookmarkUrl}
        required={true}
        ariaLabel="URL"
        validations={bookmarkUrlRequired.validations}
        reference={urlRef}
        usePermanentAccent
      />
      <InputLabel label="Icon" />
      <IconPicker chosenIcon={bookmarkIcon} setChosenIcon={setBookmarkIcon} />
      <Button type="submit" onClick={onSubmit} usePermanentAccent>
        Save Icon Bookmark
      </Button>
    </div>
  );
}
