import React from "react";
import FeedStory from "../feedStory/feedStory";
import { useRssFetch } from "../../custom-hooks/useRssFetch";
import LoadSpinner from "../loadSpinner/LoadSpinner";

const EspnFeed = props => {
  const feed = useRssFetch(
    `http://www.espn.com/espn/rss/${
      props.urlStub ? props.urlStub.concat("/") : ""
    }news`
  );

  if (!feed || !feed.items) {
    return <LoadSpinner />;
  }
  const stories = [];
  feed.items.forEach(function (entry) {
    stories.push({
      name: entry.title,
      url: entry.link,
      img: entry.image
    });
  });

  return (
    <div className="rssFeed">
      {stories.map((story, i) => {
        return <FeedStory key={i} story={story} shouldShowImage={i === 0} />;
      })}
    </div>
  );
};

export default React.memo(EspnFeed);
