import { connect } from "react-redux";
import MainContent from "./MainContent";
import { storeLayoutLocally } from "../../actions/layoutActions";

const mapStateToProps = state => {
  const {
    id,
    widgetList,
    layout,
    numberOfColumns,
    isLayoutLocked
  } = state.layouts;
  const { isLoggedIn } = state.login;
  const { uid } = state.userDetails;

  return {
    id,
    uid,
    widgetList,
    layout,
    numberOfColumns,
    isLoggedIn,
    isLayoutLocked
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLayoutChange: ({ widgetList, layout }) => {
      dispatch(storeLayoutLocally({ widgetList, layout }));
    }
  };
};

const MainContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContent);

export default MainContentContainer;
