import React from "react";
import Logo from "../logo/Logo";
import "./topbar.scss";
import classNames from "classnames";
import { useMobile } from "../../custom-hooks/useMobile";

export default function LogoAndSiteName() {
  const { isMobile } = useMobile({});

  return (
    <div
      className={classNames(
        "site-name-container",
        isMobile ? "mobile-view" : ""
      )}
    >
      <Logo className="non-mobile-view" />
      <a href="/" alt="Navigate to the homepage">
        <h1 className="site-name">Dashboi</h1>
      </a>
    </div>
  );
}
