import React from "react";
import InputLabel from "../input/InputLabel";
import "./RadioButton.scss";
import classnames from "classnames";

export const RADIO_BUTTON_ORIENTATIONS = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal"
};

export default function RadioButton(props) {
  return (
    <div className="radio-button-input">
      {props.label ? (
        <InputLabel
          label={props.label}
          helpText={props.helpText}
          helpTextPlacement={props.helpTextPlacement}
          isRequired={props.required}
        />
      ) : null}
      <div className={classnames("radio-button-choices", props.orientation)}>
        {props.choices.map((choice, index) => {
          return (
            <div key={index} className="radio-button">
              <input
                type="radio"
                id={choice.toLowerCase()}
                value={choice}
                name={props.name}
                checked={props.value === choice}
                onChange={() => props.onSelection(choice)}
                autoFocus={props.autoFocusFirstInput && index === 0}
                ref={
                  props.references.length > index
                    ? props.references[index]
                    : null
                }
              />
              <label htmlFor={choice.toLowerCase()}>{choice}</label>
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

RadioButton.defaultProps = {
  label: null,
  choices: [],
  name: null,
  onSelection: () => {},
  value: null,
  orientation: RADIO_BUTTON_ORIENTATIONS.VERTICAL,
  references: [],
  autoFocusFirstInput: false
};
