import React, { useState } from "react";
import H, { headerSizes } from "../elements/H";
import "./Premium.scss";
import Button, { buttonStyles } from "../button/button";
import ClickableText from "../clickableText/ClickableText";
import history from "../../utils/history";
import PaymentFormContainer from "../paymentForm/PaymentFormContainer";
import PremiumSlider from "../paymentForm/PremiumSlider";

export default function Premium(props) {
  const [premiumAmount, setPremiumAmount] = useState(5);
  const [step, setStep] = useState(0);

  const sliderComponent = (
    <PremiumSlider
      premiumAmount={premiumAmount}
      setPremiumAmount={setPremiumAmount}
    />
  );
  if (step === 0) {
    // choose amount
    return (
      <div className="premium-container">
        <div>
          <H size={headerSizes.MEDIUM} textAlign="center">
            Go Premium!
          </H>
          <p>
            Dashboi will always be free to use, but going premium gets you some
            sweet extras and you get to feel good about helping a small business
            stay in business.
          </p>
          <p>
            We believe that, given the opportunity, people will pay what they
            can for the services they use. Most people choose $5/month.
          </p>
        </div>
        {sliderComponent}
        <div className="premium-button-container">
          <Button
            buttonStyle={buttonStyles.OPAQUE_BUTTON}
            onClick={() => setStep(1)}
            shouldHandleGlobalEnterKeypress={true}
          >
            Continue
          </Button>
          <ClickableText onClick={() => history.push("/app")}>
            No thanks
          </ClickableText>
        </div>
      </div>
    );
  }

  if (step === 1) {
    // Enter credit card
    return (
      <div className="premium-container">
        <div>
          <H size={headerSizes.MEDIUM} textAlign="center">
            Thank you!
          </H>
          <p>
            Your support means the world to us. We think you're going to love
            Dashboi and can't wait to see what cool stuff you make.
          </p>
        </div>
        {sliderComponent}
        <PaymentFormContainer
          premiumAmount={premiumAmount}
          setIsProcessing={props.setIsProcessing}
          renderButtons={confirmCallback => (
            <Button
              buttonStyle={buttonStyles.OPAQUE_BUTTON}
              onClick={confirmCallback}
              shouldHandleGlobalEnterKeypress={true}
            >
              Get Premium
            </Button>
          )}
        />
        <ClickableText onClick={() => history.push("/app")}>
          I changed my mind
        </ClickableText>
      </div>
    );
  }

  return null;
}
