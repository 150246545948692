import React from "react";
import Logo from "../logo/Logo";
import "./TitleAndLogo.scss";

export default function TitleAndLogo(props) {
  return (
    <div className="title-and-logo" data-testid="title-and-logo">
      {props.shouldShowLogo ? <Logo /> : null}
      <h1 className="modal-title">{props.title}</h1>
    </div>
  );
}

TitleAndLogo.defaultProps = {
  shouldShowLogo: true,
  title: ""
};
