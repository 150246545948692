import React from "react";
import Button, { buttonStyles } from "../button/button";
import { FaRegSave } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import "./SaveLayoutButton.scss";
import { BASIC } from "../../utils/stripeUtils";
import { showModal } from "../../actions/modalActions";
import { PREMIM_ONLY_SAVE } from "../modal/modalTypes";
import { useSaveWrapper } from "../../custom-hooks/useSaveWrapper";

export default function SaveLayoutButton(props) {
  const { planType } = useSelector(state => state.userDetails);
  const { isExperimentalMode } = useSelector(state => state.global);
  const dispatch = useDispatch();

  const { onSaveClick } = useSaveWrapper();

  const saveLayout = event => {
    event.preventDefault();
    if (planType === BASIC && isExperimentalMode) {
      dispatch(showModal(PREMIM_ONLY_SAVE));
    } else {
      onSaveClick(true, event);
    }
  };

  return (
    <Button
      onClick={saveLayout}
      buttonStyle={buttonStyles.OPAQUE_BUTTON}
      isInDarkBackground={true}
      className="save-button"
    >
      {props.shouldShowIcon ? (
        <FaRegSave className="icon-button" aria-label="Save Icon" />
      ) : (
        "Save Layout"
      )}
    </Button>
  );
}

SaveLayoutButton.defaultProps = {
  shouldShowIcon: false
};
