export const eventCategories = {
  GENERAL_APP_ACTION: "General App Action",
  AUTHENTICATION_ACTION: "Authentication Action",
  LAYOUT_CONFIGURATION: "Layout Configuration",
  COMPONENT_CONFIGURATION: "Component Configuration"
};

export function recordEvent(eventAction, eventCategory, eventLabel) {
  window.gtag("event", eventAction, {
    event_category: eventCategory,
    event_label: eventLabel,
    value: 1
  });
}
