import { db } from "../utils/firebase-config";

export function createStyles(styles) {
  return new Promise(function (resolve, reject) {
    db.collection("styles")
      .add(styles)
      .then(docRef => resolve(docRef))
      .catch(err => reject(err));
  });
}

export function updateStyles(id, styles) {
  return new Promise(function (resolve, reject) {
    db.collection("styles")
      .doc(id)
      .update(styles)
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function queryStylesByUid(uid) {
  return new Promise(function (resolve, reject) {
    db.collection("styles")
      .where("uid", "==", uid)
      .get()
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        reject(err);
      });
  });
}
