import React from "react";
import classNames from "classnames";
import "./Elements.scss";

export const headerSizes = {
  LARGE: "h1",
  MEDIUM: "h2",
  SMALL: "h3",
  EXTRA_SMALL: "h4"
};

export default function H(props) {
  const Element = props.size;

  return (
    <Element
      className={classNames(
        props.className,
        props.usePermanentAccent ? "permanent" : ""
      )}
      style={{ textAlign: props.textAlign }}
    >
      {props.children}
    </Element>
  );
}

H.defaultProps = {
  size: headerSizes.LARGE,
  className: "",
  textAlign: "left",
  usePermanentAccent: true
};
