import axios from "axios";

export function createCustomer(email) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/create-customer", {
        email
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
