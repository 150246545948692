import React from "react";
import LoadSpinner from "./LoadSpinner";
import "./LoadSpinner.scss";

export default function LoadingGlass({ style }) {
  return (
    <div className="loading-glass" style={style}>
      <LoadSpinner />
    </div>
  );
}

LoadingGlass.defaultProps = {
  style: {}
};
