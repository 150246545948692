import React, { useLayoutEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./PaymentForm.scss";
import classNames from "classnames";
import Validations from "../validations/Validations";
import { createSubscription } from "../../axios/CreateSubscriptionFacade";
import { useDispatch, useSelector } from "react-redux";
import { prices } from "../../utils/stripeUtils";
import Banner from "../banner/banner";
import { bannerSentiment } from "../banner/bannerUtils";
import {
  storeSubscription,
  storeUserCustomerId
} from "../../actions/userActions";

export default function PaymentForm({
  premiumAmount,
  setIsProcessing,
  renderButtons
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [hasFocus, setHasFocus] = useState(false);
  const [error, setError] = useState("");
  const [subscriptionCreationError, setSubscriptionCreationError] = useState(
    null
  );

  const { customerId, uid, email } = useSelector(state => state.userDetails);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      cardElement.on("focus", () => setHasFocus(true));
      cardElement.on("blur", () => setHasFocus(false));
      cardElement.on("keydown", () => setError(""));
    }
  }, [setHasFocus, elements]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    let errorOccurred;
    if (error) {
      setError(error.message);
    } else {
      const price_id = prices[premiumAmount - 1];
      try {
        const response = await createSubscription(
          customerId,
          email,
          price_id,
          paymentMethod.id
        );
        if (!customerId) {
          dispatch(
            storeUserCustomerId({ uid, customerId: response.data.customerId })
          );
        }
        dispatch(storeSubscription(uid, response.data.subscriptionId));
        errorOccurred = false;
      } catch (err) {
        setSubscriptionCreationError(
          "Error occurred during processing. Please contact the Dashboi team @ dashboi.app@gmail.com"
        );
        errorOccurred = true;
      }
    }
    setIsProcessing(false);
    return errorOccurred;
  };

  return (
    <div className="payment-form">
      {subscriptionCreationError && (
        <Banner
          sentiment={bannerSentiment.NEGATIVE}
          message={subscriptionCreationError}
        />
      )}
      <CardElement
        className={classNames("card-input", hasFocus ? "has-focus" : "")}
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "rgb(107, 36, 80)",
              "::placeholder": {
                color: "#575C5B"
              },
              fontFamily: '"Lato", sans-serif'
            },
            invalid: {
              color: "#D11D1A"
            }
          }
        }}
      />
      <Validations validations={[error]} />
      {renderButtons && renderButtons(handleSubmit)}
    </div>
  );
}
