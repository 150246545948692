import React from "react";
import ReactGridLayoutWrapper from "./ReactGridLayoutWrapper";
import "./MainContent.scss";
import GlobalBanner from "../banner/GlobalBanner";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

export default class MainContent extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: function () {},
    id: null,
    widgetList: [],
    layout: []
  };

  onLayoutChange = layout => {
    const { widgetList, isLoggedIn } = this.props;
    if (isLoggedIn) {
      recordEvent(
        "Layout Adjusted",
        eventCategories.LAYOUT_CONFIGURATION,
        "Layout Adjusted"
      );
      this.props.onLayoutChange({ widgetList, layout });
    }
  };

  render() {
    const { widgetList, layout, numberOfColumns, isLayoutLocked } = this.props;
    return (
      <div id="main-content">
        <GlobalBanner />
        <ReactGridLayoutWrapper
          widgetList={widgetList}
          isLayoutLocked={isLayoutLocked}
          layout={layout}
          numberOfColumns={numberOfColumns}
          onLayoutChange={this.onLayoutChange}
          onDragStart={() =>
            recordEvent(
              "Grid Item Moved",
              eventCategories.LAYOUT_CONFIGURATION,
              "Grid Item Moved"
            )
          }
          onResizeStart={() =>
            recordEvent(
              "Grid Item Resized",
              eventCategories.LAYOUT_CONFIGURATION,
              "Grid Item Resized"
            )
          }
        />
      </div>
    );
  }
}
