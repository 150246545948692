import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MultiSelectDropdown from "../../multiSelectDropdown/MultiSelectDropdown";
import Button from "../../button/button";
import stocks from "../../../utils/stocks.json";
import "./EditTickerBanner.scss";
import { saveWidget, addTickerBanner } from "../../../actions/layoutActions";
import { hideModal } from "../../../actions/modalActions";
import { generateSymbolsFromSelections } from "../FinanceUtils";
import { useRefCallback } from "../../../custom-hooks/useRefCallback";
import {
  eventCategories,
  recordEvent
} from "../../../utils/googleAnalyticsEvents";

/*
 * TODO: Pull data from server instead of doing it locally in browser (bad performance)
 */
export default function EditTickerBanner(props) {
  const tickerBanners = useSelector(state => state.layouts.tickerBanners);
  const dispatch = useDispatch();

  const tickerBanner = tickerBanners
    ? tickerBanners.find(banner => banner.widget_id === props.widget_id)
    : null;

  // We are saving `symbol - company name` in tickerBanner.symbols
  const symbols = [];
  if (tickerBanner?.symbols) {
    tickerBanner.symbols.forEach(symbol => {
      const keys = symbol.split(":");
      symbols.push(keys[1]);
    });
  }
  const [selections, setSelections] = useState(tickerBanner ? symbols : []);

  const choices = [];
  Object.keys(stocks).forEach(key => {
    const keys = key.split(":");
    choices.push(keys[1].concat(" - ").concat(stocks[key]));
  });

  const onSubmit = () => {
    const symbols = generateSymbolsFromSelections(selections);

    recordEvent(
      "Ticket Banner Config Saved",
      eventCategories.COMPONENT_CONFIGURATION,
      "Ticket Banner Config Saved"
    );
    dispatch(saveWidget(props.widget_id));
    dispatch(addTickerBanner({ widget_id: props.widget_id, symbols }));
    dispatch(hideModal());
  };

  const { referenceFn } = useRefCallback(ref => ref.current.focus());

  return (
    <div className="edit-ticker-wrapper">
      <MultiSelectDropdown
        label="Stock and Crypto symbols (max 7)"
        helpText="Select the set of stock & crypto symbols you want to appear in the banner"
        choices={choices}
        maxSelections={7}
        placeholder="Search symbols..."
        selections={selections}
        setSelections={setSelections}
        reference={referenceFn}
      />
      <Button
        onClick={onSubmit}
        className="edit-ticker-button"
        usePermanentAccent
      >
        Save Ticker Banner
      </Button>
    </div>
  );
}

EditTickerBanner.defaultProps = {
  widget_id: ""
};
