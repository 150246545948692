import {
  REQUEST_LOGIN_EMAIL_PASSWORD,
  REQUEST_LOGIN_GOOGLE,
  REQUEST_REGISTER_EMAIL_PASSWORD,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  USER_AUTHENTICATED,
  USER_UNAUTHENTICATED,
  REQUEST_PASSWORD_RESET,
  PASSWORD_RESET_EMAIL_SENT,
  PASSWORD_RESET_FAILURE,
  REQUEST_DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE
} from "./types";
import { getTasks, clearTasks } from "./taskActions";
import {
  getLayoutConfigurations,
  clearLayout,
  unlockLayout
} from "./layoutActions";
import { hideModal } from "./modalActions";
import {
  storeUserDetails,
  clearUser,
  storeUserCustomerId,
  storeBasicPlanSelection,
  storeUserFirstLoginTime,
  getUserDetails
} from "./userActions";
import {
  loginWithEmailAndPassword,
  signOutOfApp,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  sendPasswordResetEmail,
  deleteAccount,
  updateUserDisplayName
} from "../auth/AuthenticationFacade";
import history from "../utils/history";
import { signUpForMailingList } from "../axios/MailchimpSignupFacade";
import { createCustomer } from "../axios/CreateCustomerFacade";
import { clearStyles, getStyles } from "./StyleActions";
import { writeError } from "../db/ErrorFacade";
import { eventCategories, recordEvent } from "../utils/googleAnalyticsEvents";

/*
 * Users can sign up with an email and password. This action responds to the user clicking
 * the "LOGIN" button in the login modal.
 */
export function requestLoginWithEmailAndPassword(email, password) {
  return dispatch => {
    dispatch({ type: REQUEST_LOGIN_EMAIL_PASSWORD, email });

    loginWithEmailAndPassword(email, password)
      .then(result => {
        recordEvent(
          "Login With Email",
          eventCategories.AUTHENTICATION_ACTION,
          "Login With Email"
        );
        const { user } = result;
        const { uid, email, displayName, photoURL } = user;
        dispatch(storeUserDetails({ uid, email, displayName, photoURL }));
        signUpForMailingList({
          email,
          firstName: displayName
        });
        dispatch({ type: LOGIN_SUCCESS });
        processUser(dispatch, result);
      })
      .catch(err => {
        dispatch({ type: LOGIN_FAILURE });
      });
  };
}

/*
 * Users can sign in or sign up with google, and both actions use the same method.
 * This action responds to the user clicking the "LOGIN WITH GOOGLE" button in the login modal.
 */
export function requestLoginWithGoogle() {
  return dispatch => {
    dispatch({ type: REQUEST_LOGIN_GOOGLE });

    signInWithPopup()
      .then(result => {
        recordEvent(
          "Login With Google",
          eventCategories.AUTHENTICATION_ACTION,
          "Login With Google"
        );
        processUser(dispatch, result);

        const { user } = result;
        const { uid, email, displayName, photoURL } = user;
        dispatch(storeUserDetails({ uid, email, displayName, photoURL }));
        dispatch({ type: LOGIN_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: LOGIN_FAILURE });
      });
  };
}

/*
 * verifyAuthentication is registered with the global store on app load
 * onAuthStateChanged is called anytime the user authentication changes with firebase.
 */
export function verifyAuthentication() {
  return dispatch => {
    onAuthStateChanged(user => {
      if (user) {
        const uid = user.uid;
        dispatch({ type: USER_AUTHENTICATED, uid });
        dispatch(getUserDetails(uid));
        dispatch(getTasks(uid));
        dispatch(getStyles(uid));
        dispatch(getLayoutConfigurations(uid));
        dispatch(hideModal());
      } else {
        dispatch({ type: USER_UNAUTHENTICATED });
        dispatch(clearTasks());
        dispatch(clearUser());
        dispatch(clearLayout());
        dispatch(clearStyles());
      }
    });
  };
}

/*
 * Sends a request to firebase service to log the user out of the application
 */
export function requestLogout() {
  return dispatch => {
    dispatch({ type: LOGOUT });

    signOutOfApp()
      .then(() => {
        recordEvent("Logout", eventCategories.AUTHENTICATION_ACTION, "Logout");
        dispatch({ type: LOGOUT_SUCCESS });
      })
      .catch(err => {});
  };
}

/*
 * Registers a new user in firebase based on the email and password
 * This action is responds to the user clicking the "SIGN UP" button in the modal
 */
export function requestRegistrationWithEmailAndPassword(
  email,
  displayName,
  password
) {
  return dispatch => {
    dispatch({ type: REQUEST_REGISTER_EMAIL_PASSWORD });

    createUserWithEmailAndPassword(email, password)
      .then(result => {
        recordEvent(
          "Register with Email Complete",
          eventCategories.AUTHENTICATION_ACTION,
          "Register with Email Complete"
        );
        const user = result.user;
        dispatch({ type: REGISTER_SUCCESS, uid: user.uid });
        updateUserDisplayName(user, displayName);

        processUser(dispatch, result, displayName);
        dispatch(
          storeUserDetails({
            uid: user.uid,
            email: user.email,
            displayName,
            photoUrl: user.photoURL
          })
        );
      })
      .catch(err => {
        recordEvent(
          "Register with Email Failed",
          eventCategories.AUTHENTICATION_ACTION,
          "Register with Email Failed"
        );
        dispatch({ type: REGISTER_FAILURE });
        writeError({
          uid: "",
          error: "Sign up with email and password failed",
          cause: err
        });
      });
  };
}

/*
 * Sends a password reset email to the email requested, if a user exists with that email.
 */
export function requestPasswordReset(email) {
  return dispatch => {
    dispatch({ type: REQUEST_PASSWORD_RESET });

    sendPasswordResetEmail(email)
      .then(() => {
        recordEvent(
          "Forgot Password",
          eventCategories.AUTHENTICATION_ACTION,
          "Forgot Password"
        );
        dispatch({ type: PASSWORD_RESET_EMAIL_SENT });
        history.push("/login");
      })
      .catch(err => {
        dispatch({ type: PASSWORD_RESET_FAILURE });
      });
  };
}

/*
 * Deletes a user from firebase completely. Does not delete any of their data.
 * This action is permanent.
 */
export function requestAccountDeletion(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_DELETE_ACCOUNT });

    deleteAccount(uid)
      .then(() => {
        recordEvent(
          "Account Delete Complete",
          eventCategories.AUTHENTICATION_ACTION,
          "Account Delete Complete"
        );
        dispatch({ type: DELETE_ACCOUNT_SUCCESS });
        dispatch(hideModal());
      })
      .catch(err => {
        dispatch({ type: DELETE_ACCOUNT_FAILURE });
      });
  };
}

/*
 * When a user creates an account for the first time, there are several side effects
 * that must occur, such as adding them to the mailing list and creating a customer
 * in Stripe associate to their email.
 */
function processUser(dispatch, result, displayName) {
  const { user, additionalUserInfo } = result;
  if (additionalUserInfo.isNewUser) {
    dispatch(storeUserFirstLoginTime(user.uid));
    dispatch(unlockLayout());
    dispatch(storeBasicPlanSelection(user.uid, false));
    if (displayName || user.displayName) {
      signUpForMailingList({
        email: user.email,
        firstName: displayName || user.displayName
      });
    }
    createCustomer(user.email)
      .then(res => {
        dispatch(
          storeUserCustomerId({
            uid: user.uid,
            customerId: res.data.customerId
          })
        );
      })
      .catch(err => {
        writeError({
          uid: user.uid,
          error: "Could not create customer object for user",
          cause: err.toString()
        });
      });
    history.push("/premium");
  } else {
    history.push("/app");
  }
}
