import React from "react";
import logo from "../../resources/logo.png";
import "./Logo.scss";
import classNames from "classnames";

export default function Logo(props) {
  return (
    <a href="/" className={classNames("app-logo-link", props.className)}>
      <img src={logo} alt="Dashboi Logo" className="app-logo" />
    </a>
  );
}

Logo.defaultProps = {
  className: ""
};
