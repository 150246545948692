import React from "react";
import FeedStory from "../feedStory/feedStory";
import { useRssFetch } from "../../custom-hooks/useRssFetch";
import LoadSpinner from "../loadSpinner/LoadSpinner";

const FoxNewsFeed = props => {
  const feed = useRssFetch(`http://feeds.foxnews.com/foxnews/${props.urlStub}`);

  if (!feed || !feed.items) {
    return <LoadSpinner />;
  }
  const stories = [];
  feed.items.forEach(function (entry) {
    const mediaContent = entry.media ? entry.media[0]["media:content"] : null;
    stories.push({
      name: entry.title,
      url: entry.link,
      author: entry.creator,
      img: mediaContent ? mediaContent[0].$.url : ""
    });
  });

  return (
    <div className="rssFeed">
      {stories.map((story, i) => {
        return <FeedStory key={i} story={story} shouldShowImage={i === 0} />;
      })}
    </div>
  );
};

export default React.memo(FoxNewsFeed);
