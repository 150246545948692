import React, { useState, useRef } from "react";
import PopperWrapper from "../popperWrapper/popperWrapper";
import "./ColorPicker.scss";
import { handleEnterKeypress } from "../../utils/keyEvents";
import CustomSwatchPicker from "./CustomSwatchPicker";

export default function ColorPicker(props) {
  const [isVisible, setIsVisible] = useState(null);

  const onClick = () => setIsVisible(!isVisible);

  const onKeyPress = event => {
    handleEnterKeypress(event, onClick);
  };

  const fieldRef = useRef(null);
  const field = (
    <div
      className="swatch"
      style={{
        background: `rgb(${props.color.r}, ${props.color.g}, ${props.color.b})`
      }}
      onClick={onClick}
      onKeyPress={onKeyPress}
      aria-label="Select Color"
      tabIndex={0}
      ref={fieldRef}
    />
  );

  const handleColorChange = chosenColor => {
    props.onColorChange(chosenColor);
    onLastSwatchTabPress();
  };

  const onLastSwatchTabPress = () => {
    props.onLastSwatchTabPress();
    setIsVisible(!isVisible);
  };

  const onFirstSwatchShiftTabPress = () => {
    setIsVisible(!isVisible);
    fieldRef.current.focus();
  };

  return isVisible ? (
    <PopperWrapper
      reference={field}
      poppedComponent={
        <CustomSwatchPicker
          onChangeComplete={handleColorChange}
          color={props.color}
          onLastSwatchTabPress={onLastSwatchTabPress}
          onFirstSwatchShiftTabPress={onFirstSwatchShiftTabPress}
        />
      }
      placement="bottom-start"
      referenceStyle={{ width: "100%" }}
    />
  ) : (
    field
  );
}

ColorPicker.defaultProps = {
  color: { r: 0, g: 0, b: 0, a: 1 },
  onColorChange: () => {},
  onLastSwatchTabPress: () => {},
  onFirstSwatchShiftTabPress: () => {}
};
