import { PREMIUM } from "../utils/stripeUtils";
import { saveLayoutConfiguration } from "./layoutActions";
import { saveStyles, saveTemplatedStyles } from "./StyleActions";
import {
  TOGGLE_DIRTY_FORM_ON,
  TOGGLE_DIRTY_FORM_OFF,
  SHOW_BANNER,
  HIDE_BANNER,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU,
  SET_LAST_EDIT_TIME,
  SHOW_SAVE_ALERT,
  HIDE_SAVE_ALERT,
  SET_ERROR,
  CLEAR_ERROR,
  SET_EXPERIMENTAL_MODE
} from "./types";

/*
 * A dirty form occurs whenever a user makes a material edit to their layout, including (but not limited to):
 * moving a component, adding a component, or deleting a component.
 */
export function toggleDirtyForm(isFormDirty) {
  return (dispatch, getState) => {
    const { isLayoutLocked } = getState().layouts;
    if (isFormDirty && !isLayoutLocked) {
      dispatch({ type: TOGGLE_DIRTY_FORM_ON });
      dispatch(setLastEditTime(new Date()));
    } else {
      dispatch({ type: TOGGLE_DIRTY_FORM_OFF });
      dispatch(setLastEditTime(null));
    }
  };
}

export function showGlobalBanner(bannerType) {
  return dispatch => {
    dispatch({ type: SHOW_BANNER, bannerType });
  };
}

export function hideGlobalBanner() {
  return dispatch => {
    dispatch({ type: HIDE_BANNER });
  };
}

/*
 * On mobile, the menu is not in the topbar. Instead, it pops out from a hamburger menu.
 * This action shows the mobile menu when the user clicks the hamburger.
 */
export function showMobileMenu() {
  return dispatch => {
    dispatch({ type: SHOW_MOBILE_MENU });
  };
}

/*
 * Hides the mobile menu when the user clicks the close button.
 */
export function hideMobileMenu() {
  return dispatch => {
    dispatch({ type: HIDE_MOBILE_MENU });
  };
}

/*
 * The last edit time is used to determine if we should show the user an alert when they have
 * not saved their layout. The last edit time is updated any time the user makes a
 * material change to their layout. It is also set to null when they save their layout.
 */
export function setLastEditTime(time) {
  return dispatch => {
    dispatch({ type: SET_LAST_EDIT_TIME, time });
  };
}

/*
 * The save alert is used to remind the user that they need to save the layout or their work will be lost.
 */
export function showSaveAlert() {
  return dispatch => {
    dispatch({ type: SHOW_SAVE_ALERT });
  };
}

export function hideSaveAlert() {
  return dispatch => {
    dispatch({ type: HIDE_SAVE_ALERT });
  };
}

/**
 * Useful to set an error that needs to exist globally. Current use case is in a modal.
 * @param {*} error the error message to be displayed
 */
export function setError(error) {
  return dispatch => {
    dispatch({ type: SET_ERROR, error });
  };
}

/**
 * Clears a global error
 */
export function clearError() {
  return dispatch => {
    dispatch({ type: CLEAR_ERROR });
  };
}

/**
 * ExperimentalMode is when the user has not purchased premium, but has specifically chosen to design their layout
 * using premium features. This mode is meant to entice users to go premium by showing them all it can be.
 * @param {*} isExperimental whether or not the user is in experimental mode
 */
export function setExperimentalMode(isExperimental) {
  return dispatch => {
    dispatch({ type: SET_EXPERIMENTAL_MODE, isExperimental });
  };
}

/**
 * Encapsulates all logic necessary to reset a user's layout to non-premium features before saving.
 */
export function resetAllPremiumFeatures() {
  return dispatch => {
    dispatch(saveTemplatedStyles());
  };
}

/**
 * Encapsulates all the calls needed to save a layout
 */
export function saveEverything() {
  return (dispatch, getState) => {
    const { planType, subscriptionId } = getState().userDetails;
    if (planType !== PREMIUM || !subscriptionId) {
      dispatch(resetAllPremiumFeatures());
    }
    dispatch(saveLayoutConfiguration());
    dispatch(saveStyles());
    dispatch(hideSaveAlert());
    dispatch(setExperimentalMode(false));
  };
}
