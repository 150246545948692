import { useState } from "react";
import { isEmailValid } from "../utils/validations";

/*
 * Validates that an email has the correct format. Used in forms that have an email input.
 */
export const useEmailValidator = email => {
  const [isValidEmail, setIsValidEmail] = useState(
    email ? isEmailValid(email) : true
  );
  const validateEmail = emailToCheck => {
    if (emailToCheck) {
      const validEmail = isEmailValid(emailToCheck);
      setIsValidEmail(validEmail);
    } else {
      setIsValidEmail(true);
    }
  };

  return {
    isValidEmail,
    validationMessage: isValidEmail ? [] : ["Please enter a valid email"],
    validateEmail
  };
};
