import React from "react";
import "./ClickableText.scss";
import classNames from "classnames";
import { handleEnterKeypress } from "../../utils/keyEvents";

export default function ClickableText(props) {
  const handleKeyPress = event => handleEnterKeypress(event, props.onClick);
  const getContextClassName = () =>
    props.isInDarkBackground ? "dark-background" : "light-background";

  return (
    <button
      className={classNames(
        "clickable-text",
        props.className,
        getContextClassName()
      )}
      onClick={props.onClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
      ref={props.buttonRef}
    >
      {props.children}
    </button>
  );
}

ClickableText.defaultProps = {
  onClick: () => {},
  className: "",
  isInDarkBackground: false,
  buttonRef: null
};
