import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  MARKET_OVERVIEW_FRIENDLY,
  TICKER_BANNER_FRIENDLY,
  CLOCK_FRIENDLY,
  TASK_LIST_FRIENDLY,
  BOOKMARK_LIST_FRIENDLY,
  BOOKMARK_FRIENDLY,
  WELCOME_MESSAGE_FRIENDLY,
  GOOGLE_SEARCH_FRIENDLY
} from "../../widget/widgetUtils";
import marketOverviewPng from "../../../resources/market_overview.png";
import tickerBannerPng from "../../../resources/ticker_banner.png";
import clockPng from "../../../resources/clock.png";
import newsPng from "../../../resources/news.png";
import tasksPng from "../../../resources/tasks.png";
import bookmarkListPng from "../../../resources/bookmark_list.png";
import bookmarkCardPng from "../../../resources/bookmark_card.png";
import googleSearchPng from "../../../resources/google_search.png";
import welcomePng from "../../../resources/welcome_message.png";
import bannerImage from "../../../resources/lego-banner.jpg";
import "./DocsPage.scss";
import FullWidthBannerImage from "../../fullWidgetBannerImage/FullWidthBannerImage";
import UnsplashCredit from "../../unsplashCredit/UnsplashCredit";
import FullWidthSection from "../FullWidthSection";

export default function DocsPage(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Components</title>
        <meta
          name="description"
          content="Learn about the various components that are available to add to Dashboard"
        />
        <meta
          name="og:title"
          property="og:title"
          content="Components that can be added to the dashboard"
        />
        <link rel="canonical" href="https://dashboi.com/components" />
      </Helmet>
      <FullWidthBannerImage
        backgroundImage={bannerImage}
        bannerText="Components"
      >
        <UnsplashCredit
          photographerName="Jan Huber"
          photographLink="https://unsplash.com/@jan_huber"
        />
      </FullWidthBannerImage>
      <FullWidthSection
        hasLargePadding={true}
        sectionId="market-overview-component"
      >
        <div className="component">
          <div className="side-by-side">
            <img
              src={marketOverviewPng}
              alt={`Screenshot of ${MARKET_OVERVIEW_FRIENDLY} component`}
            />
            <div>
              <h1>{MARKET_OVERVIEW_FRIENDLY}</h1>
              <p>
                The {MARKET_OVERVIEW_FRIENDLY} component can be configured to
                show multiple tabs of stock or crypto information. You can
                choose to show or hide preconfigured tabs for stock market
                indices and common crypto symbols. You can also create a custom
                tab with five stock and crypto symbols that you care about.
              </p>
              <p>
                Component courtesy of{" "}
                <a
                  href="https://tradingview.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TradingView
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection
        hasDarkBackground={true}
        hasLargePadding={true}
        sectionId="ticker-banner-component"
      >
        <div className="component">
          <div className="side-by-side">
            <div>
              <h1>{TICKER_BANNER_FRIENDLY}</h1>
              <p>
                The {TICKER_BANNER_FRIENDLY} component shows realtime stock and
                crypto symbol information. You can configure the component with
                up to seven unique stock or crypto symbols.
              </p>
              <p>
                Component courtesy of{" "}
                <a
                  href="https://tradingview.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TradingView
                </a>
                .
              </p>
            </div>
            <img
              src={tickerBannerPng}
              alt={`Screenshot of ${TICKER_BANNER_FRIENDLY} component`}
            />
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection hasLargePadding={true} sectionId="clock-component">
        <div className="component">
          <div className="side-by-side">
            <img
              src={clockPng}
              alt={`Screenshot of ${CLOCK_FRIENDLY} component`}
            />
            <div>
              <h1>{CLOCK_FRIENDLY}</h1>
              <p>
                The {CLOCK_FRIENDLY} component displays the current regular time
                (non-military).
              </p>
            </div>
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection
        hasDarkBackground={true}
        hasLargePadding={true}
        sectionId="news-component"
      >
        <div className="component">
          <div className="side-by-side">
            <div>
              <h1>News</h1>
              <p>
                The News component uses RSS to pull news from various sources.
                We support a large number of news sources, and the list is
                growing. Below are the lists that we currently support. If you
                don't see the news source you read, please{" "}
                <a href="/contact">tell us what we're missing</a>.
              </p>
              <ul>
                <li>CNN</li>
                <li>ESPN</li>
                <li>Fox</li>
                <li>Gamasutra</li>
                <li>The Guardian</li>
                <li>New York Times</li>
                <li>Washington Post</li>
              </ul>
            </div>
            <img src={newsPng} alt={`Screenshot of News component`} />
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection hasLargePadding={true} sectionId="task-component">
        <div className="component">
          <div className="side-by-side">
            <img
              src={tasksPng}
              alt={`Screenshot of ${TASK_LIST_FRIENDLY} component`}
            />
            <div>
              <h1>{TASK_LIST_FRIENDLY}</h1>
              <p>
                The {TASK_LIST_FRIENDLY} component is a simple task list that
                lets you create, edit, and complete tasks. Tasks can also be
                reopened if one is accidentally completed prematurely.
              </p>
            </div>
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection
        hasDarkBackground={true}
        hasLargePadding={true}
        sectionId="bookmark-list-component"
      >
        <div className="component">
          <div className="side-by-side">
            <div>
              <h1>{BOOKMARK_LIST_FRIENDLY}</h1>
              <p>
                The {BOOKMARK_LIST_FRIENDLY} component allows you to specify a
                list of links to help you stay organized with the places you
                visit most often online.
              </p>
            </div>
            <img
              src={bookmarkListPng}
              alt={`Screenshot of ${BOOKMARK_LIST_FRIENDLY} component`}
            />
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection hasLargePadding={true} sectionId="bookmark-component">
        <div className="component">
          <div className="side-by-side">
            <img
              src={bookmarkCardPng}
              alt={`Screenshot of ${BOOKMARK_FRIENDLY} component`}
            />
            <div>
              <h1>{BOOKMARK_FRIENDLY}</h1>
              <p>
                The {BOOKMARK_FRIENDLY} component shows a single link in a card
                to allow for more design flexibility.
              </p>
            </div>
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection
        hasDarkBackground={true}
        hasLargePadding={true}
        sectionId="welcome-component"
      >
        <div className="component">
          <div className="side-by-side">
            <div>
              <h1>{WELCOME_MESSAGE_FRIENDLY}</h1>
              <p>
                The {WELCOME_MESSAGE_FRIENDLY} component displays a personalized
                welcome message to you depending on the time of day. It will
                wish you a good morning, afternoon, or evening.
              </p>
            </div>
            <img
              src={welcomePng}
              alt={`Screenshot of ${WELCOME_MESSAGE_FRIENDLY} component`}
            />
          </div>
        </div>
      </FullWidthSection>
      <FullWidthSection hasLargePadding={true} sectionId="google-component">
        <div className="component">
          <div className="side-by-side">
            <img
              src={googleSearchPng}
              alt={`Screenshot of ${GOOGLE_SEARCH_FRIENDLY} component`}
            />
            <div>
              <h1>{GOOGLE_SEARCH_FRIENDLY}</h1>
              <p>
                The {GOOGLE_SEARCH_FRIENDLY} component provides a quick way to
                search Google. Results will open in a new tab.
              </p>
            </div>
          </div>
        </div>
      </FullWidthSection>
    </Fragment>
  );
}
