import { connect } from "react-redux";
import Widget from "./widget";
import {
  removeWidgetFromLayout,
  editWidget
} from "../../actions/layoutActions";

const mapStateToProps = state => {
  const { isLayoutLocked, editWidgetIdentifiers } = state.layouts;

  return {
    isLayoutLocked,
    editWidgetIdentifiers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeWidgetFromLayout: widget_id => {
      dispatch(removeWidgetFromLayout(widget_id));
    },
    editWidget: widget_id => {
      dispatch(editWidget(widget_id));
    }
  };
};

const WidgetContainer = connect(mapStateToProps, mapDispatchToProps)(Widget);

export default WidgetContainer;
