import React, { useCallback, useRef } from "react";
import { CustomPicker } from "react-color";
import "./CustomSwatchPicker.scss";
import {
  handleEnterKeypress,
  handleTabKeypress,
  handleShiftTabKeypress
} from "../../utils/keyEvents";

function CustomSwatchPicker(props) {
  const firstSwatchRef = useRef(null);
  const setRef = useCallback(
    node => {
      if (node) {
        node.focus();
      }

      firstSwatchRef.current = node;
    },
    [firstSwatchRef]
  );

  const onKeyDown = (event, color, index) => {
    handleEnterKeypress(event, event => props.onChangeComplete(color));
    if (index - 1 === props.colors.length) {
      event.preventDefault();
      handleTabKeypress(event, props.onLastSwatchTabPress);
    }
    if (index === 0) {
      handleShiftTabKeypress(event, props.onFirstSwatchShiftTabPress);
    }
  };

  const generateSwatch = (color, index) => {
    const rgbVal = `rgb(${color.r},${color.g},${color.b},${color.a})`;
    return (
      <div
        key={index.toString()}
        style={{
          backgroundColor: rgbVal,
          border: `1px solid ${
            rgbVal === "rgb(255,255,255,1)" ? "#ddd" : rgbVal
          }`
        }}
        className="custom-swatch"
        tabIndex={0}
        onClick={() => props.onChangeComplete(color)}
        onKeyDown={event => onKeyDown(event, color, index)}
        ref={index === 0 ? setRef : null}
        title={rgbVal}
      />
    );
  };

  const generateSwatchRows = () => {
    const rows = [];
    for (let i = 0; i < props.colors.length; i += 5) {
      const row = [];
      for (let j = i; j < Math.min(i + 5, props.colors.length); j++) {
        const swatchGroup = props.colors[j];
        row.push(
          <div key={`swatchGroup_${j}`} className="swatch-group">
            {swatchGroup.map((swatch, index) =>
              generateSwatch(swatch, j + index)
            )}
          </div>
        );
      }
      rows.push(row);
    }
    return rows;
  };
  return (
    <div className="custom-swatch-picker">
      {generateSwatchRows().map((row, index) => {
        return (
          <div key={`swatchRow_${index}`} className="row">
            {row}
          </div>
        );
      })}
    </div>
  );
}

CustomSwatchPicker.defaultProps = {
  onChangeComplete: () => {},
  color: {},
  colors: [
    [
      { r: 183, g: 28, b: 28, a: 1 },
      { r: 211, g: 47, b: 47, a: 1 },
      { r: 244, g: 67, b: 54, a: 1 }
    ],
    [
      { r: 136, g: 14, b: 79, a: 1 },
      { r: 194, g: 24, b: 91, a: 1 },
      { r: 233, g: 30, b: 99, a: 1 }
    ],
    [
      { r: 74, g: 20, b: 140, a: 1 },
      { r: 123, g: 31, b: 162, a: 1 },
      { r: 156, g: 39, b: 176, a: 1 }
    ],
    [
      { r: 49, g: 27, b: 146, a: 1 },
      { r: 81, g: 45, b: 168, a: 1 },
      { r: 103, g: 58, b: 183, a: 1 }
    ],
    [
      { r: 26, g: 35, b: 126, a: 1 },
      { r: 48, g: 63, b: 159, a: 1 },
      { r: 63, g: 81, b: 181, a: 1 }
    ],
    [
      { r: 13, g: 71, b: 161, a: 1 },
      { r: 25, g: 118, b: 210, a: 1 },
      { r: 33, g: 150, b: 243, a: 1 }
    ],
    [
      { r: 1, g: 87, b: 155, a: 1 },
      { r: 2, g: 136, b: 209, a: 1 },
      { r: 3, g: 169, b: 244, a: 1 }
    ],
    [
      { r: 0, g: 96, b: 100, a: 1 },
      { r: 0, g: 151, b: 167, a: 1 },
      { r: 0, g: 188, b: 212, a: 1 }
    ],
    [
      { r: 0, g: 77, b: 64, a: 1 },
      { r: 0, g: 121, b: 107, a: 1 },
      { r: 0, g: 150, b: 136, a: 1 }
    ],
    [
      { r: 25, g: 77, b: 51, a: 1 },
      { r: 56, g: 142, b: 60, a: 1 },
      { r: 76, g: 175, b: 80, a: 1 }
    ],
    [
      { r: 51, g: 105, b: 30, a: 1 },
      { r: 104, g: 159, b: 56, a: 1 },
      { r: 139, g: 195, b: 74, a: 1 }
    ],
    [
      { r: 130, g: 119, b: 23, a: 1 },
      { r: 175, g: 180, b: 43, a: 1 },
      { r: 205, g: 220, b: 57, a: 1 }
    ],
    [
      { r: 245, g: 127, b: 23, a: 1 },
      { r: 251, g: 192, b: 45, a: 1 },
      { r: 255, g: 235, b: 59, a: 1 }
    ],
    [
      { r: 255, g: 111, b: 0, a: 1 },
      { r: 255, g: 160, b: 0, a: 1 },
      { r: 255, g: 193, b: 7, a: 1 }
    ],
    [
      { r: 230, g: 81, b: 0, a: 1 },
      { r: 245, g: 124, b: 0, a: 1 },
      { r: 255, g: 152, b: 0, a: 1 }
    ],
    [
      { r: 191, g: 54, b: 12, a: 1 },
      { r: 230, g: 74, b: 25, a: 1 },
      { r: 255, g: 87, b: 34, a: 1 }
    ],
    [
      { r: 62, g: 39, b: 35, a: 1 },
      { r: 93, g: 64, b: 55, a: 1 },
      { r: 121, g: 85, b: 72, a: 1 }
    ],
    [
      { r: 38, g: 50, b: 56, a: 1 },
      { r: 69, g: 90, b: 100, a: 1 },
      { r: 96, g: 125, b: 139, a: 1 }
    ],
    [
      { r: 0, g: 0, b: 0, a: 1 },
      { r: 82, g: 82, b: 82, a: 1 },
      { r: 150, g: 150, b: 150, a: 1 }
    ],
    [
      { r: 170, g: 170, b: 170, a: 1 },
      { r: 221, g: 221, b: 221, a: 1 },
      { r: 255, g: 255, b: 255, a: 1 }
    ]
  ],
  onLastSwatchTabPress: () => {}
};

export default CustomPicker(CustomSwatchPicker);
