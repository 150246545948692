import {
  REQUEST_LOGIN_EMAIL_PASSWORD,
  REQUEST_LOGIN_GOOGLE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REQUEST_REGISTER_EMAIL_PASSWORD,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_AUTHENTICATED,
  USER_UNAUTHENTICATED,
  DELETE_ACCOUNT_SUCCESS,
  REQUEST_DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAILURE,
  PASSWORD_RESET_EMAIL_SENT
} from "../actions/types";

export const ACCOUNT_DELETION_STATUSES = {
  NO_ATTEMPT: "NO_ATTEMPT",
  FAILED: "FAILED",
  SUCCEEDED: "SUCCEEDED"
};

export const initialAuthState = {
  isLoggedIn: false,
  uid: "",
  accountDeletionStatus: ACCOUNT_DELETION_STATUSES.NO_ATTEMPT,
  registerFailure: false,
  loginFailure: false,
  passwordRecovery: false
};

/*
 * Responds to authentication actions and sets the appropriate state
 */
export function changeLoginState(state, action) {
  if (typeof state === "undefined") {
    return initialAuthState;
  }
  switch (action.type) {
    case USER_AUTHENTICATED:
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        ...initialAuthState,
        isLoggedIn: true,
        uid: action.uid
      });
    case REGISTER_FAILURE:
      return Object.assign({}, state, {
        ...initialAuthState,
        registerFailure: true
      });
    case USER_UNAUTHENTICATED:
    case LOGOUT:
      return Object.assign({}, state, {
        ...initialAuthState
      });
    case DELETE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        ...initialAuthState,
        accountDeletionStatus: ACCOUNT_DELETION_STATUSES.SUCCEEDED
      });
    case DELETE_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        accountDeletionStatus: ACCOUNT_DELETION_STATUSES.FAILED
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loginFailure: true,
        passwordRecovery: false,
        registerFailure: false
      });
    case PASSWORD_RESET_EMAIL_SENT:
      return Object.assign({}, state, {
        passwordRecovery: true,
        loginFailure: false,
        registerFailure: false
      });
    case REQUEST_DELETE_ACCOUNT:
    case LOGIN_SUCCESS:
    case REQUEST_REGISTER_EMAIL_PASSWORD:
    case REQUEST_LOGIN_EMAIL_PASSWORD:
    case REQUEST_LOGIN_GOOGLE:
    default:
      return state;
  }
}
