import {
  REQUEST_SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE
} from "../actions/types";

export const initialFeedbackState = {};

/*
 * Responds to feedback actions and updates the appropriate state
 */
export function handleFeedbackActions(state, action) {
  if (typeof state === "undefined") {
    return initialFeedbackState;
  }
  switch (action.type) {
    case REQUEST_SEND_FEEDBACK:
    case SEND_FEEDBACK_SUCCESS:
    case SEND_FEEDBACK_FAILURE:
    default: {
      return state;
    }
  }
}
