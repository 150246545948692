import React from "react";
import { useFormInput } from "../../custom-hooks/useFormInput";
import "./settings.scss";
import Input from "../input/input";
import { handleEnterKeypress } from "../../utils/keyEvents";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

export default function Settings(props) {
  const backgroundUrl = useFormInput(props.backgroundUrl);
  const backgroundImgRequired = useRequiredValidation(
    true,
    backgroundUrl.value
  );

  const onBackgroundImageBlur = event => {
    event.preventDefault();
    backgroundImgRequired.onInvalid(event);
    if (backgroundUrl.value) {
      recordEvent(
        "Background Image Changed",
        eventCategories.LAYOUT_CONFIGURATION,
        "Background Image Changed"
      );
      props.onBackgroundImageBlur(backgroundUrl.value);
    }
  };

  const onKeyPress = event =>
    handleEnterKeypress(event, () => {
      onBackgroundImageBlur(event);
    });

  return (
    <div className="settings-container">
      <Input
        id="backgroundUrl"
        type="text"
        name="backgroundUrl"
        ariaLabel="Background url input"
        onBlur={onBackgroundImageBlur}
        onKeyPress={event => onKeyPress(event)}
        validations={backgroundImgRequired.validations}
        {...backgroundUrl}
      />
    </div>
  );
}
