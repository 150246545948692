import { connect } from "react-redux";
import MobileMenu from "./MobileMenu";
import { hideMobileMenu } from "../../actions/globalActions";

const mapStateToProps = state => {
  const { isMobileMenuVisible } = state.global;
  const { isLoggedIn } = state.login;
  return {
    isMobileMenuVisible,
    isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMobileMenu: () => {
      dispatch(hideMobileMenu());
    }
  };
};

const MobileMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileMenu);

export default MobileMenuContainer;
