import React from "react";
import { useSelector } from "react-redux";
import { addUrlPrefix } from "../../utils/urlValidations";
import "./bookmark.scss";
import A from "../elements/A";

export default function Bookmark(props) {
  const bookmarks = useSelector(state => state.layouts.bookmarks);
  const bookmark = bookmarks
    ? bookmarks.find(bookmark => bookmark.widget_id === props.widget_id)
    : null;

  return (
    <div className="bookmark-container">
      {bookmark ? (
        <A
          href={addUrlPrefix(bookmark.url)}
          target="_blank"
          rel="noopener noreferrer"
          className="body-layout-font"
        >
          {bookmark.name}
        </A>
      ) : (
        <p className="body-layout-font">Bookmark is currently in edit mode</p>
      )}
    </div>
  );
}
