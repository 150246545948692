import axios from "axios";

export function sendEmail({ email, subject, body, canEmailBack }) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/send-email", {
        email,
        subject,
        body,
        canEmailBack: canEmailBack
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
