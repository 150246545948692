import React from "react";
import MainContentContainer from "../mainContentArea/MainContentContainer";
import "./appBody.scss";

export default function AppBody() {
  return (
    <div className="app-body">
      <MainContentContainer />
    </div>
  );
}
