import React from "react";
import Footer from "./Footer";
import "./Page.scss";
import classNames from "classnames";

export default function Page({ showFooter, children, showHeader }) {
  return (
    <div className={classNames("page", showHeader ? "show-header" : null)}>
      {children}
      {showFooter ? <Footer /> : null}
    </div>
  );
}

Page.defaultProps = {
  showFooter: true,
  showHeader: true
};
