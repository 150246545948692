import stocks from "../../utils/stocks.json";

export function generateSymbolsFromSelections(selections) {
  const symbols = [];
  selections.forEach(selection => {
    const symbol = selection.split(" ")[0];
    const nyse = stocks[`NYSE:${symbol}`];
    const nasdaq = stocks[`NASDAQ:${symbol}`];
    const coinbase = stocks[`COINBASE:${symbol}`];
    const oanda = stocks[`OANDA:${symbol}`];
    const tvc = stocks[`TVC:${symbol}`];

    if (nyse !== undefined) {
      symbols.push(`NYSE:${symbol} - ${nyse}`);
    } else if (nasdaq !== undefined) {
      symbols.push(`NASDAQ:${symbol} - ${nasdaq}`);
    } else if (coinbase !== undefined) {
      symbols.push(`COINBASE:${symbol} - ${coinbase}`);
    } else if (oanda !== undefined) {
      symbols.push(`OANDA:${symbol} - ${oanda}`);
    } else if (tvc !== undefined) {
      symbols.push(`TVC:${symbol} - ${tvc}`);
    }
  });
  return symbols;
}

// symbols have the format `NASDAQ:AAPL - Apple Corp`
export function createTradingViewSymbolMap(symbols, symbolKey, descriptionKey) {
  return symbols.map(symbol => {
    const splitSymbols = symbol.split("-");
    const obj = {};
    obj[symbolKey] = splitSymbols[0].trim();
    obj[
      descriptionKey
    ] = `${splitSymbols[0].trim()} - ${splitSymbols[1].trim()}`;
    return obj;
  });
}
