import React from "react";
import "./input.scss";
import InputLabel from "./InputLabel";
import classNames from "classnames";
import Validations from "../validations/Validations";

export default function Input(props) {
  const allValidations = props.validations;

  return (
    <div className="input-field">
      {props.label ? (
        <InputLabel
          label={props.label}
          helpText={props.helpText}
          helpTextPlacement={props.helpTextPlacement}
          isRequired={props.required}
        />
      ) : null}
      <input
        ref={props.reference}
        placeholder={props.placeholder}
        id={props.id}
        type={props.type}
        name={props.name}
        aria-label={props.ariaLabel}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        onBlur={props.onBlur}
        className={classNames(
          "text-input",
          allValidations.length > 0 ? "has-validation-error" : "",
          props.usePermanentAccent ? "uses-permanent-accent" : "",
          props.className
        )}
      />
      <Validations validations={allValidations} />
    </div>
  );
}

Input.defaultProps = {
  label: "",
  helpText: "",
  helpTextPlacement: "below",
  placeholder: "",
  id: "0",
  type: "text",
  initialValue: "",
  ariaLabel: "input-field",
  name: "0",
  required: false,
  value: "",
  validations: [],
  setValidations: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  onBlur: () => {},
  usePermanentAccent: false,
  className: ""
};
