import {
  REQUEST_SAVE_LAYOUT,
  REQUEST_GET_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  SAVE_LAYOUT_FAILURE,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE,
  CLEAR_LAYOUT,
  ADD_WIDGET_TO_LAYOUT,
  REMOVE_WIDGET_FROM_LAYOUT,
  LOCK_LAYOUT,
  UNLOCK_LAYOUT,
  EDIT_WIDGET,
  SAVE_WIDGET,
  STORE_LAYOUT_LOCALLY,
  ADD_BOOKMARK,
  ADD_BOOKMARK_LIST,
  SAVE_NUM_COLUMNS,
  SAVE_BACKGROUND_URL,
  ADD_TICKER_BANNER,
  CANCEL_EDIT_WIDGET,
  ADD_MARKET_OVERVIEW,
  ADD_CLOCK,
  ADD_ICON_BOOKMARK,
  ADD_RICH_TEXT
} from "./types";
import {
  writeLayout,
  updateLayout,
  queryLayoutByUid
} from "../db/LayoutFacade";
import { toggleDirtyForm, showGlobalBanner } from "./globalActions";
import { BANNER_TYPE_TOO_MANY_LAYOUTS } from "../components/banner/bannerUtils";
import { showModal } from "./modalActions";
import { EDIT_COMPONENT } from "../components/modal/modalTypes";

/*
 * Saves the layout to firestore. Action is kicked off when the user clicks the
 * "Save Layout" button in the sidebar or topbar.
 */
export function saveLayoutConfiguration() {
  return (dispatch, getState) => {
    const layouts = getState().layouts;
    const uid = getState().userDetails.uid;
    dispatch({ type: REQUEST_SAVE_LAYOUT });

    // Temporary state should not be written to the database
    const layoutToWrite = Object.assign({}, layouts, {
      uid,
      richTexts: JSON.stringify(layouts.richTexts)
    });
    delete layoutToWrite.id; // N/A because id is actually the docref
    delete layoutToWrite.editWidgetIdentifiers;
    delete layoutToWrite.isLayoutLocked;

    if (!layouts.id) {
      writeLayout(layoutToWrite)
        .then(docRef => {
          dispatch({
            type: SAVE_LAYOUT_SUCCESS,
            id: docRef.id
          });
        })
        .catch(err => {
          dispatch({ type: SAVE_LAYOUT_FAILURE });
        });
    } else {
      updateLayout(layouts.id, layoutToWrite)
        .then(() => {
          dispatch({
            type: SAVE_LAYOUT_SUCCESS,
            id: layouts.id
          });
        })
        .catch(err => {
          dispatch({ type: SAVE_LAYOUT_FAILURE });
        });
    }
    dispatch(lockLayout());
  };
}

/*
 * Retrieves the layout configurations from firestore.
 * Responds to a change in authentication as a side effect.
 */
export function getLayoutConfigurations(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_GET_LAYOUT });

    return queryLayoutByUid(uid)
      .then(results => {
        if (results.size > 1) {
          dispatch(showGlobalBanner(BANNER_TYPE_TOO_MANY_LAYOUTS));
        } else {
          results.forEach(doc => {
            const id = doc.id;
            const data = doc.data();
            const dataNormalized = Object.assign({}, data, {
              richTexts: JSON.parse(data.richTexts)
            });
            dispatch({
              type: GET_LAYOUT_SUCCESS,
              id,
              ...dataNormalized
            });
          });
        }
      })
      .catch(err => {
        dispatch({ type: GET_LAYOUT_FAILURE });
      });
  };
}

export function saveNumberOfColumns(numberOfColumns) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SAVE_NUM_COLUMNS, numberOfColumns });
  };
}

export function saveBackgroundImageUrl(backgroundUrl) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SAVE_BACKGROUND_URL, backgroundUrl });
  };
}

/*
 * To prevent writing to firestore every time the user makes a material change to their layout,
 * this action stores the change in browser storage.
 */
export function storeLayoutLocally({ widgetList, layout }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: STORE_LAYOUT_LOCALLY, widgetList, layout });
  };
}

/*
 * Clears the layout, and occurs when a user has logged out.
 */
export function clearLayout() {
  return dispatch => {
    dispatch({ type: CLEAR_LAYOUT });
  };
}

export function addWidgetToLayout(widgetType) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: ADD_WIDGET_TO_LAYOUT, widgetType });
  };
}

export function removeWidgetFromLayout(widget_id) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: REMOVE_WIDGET_FROM_LAYOUT, widget_id });
  };
}

/*
 * Certain widgets can be configured, such as bookmarks and market components.
 * This action stores the widget Id and then opens a modal.
 */
export function editWidget(widget_id) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: EDIT_WIDGET, widget_id });
    dispatch(showModal(EDIT_COMPONENT));
  };
}

export function saveWidget(widget_id) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SAVE_WIDGET, widget_id });
  };
}

/*
 * A side effect of saving the layout. Hides the sidebar and the edit styles on the widgets.
 */
export function lockLayout() {
  return dispatch => {
    dispatch({ type: LOCK_LAYOUT });
    dispatch(toggleDirtyForm(false));
  };
}

/*
 * Shows the sidebar and makes the widgets editable.
 */
export function unlockLayout() {
  return dispatch => {
    dispatch({ type: UNLOCK_LAYOUT });
  };
}

export function addBookmark({ widget_id, url, name }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: ADD_BOOKMARK, widget_id, url, name });
  };
}

export function addBookmarkList({ widget_id, category, bookmarks }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: ADD_BOOKMARK_LIST, widget_id, category, bookmarks });
  };
}

export function addIconBookmark({ widget_id, url, icon }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: ADD_ICON_BOOKMARK, widget_id, url, icon });
  };
}

export function addTickerBanner({ widget_id, symbols }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: ADD_TICKER_BANNER, widget_id, symbols });
  };
}

export function addMarketOverview({
  widget_id,
  symbols,
  showIndices,
  showCrypto
}) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({
      type: ADD_MARKET_OVERVIEW,
      widget_id,
      symbols,
      showIndices,
      showCrypto
    });
  };
}

export function addClock({
  widget_id,
  timeFormat,
  clockSize,
  showSeconds,
  alignment,
  color
}) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({
      type: ADD_CLOCK,
      widget_id,
      timeFormat,
      clockSize,
      showSeconds,
      alignment,
      color
    });
  };
}

export function addRichText({ widget_id, delta }) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({
      type: ADD_RICH_TEXT,
      widget_id,
      delta
    });
  };
}

export function cancelWidgetEdit() {
  return dispatch => {
    dispatch({ type: CANCEL_EDIT_WIDGET });
  };
}
