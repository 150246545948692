import React from "react";
import "./Validations.scss";

export default function Validations(props) {
  return props.validations.length > 0
    ? props.validations.map((validation, index) => (
        <span key={index} className="validation-error">
          {validation}
        </span>
      ))
    : null;
}

Validations.defaultProps = {
  validations: []
};
