import { IS_MODAL_VISIBLE } from "../actions/types";

export const initialModalState = {
  isModalVisible: false,
  modalType: "",
  subType: ""
};

/*
 * Responds to modal actions and updates the appropriate state
 */
export function changeModalVisibility(state, action) {
  if (typeof state === "undefined") {
    return initialModalState;
  }
  switch (action.type) {
    case IS_MODAL_VISIBLE:
      return Object.assign({}, state, {
        isModalVisible: action.isModalVisible,
        modalType: action.modalType,
        subType: action.subType
      });
    default:
      return state;
  }
}
