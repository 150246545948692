import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ColorPicker from "../colorPicker/ColorPicker";
import InputLabel from "../input/InputLabel";
import {
  setAccentColor,
  setBaseFontSize,
  setComponentBorderRadius,
  setComponentBackgroundColor,
  setComponentBackgroundOpacity,
  resetStyles,
  setStyleTemplate,
  removeStyleTemplate,
  setHeaderFontFamily,
  setBodyFontFamily
} from "../../actions/StyleActions";
import HorizontalSlider from "../slider/HorizontalSlider";
import "./GlobalStyles.scss";
import ClickableText from "../clickableText/ClickableText";
import { styleTemplates } from "../../utils/StyleTemplates";
import SubtleSeparator from "../subtleSeparator/SubtleSeparator";
import Dropdown from "../dropdown/Dropdown";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";
import { PREMIUM } from "../../utils/stripeUtils";
import { showModal } from "../../actions/modalActions";
import { PREMIUM_ONLY } from "../modal/modalTypes";
import SettingsContainer from "../settings/settingsContainer";
import PremiumIcon from "../premiumIcon/PremiumIcon";
import FontPicker from "../fontPicker/FontPicker";
import H, { headerSizes } from "../elements/H";

function JustifiedField(props) {
  return <div className="justified-field">{props.children}</div>;
}

function SplitSection(props) {
  return <div className="split-section">{props.children}</div>;
}

export default function GlobalStyles() {
  const dispatch = useDispatch();
  const {
    id,
    accentColor,
    baseFontSize,
    borderRadius,
    componentBackgroundColor,
    templateKey,
    headerFontFamily,
    bodyFontFamily
  } = useSelector(state => state.styles);
  const { planType } = useSelector(state => state.userDetails);
  const { isExperimentalMode } = useSelector(state => state.global);

  const [template, setTemplate] = useState(styleTemplates[templateKey]?.label);
  const resetStylesRef = useRef();
  const getStyleLabels = () => {
    const choices = [];
    for (const value of Object.values(styleTemplates)) {
      choices.push(value.label);
    }
    return choices;
  };

  const checkPremium = callback => {
    if (planType === PREMIUM || isExperimentalMode) {
      callback();
    } else {
      dispatch(showModal(PREMIUM_ONLY, "Custom Styles"));
    }
  };

  const onBaseFontSizeChange = size => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Font Size",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Font Size"
      );
      dispatch(setBaseFontSize(size));
    });
  };

  const onAccentColorChange = rgbColor => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Accent Color",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Accent Color"
      );
      dispatch(setAccentColor(rgbColor));
    });
  };

  const onComponentBorderRadiusChange = borderRadius => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Component Border Radius",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Component Border Radius"
      );
      dispatch(setComponentBorderRadius(borderRadius));
    });
  };

  const onComponentBackgroundColorChange = rgbColor => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Component Background Color",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Component Background Color"
      );
      dispatch(setComponentBackgroundColor(rgbColor));
    });
  };

  const onComponentOpacityChange = opacity => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Component Opacity",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Component Opacity"
      );
      dispatch(setComponentBackgroundOpacity(opacity / 10));
    });
  };

  const onHeaderFontFamilyChange = fontFamily => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Header Font Family",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Header Font Family"
      );
      dispatch(setHeaderFontFamily(fontFamily));
    });
  };

  const onBodyFontFamilyChange = fontFamily => {
    checkPremium(() => {
      setTemplate(null);
      recordEvent(
        "Change Body Font Family",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Body Font Family"
      );
      dispatch(setBodyFontFamily(fontFamily));
    });
  };

  const onResetStylesButtonClick = () => {
    recordEvent(
      "Reset Styles",
      eventCategories.LAYOUT_CONFIGURATION,
      "Reset Styles"
    );
    dispatch(resetStyles(id));
  };

  const onTemplateSelection = template => {
    if (template.length === 0) {
      dispatch(removeStyleTemplate());
    } else {
      let templateKey;
      for (const [key, value] of Object.entries(styleTemplates)) {
        if (value.label === template) {
          templateKey = key;
          break;
        }
      }
      recordEvent(
        "Change Style Template",
        eventCategories.LAYOUT_CONFIGURATION,
        "Change Style Template"
      );
      dispatch(setStyleTemplate(templateKey));
    }
  };

  const onLastAccentColorSwatchTabPress = () => {
    if (resetStylesRef.current) {
      resetStylesRef.current.focus();
    }
  };

  const onLastComponentBackgroundColorSwatchTabPress = () => {
    // Yes, this is a React faux pas, but its necessary because react-slider doesn't give access to the ref on the thumb
    document.getElementById("componentBackgroundOpacitySliderThumb").focus();
  };

  return (
    <div className="global-styles-container">
      <SubtleSeparator text={<span>FREE</span>} />
      <SplitSection>
        <div>
          <InputLabel
            label="Background URL"
            helpText="Provide a URL for the background image. A popular image hosting site is Imgur (https://imgur.com)."
            helpTextPlacement="right"
          />
          <SettingsContainer />
        </div>

        <div>
          <Dropdown
            label="Style Template"
            choices={getStyleLabels()}
            setDropdownValue={choice => {
              setTemplate(choice);
              onTemplateSelection(choice);
            }}
            dropdownValue={template}
          />
        </div>
      </SplitSection>
      <SubtleSeparator
        text={
          <span>
            PREMIUM <PremiumIcon />
          </span>
        }
      />
      <SplitSection>
        <div style={{ flex: 1, marginRight: "5px" }}>
          <H size={headerSizes.MEDIUM} className="style-section-header">
            Font
          </H>
          <JustifiedField>
            <div className="style-input" style={{ flex: 2 }}>
              <InputLabel label="Header" />
            </div>
            <div className="style-input" style={{ flex: 3 }}>
              <FontPicker
                selectedFont={headerFontFamily}
                onFontSelect={onHeaderFontFamilyChange}
                fontStyleClassName="header-layout-font"
              />
            </div>
          </JustifiedField>
          <JustifiedField>
            <div className="style-input" style={{ flex: 2 }}>
              <InputLabel label="Body" />
            </div>
            <div className="style-input" style={{ flex: 3 }}>
              <FontPicker
                selectedFont={bodyFontFamily}
                onFontSelect={onBodyFontFamilyChange}
                fontStyleClassName="body-layout-font"
              />
            </div>
          </JustifiedField>
          <JustifiedField>
            <div className="style-input" style={{ flex: 2 }}>
              <InputLabel
                label="Base Size"
                helpText="Changing the base font size will scale all font on the screen"
                helpTextPlacement="right"
              />
            </div>
            <div className="style-input" style={{ flex: 3 }}>
              <HorizontalSlider
                onChange={size => onBaseFontSizeChange(size)}
                value={baseFontSize}
                min={11}
                max={19}
                thumbId="fontSizeSliderThumb"
              />
            </div>
          </JustifiedField>
        </div>
        <div style={{ flex: 1, marginLeft: "5px" }}>
          <H size={headerSizes.MEDIUM} className="style-section-header">
            Component
          </H>
          <JustifiedField>
            <div style={{ flex: 2 }}>
              <InputLabel label="Background Color" />
            </div>
            <div style={{ flex: 3 }}>
              <ColorPicker
                color={componentBackgroundColor}
                onColorChange={color => onComponentBackgroundColorChange(color)}
                onLastSwatchTabPress={
                  onLastComponentBackgroundColorSwatchTabPress
                }
              />
            </div>
          </JustifiedField>
          <JustifiedField>
            <div style={{ flex: 2 }}>
              <InputLabel label="Opacity" />
            </div>
            <div style={{ flex: 3 }}>
              <HorizontalSlider
                onChange={opacity => onComponentOpacityChange(opacity)}
                value={componentBackgroundColor?.a * 10}
                min={0}
                max={10}
                thumbId="componentBackgroundOpacitySliderThumb"
              />
            </div>
          </JustifiedField>
          <JustifiedField>
            <div style={{ flex: 2 }}>
              <InputLabel
                label="Border Radius"
                helpText="Affects how curved the corners of components are"
                helpTextPlacement="right"
              />
            </div>
            <div style={{ flex: 3 }}>
              <HorizontalSlider
                onChange={borderRadius =>
                  onComponentBorderRadiusChange(borderRadius)
                }
                value={borderRadius}
                min={0}
                max={20}
              />
            </div>
          </JustifiedField>
        </div>
      </SplitSection>
      <SplitSection>
        <div>
          <H size={headerSizes.MEDIUM} className="style-section-header">
            General
          </H>
          <JustifiedField>
            <div>
              <InputLabel label="Accent Color" />
            </div>
            <div>
              <ColorPicker
                color={accentColor}
                onColorChange={color => onAccentColorChange(color)}
                onLastSwatchTabPress={onLastAccentColorSwatchTabPress}
              />
            </div>
          </JustifiedField>
          <JustifiedField>
            <ClickableText
              onClick={onResetStylesButtonClick}
              isInDarkBackground={false}
              className="reset-styles"
              buttonRef={resetStylesRef}
            >
              Reset Styles
            </ClickableText>
          </JustifiedField>
        </div>
        <div />
      </SplitSection>
    </div>
  );
}
