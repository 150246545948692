import React, { useState, useRef } from "react";
import { FaCaretRight } from "react-icons/fa";
import classNames from "classnames";
import IconText from "../IconText/IconText";
import "./SidebarWidgetOption.scss";

export default function SidebarWidgetOption(props) {
  const { item, onClick } = props;

  const [isVisible, setIsVisible] = useState(false);

  const myRef = useRef(null);

  const renderSidebarOption = item => {
    return (
      <IconText>
        {item.icon || null}
        <span className="sidebar-widget-name">{item.friendlyName}</span>
        {item.groupWidgets ? (
          <FaCaretRight
            className={classNames("sidebar-icon", "more-options")}
          />
        ) : null}
      </IconText>
    );
  };

  if (item.groupWidgets) {
    return (
      <div
        ref={myRef}
        className={classNames(
          "widget-group",
          isVisible ? "selected-option" : null
        )}
        onMouseEnter={event => {
          setIsVisible(true);
        }}
        onMouseLeave={event => {
          setIsVisible(false);
        }}
      >
        {renderSidebarOption(item)}
        {isVisible ? (
          <div className="widget-group-popup">
            {item.groupWidgets.map((childItem, i) => {
              return (
                <SidebarWidgetOption
                  key={i}
                  item={childItem}
                  onClick={onClick}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div className="single-widget" onClick={event => onClick(event, item)}>
      {renderSidebarOption(item)}
    </div>
  );
}

SidebarWidgetOption.defaultProps = {
  item: {},
  onClick: () => {}
};
