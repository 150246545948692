import React, { useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormInput } from "../../custom-hooks/useFormInput";
import { saveWidget, addBookmarkList } from "../../actions/layoutActions";
import { hideModal } from "../../actions/modalActions";
import Button from "../button/button";
import ClickableText from "../clickableText/ClickableText";
import IconText from "../IconText/IconText";
import Tooltip from "../tooltip/tooltip";
import Input from "../input/input";
import { FaTimes, FaRegPlusSquare } from "react-icons/fa";
import "./BookmarkList.scss";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

export default function EditBookmarkList(props) {
  const dispatch = useDispatch();
  const bookmarkLists = useSelector(state => state.layouts.bookmarkLists);

  const bookmarkList =
    bookmarkLists &&
    bookmarkLists.find(
      bookmarkCategory => bookmarkCategory.widget_id === props.widget_id
    );

  const category = useFormInput(bookmarkList ? bookmarkList.category : "");
  const [bookmarks, setBookmarks] = useState(
    bookmarkList
      ? bookmarkList.bookmarks
      : [
          {
            name: "",
            url: ""
          }
        ]
  );
  const listNameRef = useRef();

  useLayoutEffect(() => {
    listNameRef.current.focus();
  }, [listNameRef]);

  const onSubmit = event => {
    event.preventDefault();
    recordEvent(
      "Bookmark List Config Saved",
      eventCategories.COMPONENT_CONFIGURATION,
      "Bookmark List Config Saved"
    );
    dispatch(saveWidget(props.widget_id));
    dispatch(
      addBookmarkList({
        widget_id: props.widget_id,
        category: category.value,
        bookmarks
      })
    );
    dispatch(hideModal());
  };

  const saveBookmarkName = (index, bookmarkName) => {
    const bookmarksCopy = bookmarks.slice();
    const bookmark = bookmarksCopy[index];
    bookmark.name = bookmarkName;
    bookmarksCopy[index] = bookmark;
    setBookmarks(bookmarksCopy);
  };

  const saveBookmarkUrl = (index, url) => {
    const bookmarksCopy = bookmarks.slice();
    const bookmark = bookmarksCopy[index];
    bookmark.url = url;
    bookmarksCopy[index] = bookmark;
    setBookmarks(bookmarksCopy);
  };

  const addNewBookmark = () => {
    recordEvent(
      "Bookmark Added to List",
      eventCategories.COMPONENT_CONFIGURATION,
      "Bookmark Added to List"
    );
    const bookmarksCopy = bookmarks.slice();
    bookmarksCopy.push({ name: "", url: "" });
    setBookmarks(bookmarksCopy);
  };

  const removeBookmark = index => {
    recordEvent(
      "Bookmark Removed to List",
      eventCategories.COMPONENT_CONFIGURATION,
      "Bookmark Removed to List"
    );
    const bookmarksCopy = bookmarks.slice();
    bookmarksCopy.splice(index, 1);
    setBookmarks(bookmarksCopy);
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="bookmark-list-form">
        <Input
          label="List Name"
          ariaLabel="List Name Input"
          placeholder="List Name"
          id={"listName".concat("_", props.widget_id)}
          type="text"
          name="listName"
          {...category}
          required
          reference={listNameRef}
          usePermanentAccent
        />
        <table className="bookmark-table">
          <thead>
            <tr className="header-label-row">
              <th>Friendly Name</th>
              <th colSpan="100%">URL</th>
            </tr>
          </thead>
          <tbody>
            {bookmarks.map((bookmark, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Input
                      ariaLabel="Bookmark Name Input"
                      id={"bookmarkName".concat(
                        "_",
                        props.widget_id,
                        "_",
                        index
                      )}
                      type="text"
                      name="bookmarkName"
                      value={bookmarks[index].name}
                      onChange={event =>
                        saveBookmarkName(index, event.target.value)
                      }
                      required
                      usePermanentAccent
                    />
                  </td>
                  <td>
                    <Input
                      ariaLabel="Bookmark URL Input"
                      id={"bookmarkUrl".concat(
                        "_",
                        props.widget_id,
                        "_",
                        index
                      )}
                      type="text"
                      name="bookmarkUrl"
                      value={bookmarks[index].url}
                      onChange={event =>
                        saveBookmarkUrl(index, event.target.value)
                      }
                      required
                      usePermanentAccent
                    />
                  </td>
                  <td>
                    <Tooltip
                      tooltipMessage="Remove Bookmark"
                      placement="right"
                      referenceStyle={{ display: "inline-block" }}
                    >
                      <FaTimes
                        onClick={() => removeBookmark(index)}
                        className="remove-bookmark"
                        alt="Remove Bookmark"
                        tabIndex={0}
                        aria-label="Remove Bookmark"
                      />
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
            <tr className="add-bookmark-row">
              <td colSpan="100%">
                <IconText
                  wrapperClassName="add-bookmark"
                  onClick={addNewBookmark}
                >
                  <FaRegPlusSquare
                    alt="Add New Bookmark"
                    tabIndex={0}
                    aria-label="Add Bookmark"
                  />
                  <ClickableText>Add Bookmark</ClickableText>
                </IconText>
              </td>
            </tr>
          </tbody>
        </table>

        <Button type="submit" usePermanentAccent>
          Save Bookmark List
        </Button>
      </form>
    </div>
  );
}
