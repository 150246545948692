import { useState, useRef, useCallback } from "react";

export const useInputWidth = () => {
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef();
  const setInputRef = useCallback(node => {
    if (node) {
      inputRef.current = node;
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, []);

  return {
    inputWidth,
    inputRef,
    setInputRef
  };
};
