import {
  SET_ACCENT_COLOR,
  SET_BASE_FONT_SIZE,
  SET_COMPONENT_BORDER_RADIUS,
  SET_COMPONENT_BACKGROUND_COLOR,
  SET_COMPONENT_BACKGROUND_OPACITY,
  REQUEST_SAVE_STYLES,
  SAVE_STYLES_SUCCESS,
  SAVE_STYLES_FAILURE,
  REQUEST_GET_STYLES,
  GET_STYLES_SUCCESS,
  GET_STYLES_FAILURE,
  RESET_STYLES,
  SELECT_STYLE_TEMPLATE,
  REMOVE_STYLE_TEMPLATE,
  CLEAR_STYLES,
  SET_HEADER_FONT_FAMILY,
  SET_BODY_FONT_FAMILY
} from "./types";
import {
  updateStyles,
  createStyles,
  queryStylesByUid
} from "../db/StylesFacade";
import { toggleDirtyForm, showGlobalBanner } from "./globalActions";
import { BANNER_TYPE_TOO_MANY_STYLE_CONFIGS } from "../components/banner/bannerUtils";
import { writeError } from "../db/ErrorFacade";

/*
 * Saves a style configuration to firestore.
 */
export function saveStyles() {
  return (dispatch, getState) => {
    const styles = getState().styles;
    const uid = getState().userDetails.uid;
    dispatch({ type: REQUEST_SAVE_STYLES });

    const stylesToWrite = Object.assign({}, styles, { uid });
    delete stylesToWrite.id; // N/A because id is actually the docref

    if (styles.id) {
      return updateStyles(styles.id, stylesToWrite)
        .then(() => {
          dispatch({
            type: SAVE_STYLES_SUCCESS,
            id: styles.id
          });
        })
        .catch(err => {
          dispatch({ type: SAVE_STYLES_FAILURE });
          writeError({
            uid,
            error: "Updating styles failed",
            cause: err
          });
        });
    } else {
      return createStyles(stylesToWrite)
        .then(docRef => {
          dispatch({
            type: SAVE_STYLES_SUCCESS,
            id: docRef.id
          });
        })
        .catch(err => {
          dispatch({ type: SAVE_STYLES_FAILURE });
          writeError({
            uid,
            error: "Creating styles failed",
            cause: err
          });
        });
    }
  };
}

/*
 * Retrieves the styles for a user based on uid
 */
export function getStyles(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_GET_STYLES });

    return queryStylesByUid(uid)
      .then(results => {
        if (results.size > 1) {
          dispatch(showGlobalBanner(BANNER_TYPE_TOO_MANY_STYLE_CONFIGS));
        } else {
          results.forEach(doc => {
            const id = doc.id;
            const data = doc.data();
            dispatch({
              type: GET_STYLES_SUCCESS,
              id,
              ...data
            });
          });
        }
      })
      .catch(err => {
        dispatch({ type: GET_STYLES_FAILURE });
      });
  };
}

export function setAccentColor(color) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_ACCENT_COLOR, accentColor: color });
  };
}

export function setBaseFontSize(size) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_BASE_FONT_SIZE, baseFontSize: size });
  };
}

export function setComponentBorderRadius(borderRadius) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_COMPONENT_BORDER_RADIUS, borderRadius });
  };
}

export function setComponentBackgroundColor(backgroundColor) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_COMPONENT_BACKGROUND_COLOR, backgroundColor });
  };
}

export function setComponentBackgroundOpacity(opacity) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_COMPONENT_BACKGROUND_OPACITY, opacity });
  };
}

export function setHeaderFontFamily(fontFamily) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_HEADER_FONT_FAMILY, fontFamily });
  };
}

export function setBodyFontFamily(fontFamily) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    dispatch({ type: SET_BODY_FONT_FAMILY, fontFamily });
  };
}

export function setStyleTemplate(templateKey) {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    return new Promise(function (resolve, reject) {
      dispatch({ type: SELECT_STYLE_TEMPLATE, templateKey });
      resolve();
    });
  };
}

export function removeStyleTemplate() {
  return dispatch => {
    dispatch(toggleDirtyForm(true));
    return new Promise(function (resolve, reject) {
      dispatch({ type: REMOVE_STYLE_TEMPLATE });
      resolve();
    });
  };
}

/**
 * Sets the styles to the base set of styles ("Dashboi template"), but keeps the existing style ID
 */
export function resetStyles(id) {
  return dispatch => dispatch({ type: RESET_STYLES, id });
}

/**
 * Completely resets the style values, useful when a user logs out.
 */
export function clearStyles() {
  return dispatch => dispatch({ type: CLEAR_STYLES });
}

/**
 * Resets the styles if the template key is not set
 * This represents the state where the user has entered experimental mode, but chooses to discard their premium
 * features when saving. In such a case, we should only save their style configuration if they have used a template
 * because templates are the only way to edit styles if you don't purchase premium
 */
export function saveTemplatedStyles() {
  return (dispatch, getState) => {
    const styles = getState().styles;
    if (!styles.templateKey) {
      dispatch(resetStyles(styles.id));
    }
  };
}
