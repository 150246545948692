import React, { useState } from "react";
import H, { headerSizes } from "../elements/H";
import LogoAndSiteName from "../topbar/LogoAndSiteName";
import "./AuthenticationContainer.scss";
import background from "../../resources/sign-up-quotes.jpg";
import SignUpForm from "./SignUpForm";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import QuestionCommand from "./QuestionCommand";
import LoginFooter from "./LoginFooter";
import { Fragment } from "react";
import { useMobile } from "../../custom-hooks/useMobile";
import Premium from "./Premium";
import LoadingGlass from "../loadSpinner/LoadingGlass";

export const AuthenticationInterfaces = {
  LOGIN: "LOGIN",
  SIGN_UP: "SIGN_UP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  PREMIUM: "PREMIUM"
};

export default function AuthenticationContainer({ authenticationInterface }) {
  const style = {
    backgroundImage: `url(${background})`
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const { isMobile } = useMobile({});

  function getInterface() {
    switch (authenticationInterface) {
      case AuthenticationInterfaces.SIGN_UP:
      default:
        return (
          <Fragment>
            <SignUpForm />
            <QuestionCommand
              question="Already have an account?"
              command="Log in"
              route="/login"
            />
          </Fragment>
        );
      case AuthenticationInterfaces.LOGIN:
        return (
          <Fragment>
            <Login />
            <QuestionCommand
              question="Don't have an account?"
              command="Sign up now!"
              route="/signup"
            />
          </Fragment>
        );
      case AuthenticationInterfaces.FORGOT_PASSWORD:
        return (
          <Fragment>
            <ForgotPassword />
            <QuestionCommand
              question="Don't have an account?"
              command="Sign up now!"
              route="/signup"
            />
          </Fragment>
        );
      case AuthenticationInterfaces.PREMIUM:
        return (
          <Fragment>
            <Premium setIsProcessing={setIsProcessing} />
          </Fragment>
        );
    }
  }

  return (
    <div className="sign-up-container">
      <div className="sign-up-fields-container">
        {isProcessing ? (
          <LoadingGlass style={{ transform: "translate(-30px, -30px)" }} />
        ) : null}
        <div className="sign-up-header">
          <LogoAndSiteName />
          <H size={headerSizes.SMALL} textAlign="center">
            A better browser experience
          </H>
        </div>
        <div className="sign-up-fields">{getInterface()}</div>
        <div className="sign-up-footer">
          <LoginFooter />
        </div>
      </div>
      {isMobile ? null : (
        <div className="sign-up-quote-container">
          <div style={style} className="background-image" />
          <div className="sign-up-quote">
            <div className="sign-up-quote-elements">
              <H>Create a workspace that works for you.</H>
              <p>
                Gone are the days of a boring start page. Level up your browser
                with a better way to work.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AuthenticationContainer.defaultProps = {
  isRegistration: true
};
