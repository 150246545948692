import React, { useRef } from "react";
import { useFormInput } from "../../custom-hooks/useFormInput";
import { useEmailValidator } from "../../custom-hooks/useEmailValidator";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import "./Login.scss";
import Input from "../input/input";
import Button, { buttonStyles } from "../button/button";
import SocialLogin from "./SocialLogin";
import Banner from "../banner/banner";
import ClickableText from "../clickableText/ClickableText";
import { useDispatch, useSelector } from "react-redux";
import {
  requestLoginWithEmailAndPassword,
  requestLoginWithGoogle
} from "../../actions/AuthenticationActions";
import history from "../../utils/history";
import { bannerSentiment } from "../banner/bannerUtils";

export default function Login(props) {
  const email = useFormInput();
  const emailValidation = useEmailValidator(email.value);
  const emailRequired = useRequiredValidation(true, email.value);
  const emailRef = useRef(null);

  const password = useFormInput();
  const passwordRequired = useRequiredValidation(true, password.value);
  const passwordRef = useRef(null);

  const dispatch = useDispatch();
  const { loginFailure, passwordRecovery } = useSelector(state => state.login);

  const onSubmit = event => {
    event.preventDefault();
    emailRequired.onInvalid(event);
    passwordRequired.onInvalid(event);
    if (
      emailValidation.isValidEmail &&
      emailRequired.isFieldFilled &&
      passwordRequired.isFieldFilled
    ) {
      dispatch(requestLoginWithEmailAndPassword(email.value, password.value));
    } else if (!emailValidation.isValidEmail || !emailRequired.isFieldFilled) {
      emailRef.current.focus();
    } else if (!passwordRequired.isFieldFilled) {
      passwordRef.current.focus();
    }
  };

  const onForgotPasswordClick = event => {
    event.preventDefault();
    history.push("/forgotpassword");
  };

  return (
    <div className="login-container">
      {passwordRecovery ? (
        <Banner
          message={"Account recovery email sent"}
          sentiment={bannerSentiment.POSITIVE}
        />
      ) : null}
      {loginFailure ? (
        <Banner
          message={"Username or password is incorrect"}
          sentiment={bannerSentiment.NEGATIVE}
        />
      ) : null}
      <SocialLogin
        onGoogleSubmit={() => dispatch(requestLoginWithGoogle())}
        buttonAction={"Login"}
      />
      <div className="email-login">
        <Input
          ariaLabel="Email"
          placeholder="Email"
          id="email"
          type="text"
          name="email"
          {...email}
          onBlur={() => emailValidation.validateEmail(email.value)}
          validations={emailRequired.validations.concat(
            emailValidation.validationMessage
          )}
          required={true}
          reference={emailRef}
        />
        <Input
          ariaLabel="Password"
          placeholder="Password"
          id="password"
          type="password"
          name="password"
          {...password}
          required={true}
          validations={passwordRequired.validations}
          reference={passwordRef}
        />
        <Button
          type="submit"
          onClick={onSubmit}
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
          shouldHandleGlobalEnterKeypress={true}
        >
          Login
        </Button>
        <p className="forgot-account">
          <ClickableText onClick={onForgotPasswordClick}>
            Forgot account?
          </ClickableText>
        </p>
      </div>
    </div>
  );
}
