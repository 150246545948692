import React from "react";
import "./UnsplashCredit.scss";

/*
 * Any photos pulled from Unsplash should be given appropriate credit
 */
export default function UnsplashCredit(props) {
  return (
    <a
      className="credit-badge"
      href={props.photographLink}
      target="_blank"
      rel="noopener noreferrer"
      title={`Download free do whatever you want high-resolution photos from ${props.photographerName}`}
    >
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>unsplash-logo</title>
          <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
        </svg>
      </span>
      <span>Image credit {props.photographerName}</span>
    </a>
  );
}

UnsplashCredit.defaultProps = {
  photographerName: "",
  photographLink: ""
};
