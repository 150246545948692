import React from "react";
import "./LoginFooter.scss";

export default function LoginFooter(props) {
  return (
    <div className="login-footer">
      <p>
        {props.footerType === "LOGIN" ? "Logging in" : "Signing up"} means you
        accept the{" "}
        <a
          href="https://www.iubenda.com/privacy-policy/56685132"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.iubenda.com/terms-and-conditions/56685132"
          title="Terms and Conditions "
        >
          Terms of Conditions
        </a>
        .
      </p>
    </div>
  );
}

LoginFooter.defaultProps = {
  footerType: "LOGIN"
};
