import { useState, useEffect } from "react";

/*
 * Validates that a form input is filled out if it has been marked as required.
 */
export function useRequiredValidation(isRequired, formValue) {
  const [validations, setValidations] = useState([]);
  const [isFieldFilled, setIsFieldFilled] = useState(false);

  const onInvalid = e => {
    if (isRequired && formValue === "") {
      setValidations(["This field is required"]);
    } else {
      setValidations([]);
    }
  };

  useEffect(() => {
    setIsFieldFilled(formValue !== "");
  }, [formValue]);

  return {
    validations,
    setValidations,
    onInvalid,
    isFieldFilled
  };
}
