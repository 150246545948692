import { useState } from "react";
import {
  handleDownKeypress,
  handleEnterKeypress,
  handleTabKeypress,
  handleUpKeypress
} from "../utils/keyEvents";

export const useFocusedDropdownIndex = (
  inputRef,
  choices,
  onEnterKeyPress,
  onTabKeyPress
) => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const onKeyPress = event => {
    handleUpKeypress(event, event => {
      if (focusedIndex === 0) {
        inputRef.current.focus();
      } else {
        setFocusedIndex(focusedIndex - 1);
      }
    });
    handleDownKeypress(event, event => {
      if (focusedIndex !== choices.length) {
        setFocusedIndex(focusedIndex + 1);
      }
    });
    handleEnterKeypress(event, event => onEnterKeyPress(event, focusedIndex));
    handleTabKeypress(event, onTabKeyPress);
  };

  return {
    focusedIndex,
    setFocusedIndex,
    onKeyPress
  };
};
