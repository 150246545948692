import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import "./App.scss";
import ModalContainer from "./components/modal/modalContainer";
import { Router } from "react-router-dom";
import MobileMenuContainer from "./components/mobileMenu/MobileMenuContainer";
import history from "./utils/history";
import AppRouter from "./components/appRouter/AppRouter";

export default function App() {
  return (
    <Router history={history}>
      <Provider store={store}>
        <div className="App">
          <AppRouter />
          <ModalContainer />
          <MobileMenuContainer />
        </div>
      </Provider>
    </Router>
  );
}
