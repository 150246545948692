import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import { FaTimes } from "react-icons/fa";
import {
  FEEDBACK,
  DELETE_ACCOUNT,
  EDIT_COMPONENT,
  PREMIUM_ONLY,
  PREMIM_ONLY_SAVE,
  CANCEL_PREMIUM
} from "./modalTypes";
import FeedbackContainer from "../feedback/FeedbackContainer";
import DeleteAccountContainer from "../deleteAccount/DeleteAccountContainer";
import { handleEnterKeypress } from "../../utils/keyEvents";
import { useMobile } from "../../custom-hooks/useMobile";
import classNames from "classnames";
import EditWidgetModal from "../widget/EditWidgetModal";
import {
  TICKER_BANNER,
  BOOKMARK_LIST,
  BOOKMARK,
  MARKET_OVERVIEW,
  CLOCK,
  ICON_BOOKMARK,
  RICH_TEXT
} from "../widget/widgetUtils";
import TitleAndLogo from "../titleAndLogo/TitleAndLogo";
import PremiumOnlyModal from "./PremiumOnlyModal";
import PremiumOnlySaveModal from "./PremiumOnlySaveModal";
import CancelPremiumModal from "./CancelPremiumModal";

export default function Modal(props) {
  const { isMobile } = useMobile({});

  const selectCorrectForm = type => {
    switch (type) {
      case FEEDBACK:
        return <FeedbackContainer />;
      case DELETE_ACCOUNT:
        return <DeleteAccountContainer />;
      case EDIT_COMPONENT:
        return <EditWidgetModal />;
      case PREMIUM_ONLY:
        return <PremiumOnlyModal subType={props.subType} />;
      case PREMIM_ONLY_SAVE:
        return <PremiumOnlySaveModal />;
      case CANCEL_PREMIUM:
        return <CancelPremiumModal />;
      default:
        return null;
    }
  };

  const selectEditWidgetTitle = () => {
    const widget_id =
      props.editWidgetIdentifiers.length > 0
        ? props.editWidgetIdentifiers[0]
        : null;
    const widgetIndex = props.widgetList.findIndex(
      widget => widget.widget_id === widget_id
    );
    const widgetType =
      widgetIndex !== -1 && props.widgetList
        ? props.widgetList[widgetIndex].widgetType
        : "Yo";

    switch (widgetType) {
      case TICKER_BANNER:
        return "Edit Ticker Banner";
      case MARKET_OVERVIEW:
        return "Edit Market Overview";
      case BOOKMARK_LIST:
        return "Edit Bookmark List";
      case CLOCK:
        return "Edit Clock";
      case ICON_BOOKMARK:
        return "Edit Icon Bookmark";
      case BOOKMARK:
        return "Edit Bookmark";
      case RICH_TEXT:
        return "Add Custom Text";
      default:
        return "No Component Found";
    }
  };

  const selectFormTitle = type => {
    switch (type) {
      case FEEDBACK:
        return "Provide Feedback";
      case DELETE_ACCOUNT:
        return "Are you sure?";
      case EDIT_COMPONENT:
        return selectEditWidgetTitle();
      case PREMIUM_ONLY:
        return "This is a premium feature";
      case PREMIM_ONLY_SAVE:
        return "Upgrade your account";
      case CANCEL_PREMIUM:
        return "We're sad to see you go!";
      default:
        return "";
    }
  };

  const onCloseButtonKeyPress = event =>
    handleEnterKeypress(event, props.closeModal);

  const constructChildren = () => {
    const { closeModal, modalType } = props;
    return (
      <Fragment>
        <div className="glass" onClick={closeModal} data-testid="glass" />
        <div className={classNames("modal", isMobile ? "mobile-view" : "")}>
          <div className="modal-header">
            <TitleAndLogo
              shouldShowLogo={false}
              title={selectFormTitle(modalType)}
            />
            <FaTimes
              onClick={closeModal}
              onKeyPress={onCloseButtonKeyPress}
              className="close-modal-icon"
              height={"14px"}
              width={"14px"}
              data-testid="close-modal-icon"
              tabIndex={0}
            />
          </div>
          <div>{selectCorrectForm(modalType)}</div>
        </div>
      </Fragment>
    );
  };

  return props.isModalVisible
    ? ReactDOM.createPortal(constructChildren(), document.body)
    : null;
}
