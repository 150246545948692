import axios from "axios";

export function cancelSubscription(email, subscriptionId) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/cancel-subscription", {
        email,
        subscriptionId
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
