import { connect } from "react-redux";
import DeleteAccount from "./DeleteAccount";
import { hideModal } from "../../actions/modalActions";
import { requestAccountDeletion } from "../../actions/AuthenticationActions";

const mapStateToProps = state => {
  const { uid } = state.userDetails;
  return {
    uid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancelClick: () => {
      dispatch(hideModal());
    },
    onDeleteAccountClick: uid => {
      dispatch(requestAccountDeletion(uid));
    }
  };
};

const DeleteAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAccount);

export default DeleteAccountContainer;
