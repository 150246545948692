import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { changeLoginState } from "./reducers/authenticationReducer";
import { changeModalVisibility } from "./reducers/modalReducer";
import { handleTaskActions } from "./reducers/taskReducer";
import { handleLayoutActions } from "./reducers/layoutReducer";
import { handleUserDetailsActions } from "./reducers/userReducer";
import { handleGlobalActions } from "./reducers/globalReducer";
import { verifyAuthentication } from "./actions/AuthenticationActions";
import { handleFeedbackActions } from "./reducers/feedbackReducer";
import { handleStyleActions } from "./reducers/StyleReducer";

const middleware = [thunk];

const initialState = {};

// Saves the values from the redux state into localstorage for some db hit improvements
const saveState = state => {
  try {
    let serializedState = JSON.stringify(state);
    localStorage.setItem("dashboi", serializedState);
  } catch (err) {}
};

// loads state from local storage and uses inital state
const loadState = () => {
  try {
    let serializedState = localStorage.getItem("dashboi");
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initialState;
  }
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  // Adding a new reducer? Save yourself some headache and add it to renderWithRedux helper as well
  combineReducers({
    login: changeLoginState,
    modal: changeModalVisibility,
    task: handleTaskActions,
    layouts: handleLayoutActions,
    userDetails: handleUserDetailsActions,
    global: handleGlobalActions,
    feedback: handleFeedbackActions,
    styles: handleStyleActions
  }),
  loadState(),
  composeEnhancers(applyMiddleware(...middleware))
);

// on page load, check for authentication results from firebase
store.dispatch(verifyAuthentication());

// whenever changes are made to the global store, replicate them in the browser local storage
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
