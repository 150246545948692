import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TIME_FORMATS, CLOCK_SIZES, CLOCK_ALIGNMENT } from "./Clock";
import RadioButton, {
  RADIO_BUTTON_ORIENTATIONS
} from "../radioButton/RadioButton";
import Checkbox from "../checkbox/CheckBox";
import Button from "../button/button";
import { addClock, saveWidget } from "../../actions/layoutActions";
import { hideModal } from "../../actions/modalActions";
import InputLabel from "../input/InputLabel";
import ColorPicker from "../colorPicker/ColorPicker";
import { WHITE } from "../../utils/colors";
import { useRefCallback } from "../../custom-hooks/useRefCallback";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

export default function EditClock(props) {
  const dispatch = useDispatch();
  const { clocks } = useSelector(state => state.layouts);
  const clockConfig = clocks
    ? clocks.find(clock => clock.widget_id === props.widget_id)
    : null;

  const [timeFormat, setTimeFormat] = useState(
    clockConfig?.timeFormat || TIME_FORMATS.STANDARD
  );
  const [showSeconds, setShowSeconds] = useState(
    clockConfig?.showSeconds === undefined ? false : clockConfig.showSeconds
  );
  const [clockSize, setClockSize] = useState(
    clockConfig?.clockSize || CLOCK_SIZES.MEDIUM
  );
  const [alignment, setAlignment] = useState(
    clockConfig?.alignment || CLOCK_ALIGNMENT.CENTER
  );
  const [color, setColor] = useState(clockConfig?.color || WHITE);

  const { referenceFn } = useRefCallback(ref => ref.current.focus());
  const alignmentRef = useRef(null);
  const displaySecondsRef = useRef(null);

  const onSaveClock = () => {
    recordEvent(
      "Clock Config Saved",
      eventCategories.COMPONENT_CONFIGURATION,
      "Clock Config Saved"
    );
    dispatch(saveWidget(props.widget_id));
    dispatch(
      addClock({
        widget_id: props.widget_id,
        timeFormat,
        clockSize,
        showSeconds,
        alignment,
        color
      })
    );
    dispatch(hideModal());
  };

  return (
    <div>
      <RadioButton
        label="Time Format"
        choices={[TIME_FORMATS.STANDARD, TIME_FORMATS.MILITARY]}
        name="time_format"
        onSelection={choice => {
          recordEvent(
            "Set Clock Time Format",
            eventCategories.COMPONENT_CONFIGURATION,
            "Set Clock Time Format"
          );
          setTimeFormat(choice);
        }}
        value={timeFormat}
        orientation={RADIO_BUTTON_ORIENTATIONS.HORIZONTAL}
        autoFocusFirstInput={true}
        references={[referenceFn]}
      />
      <RadioButton
        label="Clock Size"
        choices={[CLOCK_SIZES.SMALL, CLOCK_SIZES.MEDIUM, CLOCK_SIZES.LARGE]}
        name="clock_size"
        onSelection={choice => {
          recordEvent(
            "Set Clock Size",
            eventCategories.COMPONENT_CONFIGURATION,
            "Set Clock Size"
          );
          setClockSize(choice);
        }}
        value={clockSize}
        orientation={RADIO_BUTTON_ORIENTATIONS.HORIZONTAL}
        autoFocusFirstInput={false}
      />
      <RadioButton
        label="Alignment"
        choices={[
          CLOCK_ALIGNMENT.LEFT,
          CLOCK_ALIGNMENT.CENTER,
          CLOCK_ALIGNMENT.RIGHT
        ]}
        name="clock_alignment"
        onSelection={choice => {
          recordEvent(
            "Set Clock Alignment",
            eventCategories.COMPONENT_CONFIGURATION,
            "Set Clock Alignment"
          );
          setAlignment(choice);
        }}
        value={alignment}
        orientation={RADIO_BUTTON_ORIENTATIONS.HORIZONTAL}
        references={[alignmentRef, null, null]}
        autoFocusFirstInput={false}
      />
      <div className="style-input">
        <InputLabel label="Text Color" />
        <ColorPicker
          color={color}
          onColorChange={color => {
            recordEvent(
              "Set Clock Color",
              eventCategories.COMPONENT_CONFIGURATION,
              "Set Clock Color"
            );
            setColor(color);
          }}
          onLastSwatchTabPress={() => displaySecondsRef.current.focus()}
          onFirstSwatchShiftTabPress={() => alignmentRef.current.focus()}
        />
      </div>
      <Checkbox
        label="Display Seconds"
        uniqueId="showSeconds"
        isChecked={showSeconds}
        onChange={() => {
          recordEvent(
            "Set Clock Seconds",
            eventCategories.COMPONENT_CONFIGURATION,
            "Set Clock Seconds"
          );
          setShowSeconds(!showSeconds);
        }}
        reference={displaySecondsRef}
      />
      <Button onClick={onSaveClock}>Save Clock</Button>
    </div>
  );
}
