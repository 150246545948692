import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CenterPane from "../../centerPane/CenterPane";
import Button from "../../button/button";
import { useFormInput } from "../../../custom-hooks/useFormInput";
import { useEmailValidator } from "../../../custom-hooks/useEmailValidator";
import Input from "../../input/input";
import TextArea from "../../textArea/TextArea";
import Banner from "../../banner/banner";
import { bannerSentiment } from "../../banner/bannerUtils";
import { sendEmail } from "../../../axios/ContactPageFacade";
import "./ContactPage.scss";

export default function ContactPage(props) {
  const email = useFormInput("");
  const subject = useFormInput("");
  const body = useFormInput("");
  const emailValidation = useEmailValidator(email.value);
  const [canEmailBack, setCanEmailBack] = useState(false);
  const [isMessageReceived, setIsMessageReceived] = useState(false);
  const [didErrorOccur, setDidErrorOccur] = useState(false);

  const onFormSubmit = event => {
    event.preventDefault();
    if (emailValidation.isValidEmail) {
      sendEmail({
        email: email.value,
        subject: subject.value,
        body: body.value,
        canEmailBack: canEmailBack
      })
        .then(res => {
          setIsMessageReceived(true);
          email.onChange({ target: { value: "" } });
          subject.onChange({ target: { value: "" } });
          body.onChange({ target: { value: "" } });
          setCanEmailBack(false);
        })
        .catch(err => setDidErrorOccur(true));
    }
  };

  return (
    <CenterPane>
      <Helmet>
        <title>Contact the Dashboi Team</title>
        <meta
          name="description"
          content="Contact the Dashboi team with any issues, feature requests or just to say hi. We love hearing from our users!"
        />
        <meta
          name="og:title"
          property="og:title"
          content="Contact the Dashboi Team"
        />
        <link rel="canonical" href="https://dashboi.com/contact" />
      </Helmet>
      <h1>Contact the Dashboi Team</h1>
      <p>
        We love to hear from our users. Let us know if you have any issues with
        the application or ideas about how we can improve, or just drop in to
        say hi and show us some love. We promise to take all requests seriously
        and email you back if you want to hear back from us.
      </p>
      {isMessageReceived ? (
        <Banner
          message="Message sent to the Dashboi team successfully!"
          sentiment={bannerSentiment.POSITIVE}
        />
      ) : null}
      {didErrorOccur ? (
        <Banner
          message="We're sorry, we experienced an error sending your message to the Dashboi team. Please try again at a later time or send us an email directly at dashboi.app@gmail.com."
          sentiment={bannerSentiment.NEGATIVE}
        />
      ) : null}
      <form onSubmit={onFormSubmit} data-testid="contact-form">
        <Input
          type="text"
          name="email"
          required={true}
          id="email_contact"
          label="Email"
          ariaLabel="Email"
          placeholder="fanboi@dashboi.com"
          onBlur={() => emailValidation.validateEmail(email.value)}
          validations={emailValidation.validationMessage}
          {...email}
        />
        <Input
          type="text"
          name="Subject"
          required={true}
          id="subject_contact"
          label="Subject"
          ariaLabel="Subject"
          placeholder="I love using the news components"
          {...subject}
        />
        <TextArea
          type="text"
          name="Body"
          required={true}
          className="contact-text-area"
          id="body_contact"
          label="Body"
          ariaLabel="Body"
          placeholder="Can you add more news sources?"
          {...body}
        />
        <div>
          <input
            type="checkbox"
            name="can_we_email"
            checked={canEmailBack}
            onChange={() => setCanEmailBack(!canEmailBack)}
            className="checkbox-input"
            aria-label="You may email back"
          />
          <label
            htmlFor="can_we_email"
            onClick={() => setCanEmailBack(!canEmailBack)}
          >
            You may email me back with questions or comments
          </label>
        </div>
        <Button type="submit">Send Message</Button>
      </form>
    </CenterPane>
  );
}
