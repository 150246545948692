import React from "react";
import { useSelector } from "react-redux";
import {
  BOOKMARK,
  BOOKMARK_LIST,
  TICKER_BANNER,
  MARKET_OVERVIEW,
  CLOCK,
  ICON_BOOKMARK,
  RICH_TEXT
} from "./widgetUtils";
import EditTickerBanner from "../finance/Tickers/EditTickerBanner";
import EditBookmark from "../bookmark/EditBookmark";
import EditBookmarkList from "../bookmarkList/EditBookmarkList";
import EditMarketOverview from "../finance/marketOverview/EditMarketOverview";
import EditClock from "../clock/EditClock";
import EditIconBookmark from "../iconBookmark/EditIconBookmark";
import EditRichText from "../richText/EditRichText";

export default function EditWidgetModal(props) {
  const editWidgetIdentifiers = useSelector(
    state => state.layouts.editWidgetIdentifiers
  );
  const widgetList = useSelector(state => state.layouts.widgetList);

  const widget_id =
    editWidgetIdentifiers.length > 0 ? editWidgetIdentifiers[0] : null;
  const widgetIndex = widgetList.findIndex(
    widget => widget.widget_id === widget_id
  );
  const widgetType =
    widgetIndex !== -1 && widgetList
      ? widgetList[widgetIndex].widgetType
      : "Yo";

  const getEditForm = () => {
    switch (widgetType) {
      case BOOKMARK:
        return <EditBookmark widget_id={widget_id} />;
      case BOOKMARK_LIST:
        return <EditBookmarkList widget_id={widget_id} />;
      case TICKER_BANNER:
        return <EditTickerBanner widget_id={widget_id} />;
      case MARKET_OVERVIEW:
        return <EditMarketOverview widget_id={widget_id} />;
      case CLOCK:
        return <EditClock widget_id={widget_id} />;
      case ICON_BOOKMARK:
        return <EditIconBookmark widget_id={widget_id} />;
      case RICH_TEXT:
        return <EditRichText widget_id={widget_id} />;
      default:
        return (
          <div>No widget type found. Please close the modal and try again.</div>
        );
    }
  };

  return getEditForm();
}
