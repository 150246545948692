import {
  TOGGLE_DIRTY_FORM_ON,
  TOGGLE_DIRTY_FORM_OFF,
  SHOW_BANNER,
  HIDE_BANNER,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU,
  SET_LAST_EDIT_TIME,
  SHOW_SAVE_ALERT,
  HIDE_SAVE_ALERT,
  SET_ERROR,
  CLEAR_ERROR,
  SET_EXPERIMENTAL_MODE
} from "../actions/types";

export const initialGlobalState = {
  isFormDirty: false,
  isBannerVisible: false,
  bannerType: null,
  isMobileMenuVisible: false,
  lastEditTime: null,
  isSaveAlertVisible: false,
  error: null,
  isExperimentalMode: false
};

/*
 * Responds to global actions and updates the appropriate state
 */
export function handleGlobalActions(state, action) {
  if (typeof state === "undefined") {
    return initialGlobalState;
  }
  switch (action.type) {
    case TOGGLE_DIRTY_FORM_ON: {
      return Object.assign({}, state, {
        isFormDirty: true
      });
    }
    case TOGGLE_DIRTY_FORM_OFF: {
      return Object.assign({}, state, {
        isFormDirty: false
      });
    }
    case SHOW_BANNER: {
      return Object.assign({}, state, {
        isBannerVisible: true,
        bannerType: action.bannerType
      });
    }
    case HIDE_BANNER: {
      return Object.assign({}, state, {
        isBannerVisible: false,
        bannerType: null
      });
    }
    case SHOW_MOBILE_MENU: {
      return Object.assign({}, state, {
        isMobileMenuVisible: true
      });
    }
    case HIDE_MOBILE_MENU: {
      return Object.assign({}, state, {
        isMobileMenuVisible: false
      });
    }
    case SET_LAST_EDIT_TIME: {
      return Object.assign({}, state, {
        lastEditTime: action.time
      });
    }
    case SHOW_SAVE_ALERT: {
      return Object.assign({}, state, {
        isSaveAlertVisible: true
      });
    }
    case HIDE_SAVE_ALERT: {
      return Object.assign({}, state, {
        isSaveAlertVisible: false
      });
    }
    case SET_ERROR: {
      return Object.assign({}, state, {
        error: action.error
      });
    }
    case CLEAR_ERROR: {
      return Object.assign({}, state, {
        error: null
      });
    }
    case SET_EXPERIMENTAL_MODE: {
      return Object.assign({}, state, {
        isExperimentalMode: action.isExperimental
      });
    }
    default: {
      return state;
    }
  }
}
