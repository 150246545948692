import React from "react";
import Dropdown from "../dropdown/Dropdown";

export const fonts = {
  "Amatic SC": "cursive",
  Anton: "sans-serif",
  "Carter One": "cursive",
  "Indie Flower": "cursive",
  "Josefin Slab": "serif",
  "Kaushan Script": "cursive",
  Lato: "sans-serif",
  Merienda: "cursive",
  Merriweather: "serif",
  Monoton: "cursive",
  Oswald: "sans-serif",
  "Permanent Marker": "cursive",
  "Press Start 2P": "cursive",
  Staatliches: "cursive",
  Tangerine: "cursive",
  Teko: "sans-serif",
  Ubuntu: "sans-serif",
  "Yanone Kaffeesatz": "sans-serif"
};

export default function FontPicker(props) {
  return (
    <Dropdown
      choices={Object.keys(fonts)}
      choicesStyles={Object.keys(fonts).map(font => ({
        fontFamily: `"${font}", ${fonts[font]}`
      }))}
      dropdownValue={props.selectedFont}
      setDropdownValue={props.onFontSelect}
      selectedChoiceClassName={props.fontStyleClassName}
    />
  );
}

FontPicker.defaultProps = {
  label: "Header Font",
  fontStyleClassName: "header-layout-font",
  selectedFont: "Lato",
  onFontSelect: () => {}
};
