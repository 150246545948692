import {
  REQUEST_SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE
} from "./types";
import { writeFeedback } from "../db/FeedbackFacade";
import { hideModal } from "./modalActions";

/*
 * action that handles writing feedback to firestore
 * action is kicked off when the user clicks "Provide Feedback!" in the sidebar and fills out the form.
 */
export function sendFeedback({ uid, sentimentScore, title, body }) {
  return dispatch => {
    dispatch({ type: REQUEST_SEND_FEEDBACK });
    writeFeedback({ uid, sentimentScore, title, body })
      .then(() => {
        dispatch({ type: SEND_FEEDBACK_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: SEND_FEEDBACK_FAILURE });
      });
    dispatch(hideModal());
  };
}
