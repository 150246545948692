import React, { useState } from "react";
import { FaArrowsAlt, FaTimes, FaEdit } from "react-icons/fa";
import classNames from "classnames";
import "./widget.scss";
import { editableWidgetTypes } from "./widgetUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  removeWidgetFromLayout,
  editWidget
} from "../../actions/layoutActions";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

export default function Widget(props) {
  const { isLayoutLocked, editWidgetIdentifiers } = useSelector(
    state => state.layouts
  );
  const dispatch = useDispatch();

  const [dragClass, setDragClass] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const onRemoveClick = () => {
    recordEvent(
      `Delete ${props.widgetType}`,
      eventCategories.COMPONENT_CONFIGURATION,
      `Delete ${props.widgetType}`
    );
    dispatch(removeWidgetFromLayout(props.widget_id));
  };

  const onEditClick = () => {
    recordEvent(
      `Edit ${props.widgetType}`,
      eventCategories.COMPONENT_CONFIGURATION,
      `Edit ${props.widgetType}`
    );
    dispatch(editWidget(props.widget_id));
  };

  const onMouseDown = () => {
    setDragClass("grabbing");
  };

  const onMouseUp = () => {
    setDragClass("");
  };

  const isEditMode = editWidgetIdentifiers.includes(props.widget_id);

  const widgetHeader = isLayoutLocked ? null : (
    <div className="widget-header">
      <div
        className={classNames("draggable-handle", dragClass)}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <FaArrowsAlt
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          className={dragClass}
        />
      </div>
      <div className="remove-widget-icon">
        {editableWidgetTypes.includes(props.widgetType) && !isEditMode ? (
          <FaEdit onClick={onEditClick} />
        ) : null}
      </div>
      <div className="remove-widget-icon">
        <FaTimes
          onClick={onRemoveClick}
          aria-label="Remove Widget From Layout"
        />
      </div>
    </div>
  );

  const bodyClasses = classNames(
    "widget-body",
    isLayoutLocked ? "locked" : "",
    "body_".concat(props.widgetType)
  );
  const wrapperClasses = classNames(
    "wrapper",
    "wrapper_".concat(props.widgetType),
    !isLayoutLocked ? "edit-mode" : ""
  );

  return (
    <div className={wrapperClasses}>
      <div
        className="widget-container"
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-testid="widget_container"
      >
        {isHovered ? widgetHeader : null}
        <div className={bodyClasses} id={props.widget_id}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
