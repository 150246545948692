import React from "react";
import { FaTasks, FaMoneyBillAlt, FaRss } from "react-icons/fa";
import "./Card.scss";

export default function Card(props) {
  const getIcon = () => {
    switch (props.icon) {
      case "PRODUCTIVITY":
        return (
          <FaTasks
            className="card-icon"
            size={30}
            aria-label="Productivity Tools icon"
          />
        );
      case "MONEY":
        return (
          <FaMoneyBillAlt
            className="card-icon"
            size={30}
            aria-label="Finance icon"
          />
        );
      case "NEWS":
        return <FaRss className="card-icon" size={30} aria-label="News icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="card">
      {getIcon()}
      <h1>{props.title}</h1>
      {props.children}
    </div>
  );
}

Card.defaultProps = {
  icon: null,
  title: "",
  body: ""
};
