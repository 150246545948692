/*
 * Actions are grouped by their conceptual object. Generally, actions respond to user input
 * including (but not limited to): clicks, hovers, page loads, keyboard events.
 */

// AUTHENTICATION
export const REQUEST_LOGIN_EMAIL_PASSWORD = "REQUEST_LOGIN_EMAIL_PASSWORD";
export const REQUEST_LOGIN_GOOGLE = "REQUEST_LOGIN_GOOGLE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REQUEST_REGISTER_EMAIL_PASSWORD =
  "REQUEST_REGISTER_EMAIL_PASSWORD";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const USER_UNAUTHENTICATED = "USER_UNAUTHENTICATED";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const PASSWORD_RESET_EMAIL_SENT = "PASSWORD_RESET_EMAIL_SENT";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const REQUEST_DELETE_ACCOUNT = "REQUEST_DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

// MODAL
export const IS_MODAL_VISIBLE = "IS_MODAL_VISIBLE";

// TASKS
export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAILURE = "ADD_TASK_FAILURE";
export const UPDATE_COMPLETION_STATUS = "COMPLETE_TASK";
export const UPDATE_COMPLETION_STATUS_SUCCESS = "COMPLETE_TASK_SUCCESS";
export const UPDATE_COMPLETION_STATUS_FAILURE = "COMPLETE_TASK_FAILURE";
export const UPDATE_TASK_NAME = "UPDATE_TASK_NAME";
export const UPDATE_TASK_NAME_SUCCESS = "UPDATE_TASK_NAME_SUCCESS";
export const UPDATE_TASK_NAME_FAILURE = "UPDATE_TASK_NAME_FAILURE";
export const FILTER_TASKS = "FILTER_TASKS";
export const REQUEST_TASKS = "REQUEST_TASKS";
export const REQUEST_TASKS_SUCCESS = "REQUEST_TASKS_SUCCESS";
export const REQUEST_TASKS_FAILURE = "REQUEST_TASKS_FAILURE";
export const CLEAR_TASKS = "CLEAR_TASKS";
export const ARCHIVE_TASK = "ARCHIVE_TASK";
export const ARCHIVE_TASK_SUCCESS = "ARCHIVE_TASK_SUCCESS";
export const ARCHIVE_TASK_FAILURE = "ARCHIVE_TASK_FAILURE";
export const UNARCHIVE_TASK = "UNARCHIVE_TASK";
export const UNARCHIVE_TASK_SUCCESS = "UNARCHIVE_TASK_SUCCESS";
export const UNARCHIVE_TASK_FAILURE = "UNARCHIVE_TASK_FAILURE";

// LAYOUT
export const REQUEST_SAVE_LAYOUT = "REQUEST_SAVE_LAYOUT";
export const SAVE_LAYOUT_SUCCESS = "SAVE_LAYOUT_SUCCESS";
export const SAVE_LAYOUT_FAILURE = "SAVE_LAYOUT_FAILURE";
export const STORE_LAYOUT_LOCALLY = "STORE_LAYOUT_LOCALLY";
export const REQUEST_GET_LAYOUT = "REQUEST_GET_LAYOUT";
export const GET_LAYOUT_SUCCESS = "GET_LAYOUT_SUCCESS";
export const GET_LAYOUT_FAILURE = "GET_LAYOUT_FAILURE";
export const CLEAR_LAYOUT = "CLEAR_LAYOUT";
export const ADD_WIDGET_TO_LAYOUT = "ADD_WIDGET_TO_LAYOUT";
export const REMOVE_WIDGET_FROM_LAYOUT = "REMOVE_WIDGET_FROM_LAYOUT";
export const EDIT_WIDGET = "EDIT_WIDGET";
export const SAVE_WIDGET = "SAVE_WIDGET";
export const LOCK_LAYOUT = "LOCK_LAYOUT";
export const UNLOCK_LAYOUT = "UNLOCK_LAYOUT";
export const CANCEL_EDIT_WIDGET = "CANCEL_EDIT_WIDGET";
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const ADD_BOOKMARK_LIST = "ADD_BOOKMARK_LIST";
export const ADD_TICKER_BANNER = "ADD_TICKER_BANNER";
export const ADD_MARKET_OVERVIEW = "ADD_MARKET_OVERVIEW";
export const ADD_CLOCK = "ADD_CLOCK";
export const ADD_ICON_BOOKMARK = "ADD_ICON_BOOKMARK";
export const ADD_RICH_TEXT = "ADD_RICH_TEXT";

// USER SETTINGS
export const SAVE_NUM_COLUMNS = "SAVE_NUM_COLUMNS";
export const SAVE_BACKGROUND_URL = "SAVE_BACKGROUND_URL";
export const REQUEST_SAVE_SETTINGS = "REQUEST_SAVE_SETTINGS";
export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_FAILURE = "SAVE_SETTINGS_FAILURE";
export const REQUEST_GET_SETTINGS = "REQUEST_GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "GET_SETTINGS_FAILURE";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";

// USER
export const REQUEST_STORE_USER = "REQUEST_STORE_USER";
export const CLEAR_USER = "CLEAR_USER";
export const REQUEST_SAVE_CUSTOMER_ID = "REQUEST_SAVE_CUSTOMER_ID";
export const SAVE_CUSTOMER_ID_SUCCESS = "SAVE_CUSTOMER_ID_SUCCESS";
export const SAVE_CUSTOMER_ID_FAILURE = "SAVE_CUSTOMER_ID_FAILURE";
export const STORE_CHOSEN_PLAN = "STORE_CHOSEN_PLAN";
export const REQUEST_SAVE_SUBSCRIPTION = "REQUEST_SAVE_SUBSCRIPTION";
export const SAVE_SUBSCRIPTION_SUCCESS = "SAVE_SUBSCRIPTION_SUCCESS";
export const SAVE_SUBSCRIPTION_FAILURE = "SAVE_SUBSCRIPTION_FAILURE";
export const REQUEST_SAVE_FIRST_LOGIN = "REQUEST_SAVE_FIRST_LOGIN";
export const SAVE_FIRST_LOGIN_SUCCESS = "SAVE_FIRST_LOGIN_SUCCESS";
export const SAVE_FIRST_LOGIN_FAILURE = "SAVE_FIRST_LOGIN_FAILURE";
export const REQUEST_GET_USER_DETAILS = "REQUEST_GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const REQUEST_SAVE_CANCELLATION = "REQUEST_SAVE_CANCELLATION";
export const SAVE_CANCELLATION_SUCCESS = "SAVE_CANCELLATION_SUCCESS";
export const SAVE_CANCELLATION_FAILURE = "SAVE_CANCELLATION_FAILURE";

// GLOBAL
export const TOGGLE_DIRTY_FORM_ON = "TOGGLE_DIRTY_FORM_ON";
export const TOGGLE_DIRTY_FORM_OFF = "TOGGLE_DIRTY_FORM_OFF";
export const SHOW_BANNER = "SHOW_BANNER";
export const HIDE_BANNER = "HIDE_BANNER";
export const SHOW_MOBILE_MENU = "SHOW_MOBILE_MENU";
export const HIDE_MOBILE_MENU = "HIDE_MOBILE_MENU";
export const SET_LAST_EDIT_TIME = "SET_LAST_EDIT_TIME";
export const SHOW_SAVE_ALERT = "SHOW_SAVE_ALERT";
export const HIDE_SAVE_ALERT = "HIDE_SAVE_ALERT";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_EXPERIMENTAL_MODE = "SET_EXPERIMENTAL_MODE";

// FEEDBACK
export const REQUEST_SEND_FEEDBACK = "REQUEST_SEND_FEEDBACK";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";

// STYLES
export const SET_ACCENT_COLOR = "SET_ACCENT_COLOR";
export const SET_BASE_FONT_SIZE = "SET_BASE_FONT_SIZE";
export const SET_COMPONENT_BORDER_RADIUS = "SET_COMPONENT_BORDER_RADIUS";
export const SET_COMPONENT_BACKGROUND_COLOR = "SET_COMPONENT_BACKGROUND_COLOR";
export const SET_COMPONENT_BACKGROUND_OPACITY =
  "SET_COMPONENT_BACKGROUND_OPACITY";
export const REQUEST_SAVE_STYLES = "REQUEST_SAVE_STYLES";
export const SAVE_STYLES_SUCCESS = "SAVE_STYLES_SUCCESS";
export const SAVE_STYLES_FAILURE = "SAVE_STYLES_FAILURE";
export const REQUEST_GET_STYLES = "REQUEST_GET_STYLES";
export const GET_STYLES_SUCCESS = "GET_STYLES_SUCCESS";
export const GET_STYLES_FAILURE = "GET_STYLES_FAILURE";
export const RESET_STYLES = "RESET_STYLES";
export const CLEAR_STYLES = "CLEAR_STYLES";
export const SELECT_STYLE_TEMPLATE = "SELECT_STYLE_TEMPLATE";
export const REMOVE_STYLE_TEMPLATE = "REMOVE_STYLE_TEMPLATE";
export const SET_HEADER_FONT_FAMILY = "SET_HEADER_FONT_FAMILY";
export const SET_BODY_FONT_FAMILY = "SET_BODY_FONT_FAMILY";
