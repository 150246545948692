import React, { useLayoutEffect } from "react";
import { useTemporaryRef } from "../../custom-hooks/useTemporaryRef";
import Button, { buttonStyles } from "../button/button";
import "./ButtonFooterLayout.scss";

export default function ButtonFooterLayout(props) {
  const primaryButtonRef = useTemporaryRef(props.primaryButtonRef);
  const secondaryButtonRef = useTemporaryRef(props.secondaryButtonRef);

  /*
   * Focuses the cancel button as soon as the DOM paints
   */
  useLayoutEffect(() => {
    if (secondaryButtonRef && secondaryButtonRef.current) {
      secondaryButtonRef.current.focus();
    }
  }, [secondaryButtonRef]);

  return (
    <div className="button-footer-layout">
      <Button
        buttonStyle={buttonStyles.SECONDARY_BUTTON}
        reference={secondaryButtonRef}
        onClick={props.onSecondaryButtonClick}
      >
        {props.secondaryButtonText}
      </Button>
      <Button
        buttonStyle={buttonStyles.OPAQUE_BUTTON}
        onClick={props.onPrimaryButtonClick}
        reference={primaryButtonRef}
        className={"primary"}
      >
        {props.primaryButtonText}
      </Button>
    </div>
  );
}

ButtonFooterLayout.defaultProps = {
  onPrimaryButtonClick: () => {},
  onSecondaryButtonClick: () => {},
  primaryButtonText: "",
  secondaryButtonText: "",
  primaryButtonRef: null,
  secondaryButtonRef: null
};
