import {
  ADD_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  UPDATE_COMPLETION_STATUS,
  UPDATE_COMPLETION_STATUS_SUCCESS,
  UPDATE_COMPLETION_STATUS_FAILURE,
  FILTER_TASKS,
  REQUEST_TASKS,
  REQUEST_TASKS_SUCCESS,
  REQUEST_TASKS_FAILURE,
  CLEAR_TASKS,
  UPDATE_TASK_NAME,
  UPDATE_TASK_NAME_SUCCESS,
  UPDATE_TASK_NAME_FAILURE,
  ARCHIVE_TASK,
  ARCHIVE_TASK_SUCCESS,
  ARCHIVE_TASK_FAILURE,
  UNARCHIVE_TASK,
  UNARCHIVE_TASK_SUCCESS,
  UNARCHIVE_TASK_FAILURE
} from "./types";
import {
  writeTask,
  getTasksForUser,
  updateTaskCompletion,
  updateTaskNameForTask,
  updateArchiveStatusForTask
} from "../db/TaskFacade";
import { writeError } from "../db/ErrorFacade";

export function addTask(task) {
  return dispatch => {
    dispatch({ type: ADD_TASK });

    writeTask(task)
      .then(docRef => {
        task.id = docRef.id;
        dispatch({ type: ADD_TASK_SUCCESS, task });
      })
      .catch(err => {
        dispatch({ type: ADD_TASK_FAILURE });
      });
  };
}

export function getTasks(uid) {
  return dispatch => {
    dispatch({ type: REQUEST_TASKS });

    getTasksForUser(uid)
      .then(result => {
        const taskList = [];
        result.forEach(doc => {
          const task = doc.data();
          task.id = doc.id;
          taskList.push(task);
        });
        dispatch({ type: REQUEST_TASKS_SUCCESS, taskList });
      })
      .catch(err => {
        dispatch({ type: REQUEST_TASKS_FAILURE });
      });
  };
}

export function clearTasks() {
  return {
    type: CLEAR_TASKS
  };
}

export function updateTaskCompletionStatus(taskId, isCompleted) {
  return dispatch => {
    dispatch({ type: UPDATE_COMPLETION_STATUS });
    updateTaskCompletion(taskId, isCompleted)
      .then(() => {
        dispatch({
          type: UPDATE_COMPLETION_STATUS_SUCCESS,
          taskId,
          isCompleted
        });
      })
      .catch(err => {
        dispatch({ type: UPDATE_COMPLETION_STATUS_FAILURE });
      });
  };
}

export function updateTaskName(taskId, taskName) {
  return dispatch => {
    dispatch({ type: UPDATE_TASK_NAME });
    updateTaskNameForTask(taskId, taskName)
      .then(() => {
        dispatch({ type: UPDATE_TASK_NAME_SUCCESS, taskId, taskName });
      })
      .catch(err => {
        dispatch({ type: UPDATE_TASK_NAME_FAILURE });
      });
  };
}

export function archiveTask(taskId, uid) {
  return dispatch => {
    dispatch({ type: ARCHIVE_TASK });
    updateArchiveStatusForTask(taskId, true)
      .then(() => {
        dispatch({ type: ARCHIVE_TASK_SUCCESS, taskId });
      })
      .catch(err => {
        dispatch({ type: ARCHIVE_TASK_FAILURE });
        writeError({
          uid,
          error: `Archiving task ${taskId} failed`,
          cause: err.toString()
        });
      });
  };
}

export function unarchiveTask(taskId, uid) {
  return dispatch => {
    dispatch({ type: UNARCHIVE_TASK });
    updateArchiveStatusForTask(taskId, false)
      .then(() => {
        dispatch({ type: UNARCHIVE_TASK_SUCCESS, taskId });
      })
      .catch(err => {
        dispatch({ type: UNARCHIVE_TASK_FAILURE });
        writeError({
          uid,
          error: `Archiving task ${taskId} failed`,
          cause: err.toString()
        });
      });
  };
}

export function filterTasks(isCompleted) {
  return {
    type: FILTER_TASKS,
    isCompletedFilter: isCompleted
  };
}
