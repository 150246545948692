import { connect } from "react-redux";
import { saveWidget, addBookmarkList } from "../../actions/layoutActions";
import BookmarkList from "./BookmarkList";

const mapStateToProps = state => {
  const { isLoggedIn } = state.login;
  const { bookmarkLists, editWidgetIdentifiers } = state.layouts;
  return {
    isLoggedIn,
    bookmarkLists,
    editWidgetIdentifiers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: ({ widget_id, category, bookmarks }) => {
      dispatch(saveWidget(widget_id));
      dispatch(addBookmarkList({ widget_id, category, bookmarks }));
    }
  };
};

const BookmarkListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookmarkList);

export default BookmarkListContainer;
