import {
  REQUEST_SAVE_LAYOUT,
  REQUEST_GET_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  SAVE_LAYOUT_FAILURE,
  GET_LAYOUT_SUCCESS,
  GET_LAYOUT_FAILURE,
  CLEAR_LAYOUT,
  ADD_WIDGET_TO_LAYOUT,
  REMOVE_WIDGET_FROM_LAYOUT,
  EDIT_WIDGET,
  REQUEST_SAVE_SETTINGS,
  SAVE_SETTINGS_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  UNLOCK_LAYOUT,
  LOCK_LAYOUT,
  SAVE_WIDGET,
  STORE_LAYOUT_LOCALLY,
  ADD_BOOKMARK,
  ADD_BOOKMARK_LIST,
  SAVE_NUM_COLUMNS,
  SAVE_BACKGROUND_URL,
  ADD_TICKER_BANNER,
  CANCEL_EDIT_WIDGET,
  ADD_MARKET_OVERVIEW,
  ADD_CLOCK,
  ADD_ICON_BOOKMARK,
  ADD_RICH_TEXT
} from "../actions/types";
import {
  generateNewWidget,
  editableWidgetTypes,
  WELCOME_MESSAGE,
  CLOCK,
  baseConfigs,
  WELCOME_MESSAGE_MIN_W,
  WELCOME_MESSAGE_MIN_H,
  CLOCK_MIN_W,
  CLOCK_MIN_H
} from "../components/widget/widgetUtils";
import background from "../resources/background-photo.jpeg";

const welcomeUuid = "base_welcome_a_1";
const clockUuid = "base_clock_a_1";

/*
 * The initial layout should contain a welcome message and a clock by default,
 * hence those components are defined here.
 */
export const initialLayoutState = {
  id: null,
  widgetList: [
    {
      widgetType: WELCOME_MESSAGE,
      widget_id: welcomeUuid
    },
    {
      widgetType: CLOCK,
      widget_id: clockUuid
    }
  ],
  clocks: [
    {
      widget_id: clockUuid,
      showSeconds: false
    }
  ],
  layout: [
    {
      ...baseConfigs,
      i: welcomeUuid,
      y: 13,
      h: 3,
      w: 50,
      x: 26,
      minH: WELCOME_MESSAGE_MIN_H,
      minW: WELCOME_MESSAGE_MIN_W
    },
    {
      ...baseConfigs,
      i: clockUuid,
      y: 7,
      h: 6,
      w: 50,
      x: 26,
      minH: CLOCK_MIN_H,
      minW: CLOCK_MIN_W
    }
  ],
  numberOfColumns: 100,
  isLayoutLocked: true,
  backgroundUrl: background,
  editWidgetIdentifiers: [],
  bookmarks: [],
  bookmarkLists: [],
  iconBookmarks: [],
  tickerBanners: [],
  marketOverviews: [],
  richTexts: []
};

/*
 * Responds to layout actions and updates the appropriate state
 */
export function handleLayoutActions(state, action) {
  if (typeof state === "undefined") {
    return initialLayoutState;
  }
  switch (action.type) {
    case SAVE_LAYOUT_SUCCESS: {
      return Object.assign({}, state, {
        id: action.id,
        editWidgetIdentifiers: []
      });
    }
    case GET_LAYOUT_SUCCESS: {
      const numberOfColumns = action.numberOfColumns || state.numberOfColumns;
      const backgroundUrl = action.backgroundUrl || state.backgroundUrl;
      const tickerBanners = action.tickerBanners || state.tickerBanners;
      const iconBookmarks = action.iconBookmarks || state.iconBookmarks;
      const richTexts = action.richTexts || state.richTexts;
      return Object.assign({}, state, {
        id: action.id,
        widgetList: action.widgetList,
        layout: action.layout,
        numberOfColumns,
        backgroundUrl,
        bookmarks: action.bookmarks,
        bookmarkLists: action.bookmarkLists,
        iconBookmarks,
        editWidgetIdentifiers: [],
        tickerBanners,
        richTexts
      });
    }
    case STORE_LAYOUT_LOCALLY: {
      return Object.assign({}, state, {
        widgetList: action.widgetList,
        layout: action.layout
      });
    }
    case CLEAR_LAYOUT: {
      return Object.assign({}, state, initialLayoutState);
    }
    case ADD_WIDGET_TO_LAYOUT: {
      const newLayout = generateNewWidget(
        action.widgetType,
        state.layout,
        state.numberOfColumns
      );
      const editWidgetIdentifiersCopy = state.editWidgetIdentifiers
        ? state.editWidgetIdentifiers.slice()
        : [];
      if (editableWidgetTypes.includes(action.widgetType)) {
        editWidgetIdentifiersCopy.push(newLayout.i);
      }
      return Object.assign({}, state, {
        widgetList: [
          ...state.widgetList,
          {
            widgetType: action.widgetType,
            widget_id: newLayout.i
          }
        ],
        layout: [...state.layout, newLayout],
        editWidgetIdentifiers: editWidgetIdentifiersCopy
      });
    }
    case REMOVE_WIDGET_FROM_LAYOUT: {
      const { widget_id } = action;
      return Object.assign({}, state, {
        widgetList: state.widgetList
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        layout: state.layout.slice().filter(elem => elem.i !== widget_id),
        tickerBanners: state.tickerBanners
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        marketOverviews: state.marketOverviews
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        bookmarks: state.bookmarks
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        bookmarkLists: state.bookmarkLists
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        iconBookmarks: state.iconBookmarks
          .slice()
          .filter(elem => elem.widget_id !== widget_id),
        editWidgetIdentifiers: state.editWidgetIdentifiers
          .slice()
          .filter(identifier => identifier !== widget_id),
        clocks:
          state.clocks?.slice().filter(elem => elem.widget_id !== widget_id) ||
          [],
        richTexts:
          state.richTexts
            ?.slice()
            .filter(elem => elem.widget_id !== widget_id) || []
      });
    }
    case SAVE_SETTINGS_SUCCESS: {
      return Object.assign({}, state, {
        numberOfColumns: action.numberOfColumns,
        backgroundUrl: action.backgroundUrl
      });
    }
    case EDIT_WIDGET: {
      const editWidgetIdentifiersCopy = state.editWidgetIdentifiers
        ? state.editWidgetIdentifiers.slice()
        : [];
      editWidgetIdentifiersCopy.push(action.widget_id);
      return Object.assign({}, state, {
        editWidgetIdentifiers: editWidgetIdentifiersCopy
      });
    }
    case SAVE_WIDGET: {
      return Object.assign({}, state, {
        // editWidgetIdentifiers: []
        editWidgetIdentifiers: state.editWidgetIdentifiers
          .slice()
          .filter(identifier => identifier !== action.widget_id)
      });
    }
    case LOCK_LAYOUT: {
      return Object.assign({}, state, {
        isLayoutLocked: true
      });
    }
    case UNLOCK_LAYOUT: {
      return Object.assign({}, state, {
        isLayoutLocked: false
      });
    }
    case ADD_BOOKMARK: {
      const bookmarks = state.bookmarks ? state.bookmarks.slice() : [];
      const bookmark = {
        widget_id: action.widget_id,
        url: action.url,
        name: action.name
      };
      addOrReplaceItem(bookmarks, bookmark, action);
      return Object.assign({}, state, {
        bookmarks
      });
    }
    case ADD_BOOKMARK_LIST: {
      const bookmarkLists = state.bookmarkLists
        ? state.bookmarkLists.slice()
        : [];
      const bookmarkList = {
        widget_id: action.widget_id,
        category: action.category,
        bookmarks: action.bookmarks
      };
      addOrReplaceItem(bookmarkLists, bookmarkList, action);
      return Object.assign({}, state, {
        bookmarkLists
      });
    }
    case ADD_ICON_BOOKMARK: {
      const iconBookmarks = state.iconBookmarks
        ? state.iconBookmarks.slice()
        : [];
      const iconBookmark = {
        widget_id: action.widget_id,
        url: action.url,
        icon: action.icon
      };
      addOrReplaceItem(iconBookmarks, iconBookmark, action);
      return Object.assign({}, state, {
        iconBookmarks
      });
    }
    case ADD_TICKER_BANNER: {
      const tickerBanners = state.tickerBanners
        ? state.tickerBanners.slice()
        : [];
      const tickerBanner = {
        widget_id: action.widget_id,
        symbols: action.symbols
      };
      addOrReplaceItem(tickerBanners, tickerBanner, action);
      return Object.assign({}, state, {
        tickerBanners
      });
    }
    case ADD_MARKET_OVERVIEW: {
      const marketOverviews = state.marketOverviews
        ? state.marketOverviews.slice()
        : [];
      const marketOverview = {
        widget_id: action.widget_id,
        symbols: action.symbols,
        showIndices: action.showIndices,
        showCrypto: action.showCrypto
      };
      addOrReplaceItem(marketOverviews, marketOverview, action);
      return Object.assign({}, state, {
        marketOverviews
      });
    }
    case ADD_CLOCK: {
      const clocks = state.clocks ? state.clocks.slice() : [];
      const {
        widget_id,
        timeFormat,
        clockSize,
        showSeconds,
        alignment,
        color
      } = action;
      const clock = {
        widget_id,
        timeFormat,
        clockSize,
        showSeconds,
        alignment,
        color
      };
      addOrReplaceItem(clocks, clock, action);
      return Object.assign({}, state, {
        clocks
      });
    }
    case SAVE_NUM_COLUMNS: {
      return Object.assign({}, state, {
        numberOfColumns: action.numberOfColumns
      });
    }
    case SAVE_BACKGROUND_URL: {
      return Object.assign({}, state, {
        backgroundUrl: action.backgroundUrl
      });
    }
    case CANCEL_EDIT_WIDGET: {
      return Object.assign({}, state, {
        editWidgetIdentifiers: []
      });
    }
    case ADD_RICH_TEXT: {
      const richTexts = state.richTexts ? state.richTexts.slice() : [];
      const { widget_id, delta } = action;
      const richTextComponent = {
        widget_id,
        delta
      };
      addOrReplaceItem(richTexts, richTextComponent, action);
      return Object.assign({}, state, {
        richTexts
      });
    }
    case REQUEST_SAVE_LAYOUT:
    case REQUEST_SAVE_SETTINGS:
    case SAVE_SETTINGS_FAILURE:
    case GET_LAYOUT_FAILURE:
    case SAVE_LAYOUT_FAILURE:
    case REQUEST_GET_LAYOUT:
    default:
      return state;
  }
}

function addOrReplaceItem(array, newItem, action) {
  const index = array.findIndex(item => item.widget_id === action.widget_id);
  if (index === -1) {
    array.push(newItem);
  } else {
    array[index] = newItem;
  }
}
