import React from "react";
import { FaStar } from "react-icons/fa";
import Tooltip from "../tooltip/tooltip";
import "./PremiumIcon.scss";

export default function PremiumIcon() {
  return (
    <Tooltip
      tooltipMessage={"Premium Feature"}
      placement={"right"}
      referenceStyle={{ display: "inline-block" }}
    >
      <FaStar
        aria-label="Premium Feature"
        className="premium-feature-icon"
        style={{ stroke: "black", strokeWidth: "10" }}
      />
    </Tooltip>
  );
}
