import React, { useState, Fragment, useRef } from "react";
import "./UserMenu.scss";
import classNames from "classnames";
import { useClickOutside } from "../../../custom-hooks/useClickOutside";
import { handleEnterKeypress } from "../../../utils/keyEvents";
import A from "../../elements/A";
import {
  FaCubes,
  FaHome,
  FaCommentDots,
  FaStar,
  FaRegObjectUngroup,
  FaSignOutAlt,
  FaUserSlash,
  FaRegStopCircle,
  FaBars,
  FaTimes
} from "react-icons/fa";
import {
  eventCategories,
  recordEvent
} from "../../../utils/googleAnalyticsEvents";
import { useDispatch, useSelector } from "react-redux";
import { PREMIUM } from "../../../utils/stripeUtils";
import { showModal } from "../../../actions/modalActions";
import { CANCEL_PREMIUM } from "../../modal/modalTypes";

export default function UserMenu(props) {
  const [isVisible, setIsVisible] = useState(false);
  const myRef = useRef(null);

  const { planType } = useSelector(state => state.userDetails);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    if (!isVisible) {
      recordEvent(
        "User Menu Opened",
        eventCategories.GENERAL_APP_ACTION,
        "User Menu Opened"
      );
    } else {
      recordEvent(
        "User Menu Closed",
        eventCategories.GENERAL_APP_ACTION,
        "User Menu Closed"
      );
    }
    setIsVisible(!isVisible);
  };

  useClickOutside(myRef, setIsVisible);

  const onCancelPremiumClick = () => {
    dispatch(showModal(CANCEL_PREMIUM));
  };

  const onUserImageKeyPress = event =>
    handleEnterKeypress(event, toggleDropdown);
  const onLogoutKeyPress = event =>
    handleEnterKeypress(event, props.onLogoutClick);
  const onDeleteAccountKeyPress = event =>
    handleEnterKeypress(event, props.onDeleteAccountClick);
  const onCancelPremiumKeypress = event =>
    handleEnterKeypress(event, onCancelPremiumClick);

  const userMenu = (
    <Fragment>
      <div className="up-arrow" />
      <div className="user-menu">
        <div className="user-menu-choices">
          <A
            href="/app"
            target="_self"
            className={classNames(
              "user-menu-choice",
              props.activePage === "app" && "active"
            )}
          >
            <FaCubes />
            Dashboard
          </A>
          <div className="user-menu-divider" />
          {planType !== PREMIUM ? (
            <A
              href="/premium"
              target="_self"
              className={classNames(
                "user-menu-choice",
                props.activePage === "premium" && "active"
              )}
            >
              <FaStar />
              Go Premium!
            </A>
          ) : null}
          <A
            href="/"
            target="_self"
            className={classNames(
              "user-menu-choice",
              props.activePage === "/" && "active"
            )}
          >
            <FaHome />
            Home
          </A>
          <A
            href="/contact"
            target="_self"
            className={classNames(
              "user-menu-choice",
              props.activePage === "contact" && "active"
            )}
          >
            <FaCommentDots />
            Contact
          </A>
          <A
            href="https://chrome.google.com/webstore/detail/dashboi/mkelkeddjgnodlegojbalneclineanof"
            className="user-menu-choice"
          >
            <FaRegObjectUngroup />
            Chrome Extension
          </A>
          <div
            onClick={props.onLogoutClick}
            className="user-menu-choice"
            tabIndex={0}
            onKeyPress={onLogoutKeyPress}
          >
            <FaSignOutAlt />
            Logout
          </div>
          <div className="user-menu-divider" />
          {planType === PREMIUM ? (
            <div
              onClick={onCancelPremiumClick}
              className="user-menu-choice"
              tabIndex={0}
              onKeyPress={onCancelPremiumKeypress}
            >
              <FaRegStopCircle />
              Cancel Premium
            </div>
          ) : null}
          <div
            onClick={props.onDeleteAccountClick}
            className="user-menu-choice"
            tabIndex={0}
            onKeyPress={onDeleteAccountKeyPress}
          >
            <FaUserSlash />
            Delete Account
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <div
      tabIndex={0}
      className="avatar-wrapper"
      ref={myRef}
      onKeyPress={onUserImageKeyPress}
    >
      {props.photoUrl ? (
        <img
          alt="user avatar"
          src={props.photoUrl}
          className={classNames(
            "user-avatar",
            props.shouldHaveLeftMargin ? "showing-actions" : ""
          )}
          onClick={toggleDropdown}
        />
      ) : (
        <div
          className={classNames(
            "user-avatar",
            "no-photo",
            props.shouldHaveLeftMargin ? "showing-actions" : ""
          )}
          onClick={toggleDropdown}
        >
          {isVisible ? (
            <FaTimes aria-label="Close User Menu" />
          ) : (
            <FaBars aria-label="Open User Menu" />
          )}
        </div>
      )}
      {isVisible ? userMenu : null}
    </div>
  );
}

UserMenu.defaultProps = {
  activePage: "app",
  shouldHaveLeftMargin: true,
  onDeleteAccountClick: () => {},
  onLogoutClick: () => {}
};
