import React, { Component } from "react";
import RGL from "react-grid-layout";
import RssFeed from "../rssFeed/rssFeed";
import WidgetContainer from "../widget/widgetContainer";
import Clock from "../clock/Clock";
import {
  BOOKMARK,
  CLOCK,
  TASK_LIST,
  GOOGLE_SEARCH,
  BOOKMARK_LIST,
  CNN_WORLD,
  ESPN_COLLEGE_BASKETBALL,
  ESPN_COLLEGE_FOOTBALL,
  ESPN_MLB,
  ESPN_NBA,
  ESPN_NFL,
  ESPN_NHL,
  ESPN_TOP,
  FOX_NATIONAL,
  FOX_LATEST,
  FOX_WORLD,
  FOX_POLITICS,
  FOX_BUSINESS,
  FOX_SCI_TECH,
  FOX_HEALTH,
  FOX_ENTERTAINMENT,
  FOX_VIEWS,
  GAMASUTRA_FEED,
  GUARDIAN_WORLD,
  GUARDIAN_US,
  GUARDIAN_UK,
  GUARDIAN_UK_LIFESTYLE,
  GUARDIAN_SCIENCE,
  GUARDIAN_US_LIFESTYLE,
  GUARDIAN_FASHION,
  GUARDIAN_US_SPORTS,
  GUARDIAN_UK_SPORTS,
  CNN_TRAVEL,
  CNN_ENTERTAINMENT,
  CNN_HEALTH,
  CNN_POLITICS,
  CNN_TECHNOLOGY,
  CNN_TOP_STORIES,
  CNN_US,
  NYT_WORLD,
  NYT_US,
  NYT_BUSINESS,
  NYT_TECHNOLOGY,
  NYT_SPORTS,
  NYT_JOBS,
  NYT_ENVIRONMENT,
  NYT_SPACE,
  NYT_ARTS,
  WAPO_FACT_CHECKER,
  WAPO_LEADERSHIP,
  WAPO_LIFESTYLE,
  WAPO_MONKEY_CAGE,
  WAPO_NATIONAL,
  WAPO_OPINIONS,
  WAPO_POLITICS,
  WAPO_WORLD,
  TICKER_BANNER,
  MARKET_OVERVIEW,
  WELCOME_MESSAGE,
  ICON_BOOKMARK,
  RICH_TEXT
} from "../widget/widgetUtils";
import classNames from "classnames";
import "./ReactGridLayoutWrapper.scss";
import GoogleSearchWidget from "../googleSearch/googleSearchWidget";
import BookmarkListContainer from "../bookmarkList/BookmarkListContainer";
import TickerBanner from "../finance/Tickers/TickerBanner";
import MarketOverview from "../finance/marketOverview/MarketOverview";
import WelcomeMessage from "../welcomeMessage/WelcomeMessage";
import Bookmark from "../bookmark/Bookmark";
import TaskList from "../taskList/TaskList";
import IconBookmark from "../iconBookmark/IconBookmark";
import RichText from "../richText/RichText";

export default class ReactGridLayoutWrapper extends Component {
  static defaultProps = {
    widgetList: [],
    layout: []
  };

  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  getWidgetForFriendlyName = (widget, widget_id, index) => {
    switch (widget.widgetType) {
      case CNN_WORLD:
      case CNN_TRAVEL:
      case CNN_ENTERTAINMENT:
      case CNN_HEALTH:
      case CNN_POLITICS:
      case CNN_TECHNOLOGY:
      case CNN_TOP_STORIES:
      case CNN_US:
      case ESPN_COLLEGE_BASKETBALL:
      case ESPN_COLLEGE_FOOTBALL:
      case ESPN_MLB:
      case ESPN_NBA:
      case ESPN_NFL:
      case ESPN_NHL:
      case ESPN_TOP:
      case GUARDIAN_WORLD:
      case GUARDIAN_US:
      case GUARDIAN_UK:
      case GUARDIAN_UK_LIFESTYLE:
      case GUARDIAN_SCIENCE:
      case GUARDIAN_US_LIFESTYLE:
      case GUARDIAN_FASHION:
      case GUARDIAN_US_SPORTS:
      case GUARDIAN_UK_SPORTS:
      case FOX_NATIONAL:
      case FOX_LATEST:
      case FOX_WORLD:
      case FOX_POLITICS:
      case FOX_BUSINESS:
      case FOX_SCI_TECH:
      case FOX_HEALTH:
      case FOX_ENTERTAINMENT:
      case FOX_VIEWS:
      case NYT_WORLD:
      case NYT_US:
      case NYT_BUSINESS:
      case NYT_TECHNOLOGY:
      case NYT_SPORTS:
      case NYT_ENVIRONMENT:
      case NYT_SPACE:
      case NYT_JOBS:
      case NYT_ARTS:
      case GAMASUTRA_FEED:
      case WAPO_FACT_CHECKER:
      case WAPO_LEADERSHIP:
      case WAPO_LIFESTYLE:
      case WAPO_MONKEY_CAGE:
      case WAPO_NATIONAL:
      case WAPO_OPINIONS:
      case WAPO_POLITICS:
      case WAPO_WORLD:
        return <RssFeed feedType={widget.widgetType} widget_id={widget_id} />;
      case BOOKMARK:
        return <Bookmark widget_id={widget_id} />;
      case BOOKMARK_LIST:
        return <BookmarkListContainer widget_id={widget_id} />;
      case CLOCK:
        return <Clock widget_id={widget_id} />;
      case GOOGLE_SEARCH:
        return <GoogleSearchWidget />;
      case WELCOME_MESSAGE:
        return <WelcomeMessage />;
      case TICKER_BANNER:
        return <TickerBanner widget_id={widget_id} />;
      case MARKET_OVERVIEW:
        return <MarketOverview widget_id={widget_id} />;
      case ICON_BOOKMARK:
        return <IconBookmark widget_id={widget_id} />;
      case RICH_TEXT:
        return <RichText widget_id={widget_id} />;
      case TASK_LIST:
      default:
        return <TaskList widget_id={widget_id} />;
    }
  };

  generateDOM() {
    const { widgetList, layout } = this.props;
    return layout.map((l, i) => {
      return (
        <div
          key={l.i}
          className={classNames(l.static ? "static" : "")}
          data-grid={l}
        >
          <WidgetContainer
            widget_id={l.i}
            widgetType={widgetList[i].widgetType}
          >
            {this.getWidgetForFriendlyName(widgetList[i], l.i, i)}
          </WidgetContainer>
        </div>
      );
    });
  }

  render() {
    const {
      layout,
      isLayoutLocked,
      numberOfColumns,
      onLayoutChange
    } = this.props;
    return (
      <RGL
        width={this.state.width}
        draggableHandle=".draggable-handle"
        layout={layout}
        cols={numberOfColumns}
        onLayoutChange={onLayoutChange}
        compactType={null}
        preventCollision={true}
        isResizable={!isLayoutLocked}
        rowHeight={10}
        data-testid="react-grid-layout"
      >
        {this.generateDOM()}
      </RGL>
    );
  }
}
