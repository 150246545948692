import React from "react";
import InputLabel from "../input/InputLabel";
import classNames from "classnames";
import "./TextArea.scss";
import { useRequiredValidation } from "../../custom-hooks/useRequiredValidation";
import Validations from "../validations/Validations";

export default function TextArea(props) {
  const validate = useRequiredValidation(props.required, props.value);
  const allValidations = validate.validations.concat(props.validations);

  return (
    <div
      className={classNames(
        "paragraph-field",
        props.hasBottomPadding ? "has-bottom-padding" : ""
      )}
    >
      {props.label ? (
        <InputLabel
          label={props.label}
          helpText={props.helpText}
          helpTextPlacement={props.helpTextPlacement}
          isRequired={props.required}
        />
      ) : null}
      <textarea
        ref={props.reference}
        aria-label={props.ariaLabel}
        className={classNames(
          "text-area",
          props.className,
          allValidations.length > 0 ? "has-validation-error" : null
        )}
        name={props.name}
        value={props.value}
        onChange={event => {
          props.onChange(event);
          props.changeFn();
        }}
        onBlur={props.onBlur}
        onKeyDown={props.delayedChangeFn}
        onPaste={props.delayedChangeFn}
        onDrop={props.delayedChangeFn}
        onCut={props.delayedChangeFn}
        required={props.required}
        onInvalid={validate.onInvalid}
        placeholder={props.placeholder}
        style={props.additionalStyles}
        rows={props.rows}
      />
      <Validations validations={allValidations} />
    </div>
  );
}

TextArea.defaultProps = {
  ariaLabel: "text-area",
  className: "",
  name: "",
  value: "",
  onChange: () => {},
  onBlur: () => {},
  changeFn: () => {},
  label: "",
  helpText: "",
  helpTextPlacement: "",
  required: false,
  validations: [],
  placeholder: "",
  reference: () => {},
  additionalStyles: {},
  height: null,
  rows: 4,
  hasBottomPadding: true
};
