import { db } from "../utils/firebase-config";

export function getUser(uid) {
  return new Promise(function (resolve, reject) {
    db.collection("users")
      .doc(uid)
      .get()
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function saveUserFirstLogin(uid) {
  return new Promise(function (resolve, reject) {
    db.collection("users")
      .doc(uid)
      .set({
        firstLoginTime: new Date()
      })
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function saveCustomerId({ uid, customerId }) {
  return new Promise(function (resolve, reject) {
    db.collection("users")
      .doc(uid)
      .update({
        customerId
      })
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function saveUserPlan({ uid, planType }) {
  return new Promise(function (resolve, reject) {
    db.collection("users")
      .doc(uid)
      .update({
        planType
      })
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function saveSubscriptionId({ uid, subscriptionId, planType }) {
  try {
    db.collection("users")
      .doc(uid)
      .update({
        subscriptionId,
        planType
      })
      .then(results => {
        return results;
      })
      .catch(err => {
        throw err;
      });
  } catch (err) {
    throw err;
  }
}
