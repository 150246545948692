import { useEffect } from "react";

/*
 * Similar to useClickOutside, except this acheives the same functionality with portals,
 * which need separate handling because they are not contained within the parent element.
 */
export const useClickOutsidePortal = (
  fieldRef,
  poppedComponentRef,
  setIsVisible
) => {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  });

  const isNotContainedInRef = (event, ref) => {
    return (
      ref &&
      ref.current !== null &&
      ref.current !== undefined &&
      event.target !== ref.current &&
      !ref.current.contains(event.target)
    );
  };

  const handleClickOutside = event => {
    if (
      isNotContainedInRef(event, fieldRef) &&
      isNotContainedInRef(event, poppedComponentRef)
    ) {
      setIsVisible(false);
    }
  };
};
