import React, { Fragment } from "react";
import "./BookmarkList.scss";
import { addUrlPrefix } from "../../utils/urlValidations";
import H, { headerSizes } from "../elements/H";
import A from "../elements/A";

export default function BookmarkList(props) {
  const bookmarkList = props.bookmarkLists.find(
    bookmarkCategory => bookmarkCategory.widget_id === props.widget_id
  );
  return (
    <div className="bookmark-list-container">
      {bookmarkList ? (
        <Fragment>
          <div className="bookmark-list-category">
            <H
              size={headerSizes.EXTRA_SMALL}
              textAlign="center"
              className="header-layout-font"
              usePermanentAccent={false}
            >
              {bookmarkList.category}
            </H>
          </div>
          <div className="bookmarks">
            <ul>
              {bookmarkList.bookmarks.map((bookmark, index) => {
                return (
                  <li key={index}>
                    <A
                      href={addUrlPrefix(bookmark.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="body-layout-font"
                    >
                      {bookmark.name}
                    </A>
                  </li>
                );
              })}
            </ul>
          </div>
        </Fragment>
      ) : (
        "Bookmark List is currently in edit mode"
      )}
    </div>
  );
}

BookmarkList.defaultProps = {
  bookmarkLists: [],
  editWidgetIdentifiers: []
};
