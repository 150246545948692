import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import "./Dropdown.scss";
import { useInputWidth } from "../../custom-hooks/useInputWidth";
import classNames from "classnames";
import InputLabel from "../input/InputLabel";
import { useFocusedDropdownIndex } from "../../custom-hooks/useFocusedDropdownIndex";

export default function Dropdown(props) {
  const { inputWidth, inputRef, setInputRef: setDropdownRef } = useInputWidth();

  const [isVisible, setIsVisible] = useState(false);

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  const chooseDropdownValue = choice => {
    // setLocalDropdownValue(choice);
    props.setDropdownValue(choice);
    toggleDropdown();
  };

  const onEnterKeyDown = (event, focusedIndex) => {
    if (!isVisible) {
      toggleDropdown();
    } else {
      const selectedChoice = props.choices[focusedIndex];
      chooseDropdownValue(selectedChoice);
    }
  };

  const { focusedIndex, onKeyPress } = useFocusedDropdownIndex(
    inputRef,
    props.choices,
    onEnterKeyDown,
    () => setIsVisible(false)
  );

  const field = (
    <div
      className={classNames(
        "clickable-dropdown-input",
        props.selectedChoiceClassName
      )}
      onClick={toggleDropdown}
      onKeyDown={onKeyPress}
      style={props.dropdownValue ? { color: "black" } : { fontStyle: "italic" }}
      ref={setDropdownRef}
      tabIndex={0}
    >
      {props.dropdownValue || "--- Select ---"}
      <FaCaretDown
        className={classNames(
          "dropdown-caret",
          isVisible ? "dropdown-visible" : ""
        )}
        aria-label="Click to open dropdown"
      />
    </div>
  );

  const choices = (
    <div className="dropdown-choices" style={{ width: inputWidth + "px" }}>
      {props.choices.map((choice, index) => {
        return (
          <div
            className={classNames(
              "dropdown-choice",
              choice === props.dropdownValue ? "selected" : "",
              index === focusedIndex ? "focused" : null
            )}
            onClick={() => chooseDropdownValue(choice)}
            key={index}
            style={props.choicesStyles[index]}
          >
            {choice}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="dropdown-field">
      {props.label ? (
        <InputLabel
          label={props.label}
          helpText={props.helpText}
          additionalIconComponent={props.additionalIconComponent}
        />
      ) : null}
      {field}
      {isVisible ? choices : null}
    </div>
  );
}

Dropdown.defaultProps = {
  dropdownValue: null,
  dropdownPlaceholder: "Select...",
  setDropdownValue: () => {},
  label: "",
  additionalIconComponent: null,
  helpText: "",
  choices: [],
  selectedChoiceClassName: "",
  choicesStyles: []
};
