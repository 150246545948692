import React from "react";
import * as FontAwesome from "react-icons/fa";
import "./IconPicker.scss";

function IconList({ icons, setChosenIcon, setIsPickerVisible }) {
  return icons.map(icon => {
    const MyIcon = FontAwesome[icon];
    return (
      <div
        key={icon}
        className="icon-container"
        onClick={() => {
          setChosenIcon(icon);
          setIsPickerVisible(false);
        }}
        aria-label={`${icon} Icon`}
      >
        <MyIcon className="icon" />
      </div>
    );
  });
}

export default React.memo(IconList);
