import React from "react";
import { useSelector } from "react-redux";
import { addUrlPrefix } from "../../utils/urlValidations";
import A from "../elements/A";
import * as FontAwesome from "react-icons/fa";

export default function IconBookmark(props) {
  const iconBookmarks = useSelector(state => state.layouts.iconBookmarks);
  const bookmark = iconBookmarks
    ? iconBookmarks.find(bookmark => bookmark.widget_id === props.widget_id)
    : null;

  const Icon = FontAwesome[bookmark?.icon ?? "FaBeer"];
  return (
    <div className="bookmark-container">
      {bookmark ? (
        <A
          href={addUrlPrefix(bookmark.url)}
          target="_blank"
          rel="noopener noreferrer"
          className="body-layout-font"
        >
          <Icon style={{ width: "50px", height: "50px" }} />
        </A>
      ) : (
        <p className="body-layout-font">Bookmark is currently in edit mode</p>
      )}
    </div>
  );
}
