import React from "react";
import "./QuestionCommand.scss";
import A from "../elements/A";

export default function QuestionCommand(props) {
  if (props.question === "" || props.command === "") {
    return null;
  }
  return (
    <p className="question-command">
      {props.question}{" "}
      <A href={props.route} target={props.target}>
        {props.command}
      </A>
    </p>
  );
}

QuestionCommand.defaultProps = {
  question: "",
  command: "",
  route: "/signup",
  target: "_self"
};
