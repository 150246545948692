import React, { Component } from "react";
import { Manager, Reference, Popper } from "react-popper";
import ReactDOM from "react-dom";
import "./popperWrapper.scss";

export default class PopperWrapper extends Component {
  render() {
    const {
      reference,
      poppedComponent,
      placement,
      referenceStyle,
      poppedComponentStyles
    } = this.props;
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div style={referenceStyle} ref={ref}>
              {reference}
            </div>
          )}
        </Reference>
        {ReactDOM.createPortal(
          <Popper
            placement={placement || "bottom"}
            modifiers={{
              hide: { enabled: false },
              preventOverflow: { enabled: false }
            }}
            eventsEnabled={true}
            positionFixed={false}
          >
            {({ ref, style, placement, arrowProps, modifiers }) => (
              <div
                ref={ref}
                style={Object.assign({}, style, poppedComponentStyles)}
                className="poppedComponent"
              >
                {poppedComponent}
              </div>
            )}
          </Popper>,
          document.body
        )}
      </Manager>
    );
  }
}
