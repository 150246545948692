import { useDispatch } from "react-redux";
import { saveEverything } from "../actions/globalActions";
import { eventCategories, recordEvent } from "../utils/googleAnalyticsEvents";

/**
 * This hook provides the caller with everything it needs to save the layout.
 */
export const useSaveWrapper = () => {
  const dispatch = useDispatch();
  const onSaveClick = event => {
    recordEvent(
      "Save Layout",
      eventCategories.GENERAL_APP_ACTION,
      "Save Layout"
    );
    dispatch(saveEverything());
  };

  return { onSaveClick };
};
