import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      <p>
        © 2020 Graffic Applications, LLC.&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.iubenda.com/privacy-policy/56685132"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.iubenda.com/terms-and-conditions/56685132"
          title="Terms and Conditions "
        >
          Terms and Conditions
        </a>
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.iubenda.com/privacy-policy/56685132/cookie-policy"
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
      </p>
    </div>
  );
}
