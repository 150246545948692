import {
  SET_ACCENT_COLOR,
  SET_BASE_FONT_SIZE,
  SET_COMPONENT_BORDER_RADIUS,
  SET_COMPONENT_BACKGROUND_COLOR,
  SET_COMPONENT_BACKGROUND_OPACITY,
  REQUEST_GET_STYLES,
  REQUEST_SAVE_STYLES,
  GET_STYLES_SUCCESS,
  SAVE_STYLES_SUCCESS,
  GET_STYLES_FAILURE,
  SAVE_STYLES_FAILURE,
  RESET_STYLES,
  SELECT_STYLE_TEMPLATE,
  REMOVE_STYLE_TEMPLATE,
  CLEAR_STYLES,
  SET_HEADER_FONT_FAMILY,
  SET_BODY_FONT_FAMILY
} from "../actions/types";
import { styleTemplates } from "../utils/StyleTemplates";

export const initialStyleState = {
  ...styleTemplates.dashboi.config,
  templateKey: "dashboi"
};

/*
 * Responds to style actions and updates the appropriate state
 */
export function handleStyleActions(state, action) {
  if (typeof state === "undefined") {
    return initialStyleState;
  }
  switch (action.type) {
    case SET_ACCENT_COLOR: {
      return Object.assign({}, state, {
        accentColor: action.accentColor,
        templateKey: null
      });
    }
    case SET_BASE_FONT_SIZE: {
      return Object.assign({}, state, {
        baseFontSize: action.baseFontSize,
        templateKey: null
      });
    }
    case SET_COMPONENT_BORDER_RADIUS: {
      return Object.assign({}, state, {
        borderRadius: action.borderRadius,
        templateKey: null
      });
    }
    case SET_COMPONENT_BACKGROUND_COLOR: {
      const opacity = state.componentBackgroundColor?.a;
      const newBackgroundColor = opacity
        ? Object.assign({}, action.backgroundColor, { a: opacity })
        : action.backgroundColor;
      return Object.assign({}, state, {
        componentBackgroundColor: newBackgroundColor,
        templateKey: null
      });
    }
    case SET_COMPONENT_BACKGROUND_OPACITY: {
      const newBackgroundColor = Object.assign(
        {},
        state.componentBackgroundColor,
        { a: action.opacity }
      );
      return Object.assign({}, state, {
        componentBackgroundColor: newBackgroundColor,
        templateKey: null
      });
    }
    case SET_HEADER_FONT_FAMILY: {
      return Object.assign({}, state, {
        headerFontFamily: action.fontFamily,
        templateKey: null
      });
    }
    case SET_BODY_FONT_FAMILY: {
      return Object.assign({}, state, {
        bodyFontFamily: action.fontFamily,
        templateKey: null
      });
    }
    case GET_STYLES_SUCCESS: {
      return Object.assign({}, state, {
        id: action.id,
        accentColor: action.accentColor || state.accentColor,
        baseFontSize: action.baseFontSize || state.baseFontSize,
        borderRadius: action.borderRadius || state.borderRadius,
        componentBackgroundColor:
          action.componentBackgroundColor || state.componentBackgroundColor
      });
    }
    case SAVE_STYLES_SUCCESS: {
      return Object.assign({}, state, {
        id: action.id
      });
    }
    case REMOVE_STYLE_TEMPLATE:
    case RESET_STYLES: {
      return Object.assign({}, state, {
        ...initialStyleState,
        id: action.id || state.id
      });
    }
    case CLEAR_STYLES: {
      return Object.assign({}, state, initialStyleState);
    }
    case SELECT_STYLE_TEMPLATE: {
      const id = state.id;
      return Object.assign({}, state, {
        ...styleTemplates[action.templateKey].config,
        templateKey: action.templateKey,
        id
      });
    }
    case GET_STYLES_FAILURE:
    case SAVE_STYLES_FAILURE:
    case REQUEST_GET_STYLES:
    case REQUEST_SAVE_STYLES:
    default: {
      return state;
    }
  }
}
