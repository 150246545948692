import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { hideModal } from "../../actions/modalActions";
import { useSaveWrapper } from "../../custom-hooks/useSaveWrapper";
import Banner from "../banner/banner";
import { bannerSentiment } from "../banner/bannerUtils";
import ButtonFooterLayout from "../buttonFooterLayout/ButtonFooterLayout";
import "./Modal.scss";
import PremiumModal from "./PremiumModal";

export default function PremiumOnlySaveModal(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const { onSaveClick } = useSaveWrapper();

  return (
    <div className="premium-only-save-modal">
      <PremiumModal
        renderButtons={confirmCallback => (
          <ButtonFooterLayout
            onSecondaryButtonClick={event => {
              onSaveClick(event);
              dispatch(hideModal());
            }}
            onPrimaryButtonClick={async event => {
              const errorOccurred = await confirmCallback(event);
              if (!errorOccurred) {
                onSaveClick(errorOccurred, event);
                dispatch(hideModal());
              } else {
                setError(
                  "An error occurred while processing your payment. Please reach out to the dashboi team at dashboi.app@gmail.com"
                );
              }
            }}
            secondaryButtonText="Discard Premium Features"
            primaryButtonText="Go Premium"
          />
        )}
      >
        {error !== null ? (
          <Banner sentiment={bannerSentiment.NEGATIVE} message={error} />
        ) : null}
        <p>
          You entered into experiment mode which means you had access to all
          premium features when designing your layout. If you don't upgrade to
          premium, you will have to discard those features when saving.
        </p>
        <p>
          Your layout looks super awesome! Upgrade today to keep it that way.
        </p>
      </PremiumModal>
    </div>
  );
}
