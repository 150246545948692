import React, { Fragment, useEffect } from "react";
import Wallpaper from "../wallpaper/wallpaper";
import TopbarContainer from "../topbar/topbarContainer";
import AppBody from "../appBody/appBody";
import ContactPage from "../pages/contact/ContactPage";
import { Route, Switch, Redirect } from "react-router-dom";
import Page from "../pages/Page";
import PageNotFound from "../pages/404/PageNotFound";
import UnsplashCredit from "../unsplashCredit/UnsplashCredit";
import DocsPage from "../pages/docs/DocsPage";
import { useSelector } from "react-redux";
import {
  getRGBFromColorObject,
  getRGBAFromColorObject
} from "../../utils/colors";
import AuthenticationContainer, {
  AuthenticationInterfaces
} from "../login/AuthenticationContainer";
import AboutPage from "../pages/about/AboutPage";
import { fonts } from "../fontPicker/FontPicker";

/*
 * Manages the various routes of the application
 * Ex: /app, /contact
 */
export default function AppRouter(props) {
  const {
    accentColor,
    baseFontSize,
    borderRadius,
    componentBackgroundColor,
    headerFontFamily,
    bodyFontFamily
  } = useSelector(state => state.styles);

  const { isLoggedIn } = useSelector(state => state.login);

  /*
   * When the app renders, apply the styles that the user has set up.
   * Styles are applied via CSS properties.
   */
  useEffect(() => {
    const calculateTint = value => value + Math.floor((255 - value) / 2);

    const isColorLightOrDark = rgb => {
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      const hsp = Math.sqrt(
        0.299 * rgb.r * rgb.r + 0.587 * rgb.g * rgb.g + 0.114 * rgb.b * rgb.b
      );

      return hsp > 127.5 ? "light" : "dark";
    };

    if (accentColor) {
      document.documentElement.style.setProperty(
        "--accent-color",
        getRGBFromColorObject(accentColor)
      );
      document.documentElement.style.setProperty(
        "--secondary-accent-color",
        `rgb(${calculateTint(accentColor.r)}, ${calculateTint(
          accentColor.g
        )}, ${calculateTint(accentColor.b)})`
      );
    }

    if (baseFontSize) {
      document.documentElement.style.setProperty(
        "--body-font-size",
        `${baseFontSize}px`
      );
    }

    document.documentElement.style.setProperty(
      "--component-border-radius",
      `${borderRadius}px`
    );

    if (headerFontFamily) {
      document.documentElement.style.setProperty(
        "--header-font-family",
        headerFontFamily
      );
      document.documentElement.style.setProperty(
        "--header-font-decoration",
        fonts[headerFontFamily]
      );
    }

    if (bodyFontFamily) {
      document.documentElement.style.setProperty(
        "--body-font-family",
        bodyFontFamily
      );
      document.documentElement.style.setProperty(
        "--body-font-decoration",
        fonts[bodyFontFamily]
      );
    }

    if (componentBackgroundColor) {
      document.documentElement.style.setProperty(
        "--component-background-color",
        getRGBAFromColorObject(componentBackgroundColor)
      );
      if (componentBackgroundColor.a === 0) {
        document.documentElement.style.setProperty(
          "--component-border-color",
          `transparent`
        );
      } else {
        document.documentElement.style.setProperty(
          "--component-border-color",
          getRGBFromColorObject(componentBackgroundColor)
        );
      }
    }

    if (isColorLightOrDark(accentColor) === "light") {
      document.documentElement.style.setProperty(
        "--on-accent-text-color",
        "rgb(45, 48, 71)"
      );
      document.documentElement.style.setProperty(
        "--featured-news-background-color",
        "rgba(0, 0, 0, .7)"
      );
    } else {
      document.documentElement.style.setProperty(
        "--on-accent-text-color",
        "rgb(255, 255, 255)"
      );
      document.documentElement.style.setProperty(
        "--featured-news-background-color",
        "rgba(244, 244, 244, .8)"
      );
    }

    if (isColorLightOrDark(componentBackgroundColor) === "light") {
      document.documentElement.style.setProperty(
        "--on-component-text-color",
        "rgb(0, 0, 0)"
      );
    } else {
      document.documentElement.style.setProperty(
        "--on-component-text-color",
        "rgb(255, 255, 255)"
      );
    }
  }, [
    accentColor,
    baseFontSize,
    borderRadius,
    componentBackgroundColor,
    headerFontFamily,
    bodyFontFamily
  ]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Fragment>
            <TopbarContainer shouldShowActions={false} activePage="/" />
            <Page>
              <AboutPage />
            </Page>
          </Fragment>
        )}
      />
      <Route
        exact
        path="/app"
        render={() => (
          <Wallpaper>
            <TopbarContainer shouldShowActions={true} activePage="app" />
            <AppBody />
          </Wallpaper>
        )}
      />
      <Route
        exact
        path="/contact"
        render={() => (
          <Wallpaper>
            <TopbarContainer shouldShowActions={false} activePage="contact" />
            <Page>
              <ContactPage />
              <UnsplashCredit
                photographerName="Ales Krivec"
                photographLink="https://unsplash.com/@aleskrivec?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge"
              />
            </Page>
          </Wallpaper>
        )}
      />
      <Route
        exact
        path="/components"
        render={() => (
          <Wallpaper>
            <TopbarContainer shouldShowActions={false} activePage="contact" />
            <Page>
              <DocsPage />
            </Page>
          </Wallpaper>
        )}
      />
      <Route
        exact
        path="/signup"
        render={() =>
          !isLoggedIn ? (
            <Wallpaper>
              <Page showFooter={false} showHeader={false}>
                <AuthenticationContainer
                  authenticationInterface={AuthenticationInterfaces.SIGN_UP}
                />
              </Page>
            </Wallpaper>
          ) : (
            <Redirect to="/app" />
          )
        }
      />
      <Route
        exact
        path="/signup"
        render={() => (
          <Wallpaper>
            <Page showFooter={false} showHeader={false}>
              <AuthenticationContainer
                authenticationInterface={AuthenticationInterfaces.SIGN_UP}
              />
            </Page>
          </Wallpaper>
        )}
      />
      <Route
        exact
        path="/login"
        render={() =>
          !isLoggedIn ? (
            <Wallpaper>
              <Page showFooter={false} showHeader={false}>
                <AuthenticationContainer
                  authenticationInterface={AuthenticationInterfaces.LOGIN}
                />
              </Page>
            </Wallpaper>
          ) : (
            <Redirect to="/app" />
          )
        }
      />
      <Route
        exact
        path="/forgotpassword"
        render={() =>
          !isLoggedIn ? (
            <Wallpaper>
              <Page showFooter={false} showHeader={false}>
                <AuthenticationContainer
                  authenticationInterface={
                    AuthenticationInterfaces.FORGOT_PASSWORD
                  }
                />
              </Page>
            </Wallpaper>
          ) : (
            <Redirect to="/app" />
          )
        }
      />
      <Route
        exact
        path="/premium"
        render={() =>
          isLoggedIn ? (
            <Wallpaper>
              <Page showFooter={false} showHeader={false}>
                <AuthenticationContainer
                  authenticationInterface={AuthenticationInterfaces.PREMIUM}
                />
              </Page>
            </Wallpaper>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        path="*"
        render={() => (
          <Wallpaper>
            <TopbarContainer shouldShowActions={false} />
            <Page>
              <PageNotFound />
            </Page>
          </Wallpaper>
        )}
      />
    </Switch>
  );
}
