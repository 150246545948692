import React from "react";
import "./banner.scss";
import { useSelector, useDispatch } from "react-redux";
import { getBannerSentiment, getBannerMessage } from "./bannerUtils";
import { hideGlobalBanner } from "../../actions/globalActions";
import Banner from "./banner";

export default function GlobalBanner(props) {
  const { bannerType, isBannerVisible } = useSelector(state => state.global);
  const dispatch = useDispatch();

  const onBannerClose = () => {
    dispatch(hideGlobalBanner());
  };

  const sentiment = getBannerSentiment(bannerType);
  const message = getBannerMessage(bannerType);

  return (
    <div className="global-banner">
      <Banner
        bannerType={bannerType}
        isBannerVisible={isBannerVisible}
        shouldShowBannerClose={true}
        sentiment={sentiment}
        message={message}
        onBannerClose={onBannerClose}
      />
    </div>
  );
}
