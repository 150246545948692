import React, { useState, Fragment, useRef, useLayoutEffect } from "react";
import { useFormInput } from "../../custom-hooks/useFormInput";
import {
  FaSmile,
  FaFrown,
  FaGrinHearts,
  FaGrimace,
  FaMeh
} from "react-icons/fa";
import Input from "../input/input";
import InputLabel from "../input/InputLabel";
import "./Feedback.scss";
import Tooltip from "../tooltip/tooltip";
import classNames from "classnames";
import Button from "../button/button";
import TextArea from "../textArea/TextArea";

export default function Feedback(props) {
  const title = useFormInput("");
  const body = useFormInput("");
  const [sentimentScore, setSentimentScore] = useState(null);
  const firstOptionRef = useRef();

  useLayoutEffect(() => {
    firstOptionRef.current.focus();
  }, [firstOptionRef]);

  const onSubmit = event => {
    event.preventDefault();
    props.onSubmit({
      uid: props.uid,
      sentimentScore,
      title: title.value,
      body: body.value
    });
  };

  return (
    <Fragment>
      <InputLabel label="How do you like Dashboi?" />
      <div className="sentiment-score-choices">
        {[
          {
            icon: <FaGrinHearts />,
            text: "I'm a total fanboi!",
            score: 5,
            ref: firstOptionRef
          },
          {
            icon: <FaSmile />,
            text: "I like it.",
            score: 4,
            ref: null
          },
          {
            icon: <FaMeh />,
            text: "It's ok.",
            score: 3,
            ref: null
          },
          {
            icon: <FaFrown />,
            text: "I don't like it.",
            score: 2,
            ref: null
          },
          {
            icon: <FaGrimace />,
            text: "Really needs some work...",
            score: 1,
            ref: null
          }
        ].map(config => {
          return (
            <Tooltip
              tooltipMessage={config.text}
              placement="bottom"
              key={config.score}
            >
              <div
                className={classNames(
                  "sentiment-score-choice",
                  config.score === sentimentScore ? "selected" : null
                )}
                onClick={() => setSentimentScore(config.score)}
                ref={config.ref}
                tabIndex={0}
              >
                <div
                  className={classNames(
                    "sentiment-score-choice",
                    config.score === sentimentScore ? "selected" : null
                  )}
                  onClick={() => setSentimentScore(config.score)}
                  ref={config.ref}
                  tabIndex={0}
                >
                  {config.icon}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </div>
      <form onSubmit={onSubmit}>
        <Input
          label="Summary"
          ariaLabel="Summary"
          id="summary"
          type="text"
          name="summary"
          {...title}
          required={true}
        />
        <TextArea
          label="Details"
          ariaLabel="Details"
          name="details"
          id="details"
          {...body}
        />
        <Button type="submit">Send Feedback</Button>
      </form>
    </Fragment>
  );
}

Feedback.defaultProps = {
  onSubmit: () => {}
};
