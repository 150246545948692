export const BANNER_TYPE_TOO_MANY_LAYOUTS = "BANNER_TYPE_TOO_MANY_LAYOUTS";
export const BANNER_TYPE_TOO_MANY_STYLE_CONFIGS =
  "BANNER_TYPE_TOO_MANY_STYLE_CONFIGS";

export const bannerSentiment = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral"
};

export function getBannerSentiment(bannerType) {
  switch (bannerType) {
    case BANNER_TYPE_TOO_MANY_LAYOUTS:
    case BANNER_TYPE_TOO_MANY_STYLE_CONFIGS:
      return bannerSentiment.NEGATIVE;
    default:
      return bannerSentiment.NEUTRAL;
  }
}

export function getBannerMessage(bannerType) {
  switch (bannerType) {
    case BANNER_TYPE_TOO_MANY_LAYOUTS:
      return "An error occurred retrieving your layout. Code: ERR01. Please contact the dashboi team at dashboi.app@gmail.com.";
    case BANNER_TYPE_TOO_MANY_STYLE_CONFIGS:
      return "An error occurred retrieving your layout. Code: ERR03. Please contact the dashboi team at dashboi.app@gmail.com.";
    default:
      return "An error occurred. Code: ERR02. Please contact the dashboi team at dashboi.app@gmail.com.";
  }
}
