import React from "react";
import GuardianFeed from "./GuardianFeed";
import FoxNewsFeed from "./FoxNewsFeed";
import {
  CNN_WORLD,
  ESPN_COLLEGE_BASKETBALL,
  ESPN_COLLEGE_FOOTBALL,
  ESPN_MLB,
  ESPN_NBA,
  ESPN_NFL,
  ESPN_NHL,
  ESPN_TOP,
  FOX_BUSINESS,
  FOX_VIEWS,
  FOX_SCI_TECH,
  FOX_POLITICS,
  FOX_WORLD,
  FOX_ENTERTAINMENT,
  FOX_HEALTH,
  FOX_LATEST,
  FOX_NATIONAL,
  GUARDIAN_WORLD,
  GUARDIAN_US,
  GUARDIAN_UK,
  GUARDIAN_UK_LIFESTYLE,
  GUARDIAN_SCIENCE,
  GUARDIAN_US_LIFESTYLE,
  GUARDIAN_FASHION,
  GUARDIAN_US_SPORTS,
  GUARDIAN_UK_SPORTS,
  GAMASUTRA_FEED,
  CNN_TOP_STORIES,
  CNN_US,
  CNN_POLITICS,
  CNN_TECHNOLOGY,
  CNN_HEALTH,
  CNN_ENTERTAINMENT,
  CNN_TRAVEL,
  NYT_WORLD,
  NYT_US,
  NYT_BUSINESS,
  NYT_TECHNOLOGY,
  NYT_SPORTS,
  NYT_JOBS,
  NYT_ENVIRONMENT,
  NYT_SPACE,
  NYT_ARTS,
  WAPO_OPINIONS,
  WAPO_FACT_CHECKER,
  WAPO_LEADERSHIP,
  WAPO_LIFESTYLE,
  WAPO_MONKEY_CAGE,
  WAPO_NATIONAL,
  WAPO_POLITICS,
  WAPO_WORLD,
  CNN_WORLD_FRIENDLY,
  CNN_TOP_STORIES_FRIENDLY,
  CNN_US_FRIENDLY,
  CNN_POLITICS_FRIENDLY,
  CNN_TECHNOLOGY_FRIENDLY,
  CNN_HEALTH_FRIENDLY,
  CNN_ENTERTAINMENT_FRIENDLY,
  CNN_TRAVEL_FRIENDLY,
  NYT_WORLD_FRIENDLY,
  NYT_US_FRIENDLY,
  NYT_BUSINESS_FRIENDLY,
  NYT_TECHNOLOGY_FRIENDLY,
  NYT_SPORTS_FRIENDLY,
  NYT_ENVIRONMENT_FRIENDLY,
  NYT_SPACE_FRIENDLY,
  NYT_JOBS_FRIENDLY,
  NYT_ARTS_FRIENDLY,
  WAPO_OPINIONS_FRIENDLY,
  WAPO_FACT_CHECKER_FRIENDLY,
  WAPO_LEADERSHIP_FRIENDLY,
  WAPO_MONKEY_CAGE_FRIENDLY,
  WAPO_POLITICS_FRIENDLY,
  WAPO_WORLD_FRIENDLY,
  WAPO_LIFESTYLE_FRIENDLY,
  WAPO_NATIONAL_FRIENDLY,
  ESPN_COLLEGE_BASKETBALL_FRIENDLY,
  ESPN_MLB_FRIENDLY,
  ESPN_NBA_FRIENDLY,
  ESPN_NFL_FRIENDLY,
  ESPN_TOP_FRIENDLY,
  ESPN_COLLEGE_FOOTBALL_FRIENDLY,
  ESPN_NHL_FRIENDLY,
  FOX_BUSINESS_FRIENDLY,
  FOX_VIEWS_FRIENDLY,
  FOX_SCI_TECH_FRIENDLY,
  FOX_POLITICS_FRIENDLY,
  FOX_WORLD_FRIENDLY,
  FOX_ENTERTAINMENT_FRIENDLY,
  FOX_HEALTH_FRIENDLY,
  FOX_LATEST_FRIENDLY,
  FOX_NATIONAL_FRIENDLY,
  GUARDIAN_US_FRIENDLY,
  GUARDIAN_UK_LIFESTYLE_FRIENDLY,
  GUARDIAN_US_LIFESTYLE_FRIENDLY,
  GUARDIAN_FASHION_FRIENDLY,
  GUARDIAN_US_SPORTS_FRIENDLY,
  GUARDIAN_UK_SPORTS_FRIENDLY,
  GUARDIAN_WORLD_FRIENDLY,
  GUARDIAN_SCIENCE_FRIENDLY,
  GUARDIAN_UK_FRIENDLY
} from "../widget/widgetUtils";
import GamasutraFeed from "./GamasutraFeed";
import EspnFeed from "./EspnFeed";
import CnnFeed from "./CnnFeed";
import NytFeed from "./NytFeed";
import WapoFeed from "./WapoFeed";
import "./RssFeed.scss";
import WidgetHeaderContent from "../widgetHeaderContent/WidgetHeaderContent";

export default function RssFeed(props) {
  const getFeedComponent = () => {
    switch (props.feedType) {
      case CNN_WORLD:
        return {
          title: `CNN - ${CNN_WORLD_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_TOP_STORIES:
        return {
          title: `CNN - ${CNN_TOP_STORIES_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_US:
        return {
          title: `CNN - ${CNN_US_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_POLITICS:
        return {
          title: `CNN - ${CNN_POLITICS_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_TECHNOLOGY:
        return {
          title: `CNN - ${CNN_TECHNOLOGY_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_HEALTH:
        return {
          title: `CNN - ${CNN_HEALTH_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_ENTERTAINMENT:
        return {
          title: `CNN - ${CNN_ENTERTAINMENT_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case CNN_TRAVEL:
        return {
          title: `CNN - ${CNN_TRAVEL_FRIENDLY}`,
          component: <CnnFeed urlStub={props.feedType} />
        };
      case NYT_WORLD:
        return {
          title: `NYT - ${NYT_WORLD_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_US:
        return {
          title: `NYT - ${NYT_US_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_BUSINESS:
        return {
          title: `NYT - ${NYT_BUSINESS_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_TECHNOLOGY:
        return {
          title: `NYT - ${NYT_TECHNOLOGY_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_SPORTS:
        return {
          title: `NYT - ${NYT_SPORTS_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_ENVIRONMENT:
        return {
          title: `NYT - ${NYT_ENVIRONMENT_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_SPACE:
        return {
          title: `NYT - ${NYT_SPACE_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_JOBS:
        return {
          title: `NYT - ${NYT_JOBS_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case NYT_ARTS:
        return {
          title: `NYT - ${NYT_ARTS_FRIENDLY}`,
          component: <NytFeed urlStub={props.feedType} />
        };
      case WAPO_OPINIONS:
        return {
          title: `WaPo - ${WAPO_OPINIONS_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_FACT_CHECKER:
        return {
          title: `WaPo - ${WAPO_FACT_CHECKER_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_LEADERSHIP:
        return {
          title: `WaPo - ${WAPO_LEADERSHIP_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_LIFESTYLE:
        return {
          title: `WaPo - ${WAPO_LIFESTYLE_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_MONKEY_CAGE:
        return {
          title: `WaPo - ${WAPO_MONKEY_CAGE_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_NATIONAL:
        return {
          title: `WaPo - ${WAPO_NATIONAL_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_POLITICS:
        return {
          title: `WaPo - ${WAPO_POLITICS_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case WAPO_WORLD:
        return {
          title: `WaPo - ${WAPO_WORLD_FRIENDLY}`,
          component: <WapoFeed urlStub={props.feedType} />
        };
      case ESPN_COLLEGE_BASKETBALL:
        return {
          title: `ESPN - ${ESPN_COLLEGE_BASKETBALL_FRIENDLY}`,
          component: <EspnFeed urlStub={"ncb"} />
        };
      case ESPN_COLLEGE_FOOTBALL:
        return {
          title: `ESPN - ${ESPN_COLLEGE_FOOTBALL_FRIENDLY}`,
          component: <EspnFeed urlStub={"ncf"} />
        };
      case ESPN_MLB:
        return {
          title: `ESPN - ${ESPN_MLB_FRIENDLY}`,
          component: <EspnFeed urlStub={"mlb"} />
        };
      case ESPN_NBA:
        return {
          title: `ESPN - ${ESPN_NBA_FRIENDLY}`,
          component: <EspnFeed urlStub={"nba"} />
        };
      case ESPN_NFL:
        return {
          title: `ESPN - ${ESPN_NFL_FRIENDLY}`,
          component: <EspnFeed urlStub={"nfl"} />
        };
      case ESPN_NHL:
        return {
          title: `ESPN - ${ESPN_NHL_FRIENDLY}`,
          component: <EspnFeed urlStub={"nhl"} />
        };
      case ESPN_TOP:
        return {
          title: `ESPN - ${ESPN_TOP_FRIENDLY}`,
          component: <EspnFeed />
        };
      case FOX_BUSINESS:
        return {
          title: `FOX - ${FOX_BUSINESS_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"business"} />
        };
      case FOX_VIEWS:
        return {
          title: `FOX - ${FOX_VIEWS_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"views"} />
        };
      case FOX_SCI_TECH:
        return {
          title: `FOX - ${FOX_SCI_TECH_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"scitech"} />
        };
      case FOX_POLITICS:
        return {
          title: `FOX - ${FOX_POLITICS_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"politics"} />
        };
      case FOX_WORLD:
        return {
          title: `FOX - ${FOX_WORLD_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"world"} />
        };
      case FOX_ENTERTAINMENT:
        return {
          title: `FOX - ${FOX_ENTERTAINMENT_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"entertainment"} />
        };
      case FOX_HEALTH:
        return {
          title: `FOX - ${FOX_HEALTH_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"health"} />
        };
      case FOX_LATEST:
        return {
          title: `FOX - ${FOX_LATEST_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"latest"} />
        };
      case FOX_NATIONAL:
        return {
          title: `FOX - ${FOX_NATIONAL_FRIENDLY}`,
          component: <FoxNewsFeed urlStub={"national"} />
        };
      case GAMASUTRA_FEED:
        return {
          title: `Gamasutra`,
          component: <GamasutraFeed />
        };
      case GUARDIAN_WORLD:
        return {
          title: `The Guardian - ${GUARDIAN_WORLD_FRIENDLY}`,
          component: <GuardianFeed urlStub={"world"} />
        };
      case GUARDIAN_US:
        return {
          title: `The Guardian - ${GUARDIAN_US_FRIENDLY}`,
          component: <GuardianFeed urlStub={"us"} />
        };
      case GUARDIAN_UK:
        return {
          title: `The Guardian - ${GUARDIAN_UK_FRIENDLY}`,
          component: <GuardianFeed urlStub={"uk"} />
        };
      case GUARDIAN_UK_LIFESTYLE:
        return {
          title: `The Guardian - ${GUARDIAN_UK_LIFESTYLE_FRIENDLY}`,
          component: <GuardianFeed urlStub={"uk/lifeandstyle"} />
        };
      case GUARDIAN_SCIENCE:
        return {
          title: `The Guardian - ${GUARDIAN_SCIENCE_FRIENDLY}`,
          component: <GuardianFeed urlStub={"science"} />
        };
      case GUARDIAN_US_LIFESTYLE:
        return {
          title: `The Guardian - ${GUARDIAN_US_LIFESTYLE_FRIENDLY}`,
          component: <GuardianFeed urlStub={"us/lifeandstyle"} />
        };
      case GUARDIAN_FASHION:
        return {
          title: `The Guardian - ${GUARDIAN_FASHION_FRIENDLY}`,
          component: <GuardianFeed urlStub={"fashion"} />
        };
      case GUARDIAN_US_SPORTS:
        return {
          title: `The Guardian - ${GUARDIAN_US_SPORTS_FRIENDLY}`,
          component: <GuardianFeed urlStub={"us/sport"} />
        };
      case GUARDIAN_UK_SPORTS:
      default:
        return {
          title: `The Guardian - ${GUARDIAN_UK_SPORTS_FRIENDLY}`,
          component: <GuardianFeed urlStub={"uk/sport"} />
        };
    }
  };

  const component = getFeedComponent();

  return (
    <div className="rss-feed">
      <WidgetHeaderContent
        headerElements={
          <h4 className="header-layout-font">{component.title}</h4>
        }
        contentElements={component.component}
      />
    </div>
  );
}
