import { connect } from "react-redux";
import Feedback from "./Feedback";
import { sendFeedback } from "../../actions/FeedbackActions";

const mapStateToProps = state => {
  const { uid } = state.userDetails;
  return {
    uid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: ({ uid, sentimentScore, title, body }) => {
      dispatch(sendFeedback({ uid, sentimentScore, title, body }));
    }
  };
};

const FeedbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);

export default FeedbackContainer;
