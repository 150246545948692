import React, { useCallback, useEffect } from "react";
import "./button.scss";
import classNames from "classnames";
import { handleEnterKeypress } from "../../utils/keyEvents";

export const buttonStyles = {
  STANDARD_BUTTON: "STANDARD_BUTTON",
  OPAQUE_BUTTON: "OPAQUE_BUTTON",
  SECONDARY_BUTTON: "SECONDARY_BUTTON"
};

export default function Button(props) {
  const shouldShowAnimation =
    props.buttonStyle === buttonStyles.STANDARD_BUTTON;

  const getButtonClass = () => {
    switch (props.buttonStyle) {
      case buttonStyles.OPAQUE_BUTTON:
        return "opaque-button";
      case buttonStyles.SECONDARY_BUTTON:
        return "secondary-button";
      case buttonStyles.STANDARD_BUTTON:
      default:
        return "standard-button";
    }
  };

  const getButtonContextClass = () =>
    props.isInDarkBackground ? "dark-background" : "light-background";

  const handleKeyPress = event => {
    if (props.onKeyPress === undefined) {
      handleEnterKeypress(event, props.onClick);
    } else {
      handleEnterKeypress(event, props.onKeyPress);
    }
  };

  const handleKeyForEffect = useCallback(handleKeyPress);

  useEffect(() => {
    if (props.shouldHandleGlobalEnterKeypress) {
      document.addEventListener("keydown", handleKeyForEffect, false);
    }

    return () =>
      document.removeEventListener("keydown", handleKeyForEffect, false);
  }, [props.shouldHandleGlobalEnterKeypress, handleKeyForEffect]);

  return (
    <button
      onClick={props.onClick}
      className={classNames(
        props.className,
        getButtonClass(),
        getButtonContextClass(),
        props.usePermanentAccent ? "use-permanent-accent" : ""
      )}
      type={props.type}
      ref={props.reference}
      onKeyPress={handleKeyPress}
    >
      {shouldShowAnimation ? <span /> : null}
      {props.children}
    </button>
  );
}

Button.defaultProps = {
  onClick: () => {},
  buttonStyle: buttonStyles.STANDARD_BUTTON,
  reference: null,
  isInDarkBackground: false,
  type: "button",
  className: "",
  usePermanentAccent: false,
  shouldHandleGlobalEnterKeypress: false
};
