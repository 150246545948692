import { connect } from "react-redux";
import { saveBackgroundImageUrl } from "../../actions/layoutActions";
import Settings from "./settings";

const mapStateToProps = state => {
  const { backgroundUrl } = state.layouts;
  return {
    backgroundUrl
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onBackgroundImageBlur: backgroundImageUrl => {
      dispatch(saveBackgroundImageUrl(backgroundImageUrl));
    }
  };
};

const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);

export default SettingsContainer;
