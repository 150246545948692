import React from "react";
import { useFormInput } from "../../custom-hooks/useFormInput";
import Input from "../input/input";
import "./googleSearchWidget.scss";

export default function GoogleSearchWidget(props) {
  const searchParams = useFormInput("");
  const searchQuery = "https://www.google.com/search?q=";

  const onInputSubmit = () => {
    const searchTerms = searchParams.value.replace(" ", "+");
    const appendedQuery = searchQuery.concat(searchTerms);
    window.open(appendedQuery, "_blank");
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onInputSubmit();
    }
  };

  return (
    <div className="google_search_widget">
      <Input
        placeholder="Search Google!"
        id="googleSearch"
        name="googleSearch"
        type="text"
        {...searchParams}
        onKeyDown={onKeyDown}
        className="body-layout-font"
      />
    </div>
  );
}
