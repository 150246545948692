import React, { useState } from "react";
import * as FontAwesome from "react-icons/fa";
import { useFormInput } from "../../custom-hooks/useFormInput";
import IconList from "./IconList";
import "./IconPicker.scss";
import LoadingGlass from "../loadSpinner/LoadingGlass";
import { icons } from "./IconUtils";

let typingTimer;
export default function IconPicker(props) {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const filteredText = useFormInput("");
  const [myIcons, setMyIcons] = useState(icons);

  const IconComponent = FontAwesome[props.chosenIcon];

  const onInputKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(onDoneTyping, 500);
  };

  const onInputKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const onDoneTyping = () => {
    setMyIcons(
      icons.filter(icon =>
        icon.toLowerCase().includes(filteredText.value.toLowerCase())
      )
    );
    setIsUserTyping(false);
  };

  const handleChangingInput = event => {
    filteredText.onChange(event);
    if (!isUserTyping) {
      setIsUserTyping(true);
    }
  };

  return (
    <div className="icon-picker-container" style={{ position: "relative" }}>
      <div
        className="icon-container"
        onClick={() => setIsPickerVisible(!isPickerVisible)}
        aria-label="Icon Picker Selected Icon"
      >
        <IconComponent className="icon" aria-label={props.chosenIcon} />
      </div>
      {isPickerVisible ? (
        <div className="all-icons">
          <input
            value={filteredText.value}
            onChange={handleChangingInput}
            className="icon-picker-input"
            onKeyDown={onInputKeyDown}
            onKeyUp={onInputKeyUp}
            placeholder="Search icons..."
          />
          <div className="icon-list">
            {isUserTyping ? (
              <LoadingGlass style={{ height: "calc(100% - 31px)" }} />
            ) : null}
            <IconList
              icons={myIcons}
              setChosenIcon={props.setChosenIcon}
              setIsPickerVisible={setIsPickerVisible}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

IconPicker.defaultProps = {
  chosenIcon: "FaBeer",
  setChosenIcon: () => {}
};
