import { db } from "../utils/firebase-config";

export function writeTask(task) {
  return new Promise((resolve, reject) => {
    db.collection("tasks")
      .add({
        uid: task.uid,
        taskName: task.taskName,
        isCompleted: false,
        createdDate: task.createdDate
      })
      .then(docRef => resolve(docRef))
      .catch(err => reject(err));
  });
}

export function getTasksForUser(uid) {
  return new Promise((resolve, reject) => {
    db.collection("tasks")
      .where("uid", "==", uid)
      .get()
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function updateTaskCompletion(taskId, isCompleted) {
  return new Promise((resolve, reject) => {
    db.collection("tasks")
      .doc(taskId)
      .update({
        isCompleted
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateTaskNameForTask(taskId, taskName) {
  return new Promise((resolve, reject) => {
    db.collection("tasks")
      .doc(taskId)
      .update({
        taskName
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateArchiveStatusForTask(taskId, isArchived) {
  return new Promise((resolve, reject) => {
    db.collection("tasks")
      .doc(taskId)
      .update({
        isArchived
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });
}
