import { firebaseAuth, firebaseGoogleAuth } from "../utils/firebase-config";

export function loginWithEmailAndPassword(email, password) {
  return new Promise(function (resolve, reject) {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function signOutOfApp() {
  return new Promise(function (resolve, reject) {
    firebaseAuth
      .signOut()
      .then(resolve())
      .catch(err => reject(err));
  });
}

export function createUserWithEmailAndPassword(email, password) {
  return new Promise(function (resolve, reject) {
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(credentials => resolve(credentials))
      .catch(err => reject(err));
  });
}

export function onAuthStateChanged(callback) {
  return firebaseAuth.onAuthStateChanged(callback);
}

export function signInWithPopup() {
  return new Promise(function (resolve, reject) {
    firebaseAuth
      .signInWithPopup(firebaseGoogleAuth)
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
}

export function sendPasswordResetEmail(email) {
  return new Promise(function (resolve, reject) {
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function deleteAccount(uid) {
  return new Promise(function (resolve, reject) {
    firebaseAuth.currentUser
      .delete()
      .then(() => resolve())
      .catch(err => reject(err));
  });
}

export function updateUserDisplayName(user, displayName) {
  user.updateProfile({
    displayName
  });
}
