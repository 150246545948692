import { useState, useEffect } from "react";

/*
 * Determines if a user's screenwidth is under 768 (mobile) or over 767 (desktop)
 */
export const useMobile = ({ onMobileCallback, onWebCallback }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleResize = () => {
    if (window.innerWidth < 768 && !isMobile) {
      setIsMobile(true);
      if (onMobileCallback) {
        onMobileCallback();
      }
    } else if (window.innerWidth >= 768 && isMobile) {
      setIsMobile(false);
      if (onWebCallback) {
        onWebCallback();
      }
    }
  };

  return {
    isMobile
  };
};
