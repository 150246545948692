import axios from "axios";

export function signUpForMailingList({ email, firstName }) {
  return new Promise(function (resolve, reject) {
    axios
      .post("/.netlify/functions/subscribe-to-mailchimp", {
        email,
        firstName
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
