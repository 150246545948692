export const styleTemplates = {
  dashboi: {
    label: "Dashboi",
    config: {
      id: null,
      baseFontSize: 14,
      borderRadius: 0,
      componentBackgroundColor: {
        r: 244,
        g: 244,
        b: 244,
        a: 0.8
      },
      accentColor: {
        r: 107,
        g: 36,
        b: 80,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  oasis: {
    label: "Oasis",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 20,
      componentBackgroundColor: {
        r: 205,
        g: 220,
        b: 57,
        a: 0.2
      },
      accentColor: {
        r: 25,
        g: 77,
        b: 51,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  gopher: {
    label: "Gopher",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 2,
      componentBackgroundColor: {
        r: 251,
        g: 192,
        b: 45,
        a: 0.8
      },
      accentColor: {
        r: 136,
        g: 14,
        b: 79,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  ocean: {
    label: "Ocean",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 5,
      componentBackgroundColor: {
        r: 0,
        g: 96,
        b: 100,
        a: 0.6
      },
      accentColor: {
        r: 0,
        g: 188,
        b: 212,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  skeleton: {
    label: "Skeleton",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 8,
      componentBackgroundColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 0.4
      },
      accentColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  strawberry: {
    label: "Strawberry",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 0,
      componentBackgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1
      },
      accentColor: {
        r: 233,
        g: 30,
        b: 99,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  },
  blanco: {
    label: "Blanco",
    config: {
      id: null,
      baseFontSize: 15,
      borderRadius: 8,
      componentBackgroundColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 0
      },
      accentColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1
      },
      headerFontFamily: "Lato",
      bodyFontFamily: "Lato"
    }
  }
};
