import React, { useState } from "react";
import LoadingGlass from "../loadSpinner/LoadingGlass";
import PaymentFormContainer from "../paymentForm/PaymentFormContainer";
import PremiumSlider from "../paymentForm/PremiumSlider";
import "./Modal.scss";

export default function PremiumModal(props) {
  const [premiumAmount, setPremiumAmount] = useState(5);
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <div className="premium-modal">
      {isProcessing ? (
        <LoadingGlass style={{ transform: "translate(-30px, -95px)" }} />
      ) : null}
      {props.children}
      <PremiumSlider
        premiumAmount={premiumAmount}
        setPremiumAmount={setPremiumAmount}
      />
      <PaymentFormContainer
        premiumAmount={premiumAmount}
        setIsProcessing={setIsProcessing}
        renderButtons={props.renderButtons}
      />
    </div>
  );
}

PremiumModal.defaultProps = {
  subType: "Styles"
};
