import { db } from "../utils/firebase-config";

export function writeLayout(layout) {
  return new Promise(function (resolve, reject) {
    db.collection("layouts")
      .add(layout)
      .then(docRef => {
        resolve(docRef);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateLayout(id, layout) {
  return new Promise(function (resolve, reject) {
    db.collection("layouts")
      .doc(id)
      .update(layout)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function queryLayoutByUid(uid) {
  return new Promise(function (resolve, reject) {
    db.collection("layouts")
      .where("uid", "==", uid)
      .get()
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        reject(err);
      });
  });
}
