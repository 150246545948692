import { useState } from "react";

/*
 * Handles state management for form inputs
 */
export const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue || "");
  const handleChange = e => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange
  };
};
