import React, { Fragment } from "react";
import { useFormInput } from "../../custom-hooks/useFormInput";
import "./Login.scss";
import Input from "../input/input";
import Button, { buttonStyles } from "../button/button";
import ClickableText from "../clickableText/ClickableText";
import { useRefCallback } from "../../custom-hooks/useRefCallback";
import history from "../../utils/history";
import { useDispatch } from "react-redux";
import { requestPasswordReset } from "../../actions/AuthenticationActions";
import H, { headerSizes } from "../elements/H";

export default function Login() {
  const email = useFormInput();
  const { referenceFn: emailRef } = useRefCallback(ref => ref.current.focus());

  const dispatch = useDispatch();

  const onSubmit = event => {
    event.preventDefault();
    dispatch(requestPasswordReset(email.value));
  };

  const onCancelClick = event => {
    event.preventDefault();
    history.push("/login");
  };

  return (
    <Fragment>
      <div className="email-login">
        <H size={headerSizes.MEDIUM}>Forgot Account</H>
        <p>
          We'll send you an email with instructions on how to recover your
          account.
        </p>
        <Input
          placeholder="Email"
          id="email"
          type="text"
          name="email"
          {...email}
          required
          reference={emailRef}
        />
        <Button
          type="submit"
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
          onClick={onSubmit}
          shouldHandleGlobalEnterKeypress={true}
        >
          Send Recovery Email
        </Button>
        <p className="forgot-account">
          <ClickableText onClick={onCancelClick}>Cancel</ClickableText>
        </p>
      </div>
    </Fragment>
  );
}
