import React, { useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import "./TickerBanner.scss";
import { createTradingViewSymbolMap } from "../FinanceUtils";

export default function TickerBanner(props) {
  const tickerBanners = useSelector(state => state.layouts.tickerBanners);

  const tickerBanner = tickerBanners
    ? tickerBanners.find(banner => banner.widget_id === props.widget_id)
    : null;
  const id = "tradingview-widget-container__widget".concat(props.widget_id);

  const tickerWidgetRef = useRef(null);
  const setRef = useCallback(
    node => {
      if (node) {
        const element = document.getElementById(id);
        if (element.childNodes.length > 0) {
          element.removeChild(element.childNodes[0]);
        }
        const script = document.createElement("script");
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
        script.async = true;

        const symbols = tickerBanner
          ? createTradingViewSymbolMap(tickerBanner.symbols, "proName", "title")
          : [
              {
                proName: "FOREXCOM:SPXUSD",
                title: "S&P 500"
              },
              {
                proName: "FOREXCOM:NSXUSD",
                title: "Nasdaq 100"
              },
              {
                proName: "FX_IDC:EURUSD",
                title: "EUR/USD"
              },
              {
                proName: "BITSTAMP:BTCUSD",
                title: "BTC/USD"
              },
              {
                proName: "BITSTAMP:ETHUSD",
                title: "ETH/USD"
              }
            ];
        script.innerHTML = `{
        "symbols": ${JSON.stringify(symbols)},
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "regular",
        "locale": "en"
      }`;

        node.appendChild(script);
      }

      tickerWidgetRef.current = node;
    },
    [tickerBanner, id]
  );

  return (
    <div className="tradingview-widget-container">
      <div
        id={id}
        className="tradingview-widget-container__widget"
        ref={setRef}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="blue-text">Quotes</span>
        </a>{" "}
        by TradingView
      </div>
    </div>
  );
}

TickerBanner.defaultProps = {
  widget_id: ""
};
