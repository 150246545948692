import React, { useEffect, Fragment, useState } from "react";
import "./topbar.scss";
import Button, { buttonStyles } from "../button/button";
import classNames from "classnames";
import Logo from "../logo/Logo";
import UserMenu from "./userMenu/UserMenu";
import ClickableText from "../clickableText/ClickableText";
import { FaEdit, FaBars, FaTimes, FaPlus, FaRegEdit } from "react-icons/fa";
import { useMobile } from "../../custom-hooks/useMobile";
import { useSelector, useDispatch } from "react-redux";
import IconText from "../IconText/IconText";
import history from "../../utils/history";
import { hideSaveAlert, showSaveAlert } from "../../actions/globalActions";
import SaveLayoutButton from "../saveLayoutButton/SaveLayoutButton";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";
import LogoAndSiteName from "./LogoAndSiteName";
import ComponentPicker from "../componentPicker/ComponentPicker";
import GlobalStyles from "../globalStyles/GlobalStyles";

export default function Topbar(props) {
  const { isLoggedIn } = useSelector(state => state.login);
  const { lastEditTime, isSaveAlertVisible } = useSelector(
    state => state.global
  );
  const { id, isLayoutLocked } = useSelector(state => state.layouts);
  const dispatch = useDispatch();

  const { isMobile } = useMobile({
    onWebCallback: () => props.hideMobileMenu()
  });

  // dirty form handling if someone updates the layout
  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("unload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("unload", onUnload);
    };
  });

  // handles setting the title if someone has updated the layout
  useEffect(() => {
    if (props.isFormDirty) {
      document.title = document.title + " *";
    } else {
      document.title = "Dashboi";
    }
  }, [props.isFormDirty]);

  // handles showing the save alert
  useEffect(() => {
    const timeout = isLayoutLocked
      ? null
      : setInterval(
          function () {
            if (lastEditTime !== null) {
              dispatch(showSaveAlert());
            }
          },
          id === null || id === undefined ? 30000 : 120000
        );
    return () => clearInterval(timeout);
  }, [dispatch, isSaveAlertVisible, lastEditTime, isLayoutLocked, id]);

  const onBeforeUnload = event => {
    if (props.isFormDirty) {
      event.preventDefault();
      event.returnValue = "Dirty form";
    }
  };

  const onUnload = event => {
    props.lockLayout();
  };

  const editLayout = event => {
    event.preventDefault();
    props.toggleLayoutLock(false);
    recordEvent(
      "Edit Layout",
      eventCategories.GENERAL_APP_ACTION,
      "Edit Layout"
    );
  };

  const getActions = () => {
    if (props.shouldShowActions) {
      return props.isLayoutLocked ? (
        <Button
          onClick={editLayout}
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
          isInDarkBackground={true}
          className="save-button"
        >
          {isMobile ? (
            <FaEdit className="icon-button" aria-label="Edit Layout" />
          ) : (
            "Edit Layout"
          )}
        </Button>
      ) : (
        <SaveLayoutButton shouldShowIcon={isMobile} />
      );
    } else {
      return null;
    }
  };

  const getWebNavBarLinks = () => {
    return (
      <div className="menu-links">
        {props.isLoggedIn ? null : (
          <a
            href="/"
            alt="Home"
            className={classNames(
              "menu-link",
              props.activePage === "/" ? "active" : null
            )}
          >
            Home
          </a>
        )}
        <a
          href="/app"
          alt="My Dashboard"
          className={classNames(
            "menu-link",
            props.activePage === "app" ? "active" : null
          )}
        >
          My Dashboard
        </a>
        <a
          href="/contact"
          alt="Contact"
          className={classNames(
            "menu-link",
            props.activePage === "contact" ? "active" : null
          )}
        >
          Contact
        </a>
      </div>
    );
  };

  const getMobileNavBarLinks = () => {
    return props.isMobileMenuVisible ? (
      <FaTimes
        className="hamburger-menu"
        onClick={props.hideMobileMenu}
        aria-label="Close Menu"
      />
    ) : (
      <FaBars
        className="hamburger-menu"
        onClick={props.showMobileMenu}
        aria-label="Open Menu"
      />
    );
  };

  const getUserActions = () => {
    const saveAlert = isSaveAlertVisible ? (
      <Fragment>
        <div className="up-arrow-save" />
        <div className="save-alert">
          <IconText isIconFirst={false}>
            Don't forget to save your layout!{" "}
            <FaTimes
              aria-label="Dismiss alert"
              onClick={() => dispatch(hideSaveAlert())}
            />
          </IconText>
        </div>
      </Fragment>
    ) : null;

    return (
      <span className={classNames("login-text", isMobile ? "mobile-view" : "")}>
        {getActions()}
        {saveAlert}
        <UserMenu
          photoUrl={props.photoUrl}
          onLogoutClick={props.onLogoutClick}
          onDeleteAccountClick={props.onDeleteAccountClick}
          shouldHaveLeftMargin={props.shouldShowActions}
          activePage={props.activePage}
        />
      </span>
    );
  };

  const onLoginClick = () => {
    recordEvent(
      "Log in Initiated",
      eventCategories.GENERAL_APP_ACTION,
      "Log in Initiated"
    );
    history.push("/login");
  };

  const onSignUpClick = () => {
    recordEvent(
      "Registration Initiated",
      eventCategories.GENERAL_APP_ACTION,
      "Registration Initiated"
    );
    history.push("/signup");
  };

  const getLoginSignup = () => {
    return (
      <span className="login-options">
        <ClickableText
          onClick={onLoginClick}
          className="login-option"
          isInDarkBackground={true}
        >
          Login
        </ClickableText>
        <Button
          onClick={onSignUpClick}
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
          isInDarkBackground={true}
        >
          Sign up
        </Button>
      </span>
    );
  };

  const [isComponentPaneVisible, setIsComponentPaneVisible] = useState(false);
  const [isConfigurePaneVisible, setIsConfigurePaneVisible] = useState(false);
  const configurationOptions = (
    <div className="menu-links">
      <span
        className="configure-link"
        onMouseEnter={() => setIsComponentPaneVisible(true)}
        onMouseLeave={() => setIsComponentPaneVisible(false)}
      >
        <IconText>
          <FaPlus className="configure-link-icon" />
          <span className="configure-link-text">Components</span>
        </IconText>
        {isComponentPaneVisible ? (
          <div className="component-picker-wrapper" style={{ width: "200px" }}>
            <ComponentPicker />
          </div>
        ) : null}
      </span>
      <span
        className="configure-link"
        onMouseEnter={() => {
          setIsComponentPaneVisible(false);
          setIsConfigurePaneVisible(true);
        }}
        onMouseLeave={() => setIsConfigurePaneVisible(false)}
      >
        <IconText>
          <FaRegEdit className="configure-link-icon" />
          <span className="configure-link-text">Styles</span>
        </IconText>
        {isConfigurePaneVisible ? (
          <div className="component-picker-wrapper" style={{ width: "650px" }}>
            <GlobalStyles />
          </div>
        ) : null}
      </span>
    </div>
  );

  if (isMobile) {
    return (
      <div
        id="topbar"
        className={classNames("App-header", isMobile ? "mobile-view" : "")}
      >
        {getMobileNavBarLinks()}
        <Logo className="mobile-view" />
        {props.isLoggedIn ? (
          getUserActions()
        ) : (
          <div className="topbar-options">{getLoginSignup()}</div>
        )}
      </div>
    );
  } else {
    return (
      <div
        id="topbar"
        className={classNames(
          "App-header",
          props.activePage,
          isLayoutLocked ? "" : "edit-layout"
        )}
      >
        <LogoAndSiteName />
        {!isLayoutLocked && props.activePage === "app"
          ? configurationOptions
          : null}
        {isLoggedIn || props.activePage === "app" ? null : getWebNavBarLinks()}
        <div className="topbar-options">
          {props.isLoggedIn ? getUserActions() : getLoginSignup()}
        </div>
      </div>
    );
  }
}
