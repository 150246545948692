import { connect } from "react-redux";
import { requestLogout } from "../../actions/AuthenticationActions";
import { showModal } from "../../actions/modalActions";
import { lockLayout, unlockLayout } from "../../actions/layoutActions";
import Topbar from "./topbar";
import { DELETE_ACCOUNT } from "../modal/modalTypes";
import { showMobileMenu, hideMobileMenu } from "../../actions/globalActions";
import {
  eventCategories,
  recordEvent
} from "../../utils/googleAnalyticsEvents";

const mapStateToProps = state => {
  const { isModalVisible, modalType } = state.modal;
  const { isLoggedIn } = state.login;
  const { isFormDirty, isMobileMenuVisible } = state.global;
  const { isLayoutLocked } = state.layouts;
  const { photoUrl } = state.userDetails;
  return {
    isFormDirty,
    isModalVisible,
    modalType,
    isLoggedIn,
    isLayoutLocked,
    photoUrl,
    isMobileMenuVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogoutClick: () => {
      dispatch(requestLogout());
    },
    toggleLayoutLock: isLocked => {
      if (isLocked) {
        dispatch(lockLayout());
      } else {
        dispatch(unlockLayout());
      }
    },
    lockLayout: () => {
      dispatch(lockLayout());
    },
    onDeleteAccountClick: () => {
      recordEvent(
        "Delete Account Clicked",
        eventCategories.AUTHENTICATION_ACTION,
        "Delete Account Clicked"
      );
      dispatch(showModal(DELETE_ACCOUNT));
    },
    showMobileMenu: () => {
      dispatch(showMobileMenu());
    },
    hideMobileMenu: () => {
      dispatch(hideMobileMenu());
    }
  };
};

const TopbarContainer = connect(mapStateToProps, mapDispatchToProps)(Topbar);

export default TopbarContainer;
