import React, { Fragment, useRef, useLayoutEffect } from "react";
import Button, { buttonStyles } from "../button/button";
import { FaGoogle } from "react-icons/fa";
import "./SocialLogin.scss";
import SubtleSeparator from "../subtleSeparator/SubtleSeparator";
import IconText from "../IconText/IconText";

export default function SocialLogin(props) {
  const { buttonAction } = props;
  const googleLoginRef = useRef(null);

  useLayoutEffect(() => {
    if (googleLoginRef && googleLoginRef.current) {
      googleLoginRef.current.focus();
    }
  }, [googleLoginRef]);

  return (
    <Fragment>
      <div className="social-logins">
        <Button
          onClick={props.onGoogleSubmit}
          reference={googleLoginRef}
          buttonStyle={buttonStyles.OPAQUE_BUTTON}
        >
          <IconText>
            <FaGoogle className="google-icon" /> {buttonAction} With Google
          </IconText>
        </Button>
      </div>
      <SubtleSeparator verticalMargin={25} />
    </Fragment>
  );
}
